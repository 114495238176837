import axios from "axios";
import { token } from "morgan";

const API_BASE = process.env.REACT_APP_API_URL;

const getAccessToken = () => {
  const auth = localStorage.getItem("auth");
  return auth ? JSON.parse(auth).token : null;
};

//fetch events
export const fetchEvents = async (
  order_by_start = "asc",
  limit = 100,
  offset = 0
) => {
  try {
    const response = await axios.get(`${API_BASE}/events/`, {
      params: {
        limit,
        offset,
        order_by_start,
      },
      headers: {
        accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching events:", error);
    throw error;
  }
};

// Fetch a single event's details based on its ID
export const fetchEventDetails = async (eventId) => {
  try {
    const response = await axios.get(`${API_BASE}/events/${eventId}/`, {
      headers: {
        accept: "application/json", // Make sure the API accepts JSON responses
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching event details:", error);
    throw error; // Rethrow the error for further handling if needed
  }
};

// Create a new event
export const createEvent = async (data) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }
    const response = await axios.post(`${API_BASE}/events/`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    console.log("Event successfully created", response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      // Log the detailed error response
      console.error("Error creating event:", error.response.data);
      console.error("Error status:", error.response.status);
    } else {
      console.error("Unexpected error:", error);
    }
    throw error; // Re-throw the error to handle it elsewhere
  }
};

export const updateEvent = async (eventId, updatedEventData) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }
    const response = await axios.patch(
      `${API_BASE}/events/${eventId}`, // Use the eventId in the URL
      updatedEventData, // Send the updated event data in the body
      {
        headers: {
          "Content-Type": "application/json", // Set the Content-Type header
          event_id: `${eventId}`,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // Return the updated event data from the response
    return response.data;
  } catch (error) {
    console.error("Error during event update:", error);
    throw new Error("Failed to update event");
  }
};

//delete event by event Id
export const deleteEvent = async (eventId) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }
    const response = await axios.delete(`${API_BASE}/events/${eventId}`, {
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting event:", error);
    throw error;
  }
};

//scoring related

export const createGame = async (formData) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }
    const response = await axios.post(`${API_BASE}/games/`, formData, {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error(`Error creating a Game. ${error}`);
    throw error;
  }
};

export const updateGameScore = async (
  gameTakeawayId,
  score,
  isGameComplete
) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }

    const response = await axios.patch(
      `${API_BASE}/games/${gameTakeawayId}/scores`,
      {
        Score: score,
        ParticipantType: null,
        IsGameComplete: isGameComplete,
      },
      {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error updating game score:", error);
    throw error;
  }
};

// play pass related

//delete playpass game
export const deletePlayPass = async (UserId, playpassId) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }
    console.log("user id is: ", UserId);
    console.log("playpass id is: ", playpassId);
    const response = await axios.delete(
      `${API_BASE}/playpass/${playpassId}/admin?user_id=${UserId}`,
      {
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("error deleting playpass: ", error);
    throw error;
  }
};

//fetch all playpass games

export const fetchPlaypassGames = async () => {
  try {
    const response = await axios.get(
      `${API_BASE}/playpass/?order=asc&limit=1000&offset=0`,
      {
        headers: {
          accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Couldn't fetch playpass games", error);
    throw error;
  }
};
export const fetchPlaypassById = async (id) => {
  try {
    const response = await axios.get(
      `${API_BASE}/playpass/games/?playpass_id=${id}`,
      {
        headers: {
          accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Couldn't fetch playpass games", error);
    throw error;
  }
};

// fetch playpass members

export const fetchPlaypassMembers = async (id) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }
    const response = await axios.get(`${API_BASE}/playpass/${id}/members/`, {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Couldn't fetch playpass members", error);
    throw error;
  }
};

// Create a new playpass game
export const createPlaypass = async (data) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }
    const response = await axios.post(
      `${API_BASE}/playpass/games/admin/orders`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Playpass Game successfully created", response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      // Log the detailed error response
      console.error("Error creating Playpass Game:", error.response.data);
      console.error("Error status:", error.response.status);
    } else {
      console.error("Unexpected error:", error);
    }
    throw error; // Re-throw the error to handle it elsewhere
  }
};

export const fetchPlaypassDetails = async (playpassId) => {
  console.log("play pass id is: ", playpassId);
  try {
    const response = await axios.get(
      `${API_BASE}/playpass/games/?game_id=${playpassId}`,
      {
        headers: {
          accept: "application/json", // Make sure the API accepts JSON responses
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching event details:", error);
    throw error; // Rethrow the error for further handling if needed
  }
};

//update playpass team name
// Edit team name
export const UpdatePlaypassTeamName = async (team_id, editedTeamName) => {
  const token = getAccessToken();
  if (!token) {
    throw new Error("Unauthorized: No valid token found.");
  }
  try {
    const response = await axios.patch(
      `${API_BASE}/playpass/team/${team_id}?team_name=${editedTeamName}`,
      {},
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error updating team name: ", error);
    throw error;
  }
};

//get event members/teams
export const getEventMembers = async (eventId) => {
  try {
    const response = await axios.get(`${API_BASE}/events/members/${eventId}`);
    return response.data;
  } catch (error) {
    console.error("error in fetching event members data", error);
    throw error;
  }
};

//enter event outcome
export const eventOutcome = async (eventId, payload) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }
    console.log("inside api ", eventId);
    const response = await axios.post(
      `${API_BASE}/events/${eventId}/games/outcomes`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("error in event outcome api", error);
    throw error;
  }
};

//update event outcome
export const updateEventOutcome = async (eventId, payload) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }
    console.log("inside api ", eventId);
    const response = await axios.patch(
      `${API_BASE}/events/${eventId}/games/outcomes/`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("error in event outcome api", error);
    throw error;
  }
};

//get event outcome
export const getEventOutcome = async (eventId) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }
    console.log("inside api ", eventId);
    const response = await axios.get(
      `${API_BASE}/events/${eventId}/games/outcomes`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("error in event outcome api", error);
    throw error;
  }
};

//get playpass teams
export const getPlaypassTeams = async (playpass_id) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }
    const response = await axios.get(
      `${API_BASE}/playpass/${playpass_id}/members/`,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("error in getPlaypassTeams endpoint in api.js", error);
    throw error;
  }
};

// update playpass data
export const updatePlaypass = async (PlayPassId, updatedPlaypassData) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }
    const response = await axios.patch(
      `${API_BASE}/playpass/${PlayPassId}`,
      updatedPlaypassData,
      {
        headers: {
          "Content-Type": "application/json",
          playpass_id: `${PlayPassId}`,

          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error during playpass update:", error);
    throw new Error("Failed to update playpass");
  }
};

// add more rounds in playpass
export const addMoreRounds = async (PlayPassId, rounds) => {
  const token = getAccessToken();
  if (!token) {
    throw new Error("Unauthorized: No valid token found.");
  }
  console.log("auth token", token);
  try {
    await axios.post(
      `${API_BASE}/playpass/games/${PlayPassId}/rounds/?additional_rounds=${rounds}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.error("Error in adding more rounds in playpass:", error);
    throw error;
  }
};

// delete rounds in playpass
export const deleteRounds = async (PlayPassId, rounds) => {
  const token = getAccessToken();
  if (!token) {
    throw new Error("Unauthorized: No valid token found.");
  }
  console.log("auth token", token);
  try {
    await axios.delete(
      `${API_BASE}/playpass/games/${PlayPassId}/rounds/?delete_rounds=${rounds}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.error("Error in deleteing rounds in playpass:", error);
    throw error;
  }
};

//export playpass
export const exportPlaypass = async () => {
  const token = getAccessToken();
  if (!token) {
    throw new Error("Unauthorized: No valid token found.");
  }
  try {
    const response = await axios.get(`${API_BASE}/export/playpass`, {
      headers: {
        responseType: "blob",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error during exporting playpass details:", error);
    throw new Error("Failed to export playpass details");
  }
};

// Fetch venues
export const fetchVenues = async () => {
  try {
    // Make a GET request to the venues API
    const response = await axios.get(`${API_BASE}/venue/`, {
      headers: {
        accept: "application/json", // Set the Accept header to receive JSON response
      },
    });

    // Return the venue data (from the response's 'venues' array)
    return response.data.venues;
  } catch (error) {
    // Handle error if the request fails
    console.error("Error fetching venue data:", error);
    throw error; // Rethrow the error for handling in the calling function
  }
};

export const fetchVenueSlots = async (venueId) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }
    const response = await axios.get(
      `${API_BASE}/venues/${venueId}/allocation`,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data; // Return the slot details
  } catch (error) {
    console.error("Error fetching slots:", error);
    throw error; // Throw the error for further handling
  }
};

//fetch sponsor data
export const fetchSponsors = async () => {
  try {
    // Make a GET request to the sponsors API
    const response = await axios.get(`${API_BASE}/sponsors/`, {
      headers: {
        accept: "application/json", // Set the Accept header to receive JSON response
      },
    });

    // Return the data from the API response
    return response.data;
  } catch (error) {
    // Handle error if the request fails
    console.error("Error fetching sponsors:", error);
    throw error; // Rethrow the error for handling in the calling function
  }
};

// Fetch a list of sports
export const fetchSports = async () => {
  try {
    const response = await axios.get(`${API_BASE}/sports/`);
    return response.data; // Assuming the data contains an array of sports with SportId and SportType
  } catch (error) {
    console.error("Error fetching sports", error);
    throw error;
  }
};

export const uploadEventImage = async (formData) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }

    const response = await axios.post(`${API_BASE}/files`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("Uploading...");
    return response.data;
  } catch (error) {
    console.error(
      "Error uploading image:",
      error.response ? error.response.data : error
    );
    throw new Error("Image upload failed");
  }
};

export const fetchFileById = async (fileId) => {
  try {
    const response = await axios.get(`${API_BASE}/files/${fileId}`, {
      headers: {
        accept: "application/json",
      },
    });

    console.log("File API Response:", response.data); // Log the response data
    return response.data; // Return the response for further processing
  } catch (error) {
    console.error("Error fetching file by ID:", error);
    throw error;
  }
};

export const downloadFile = async (fileId) => {
  try {
    const response = await axios.get(`${API_BASE}/files/${fileId}/download`, {
      responseType: "blob", // Expect binary data
      headers: {
        accept: "application/json",
      },
    });

    // Convert blob data into a URL
    const fileURL = URL.createObjectURL(response.data);
    // console.log("FileURL", fileURL)
    return fileURL;
  } catch (error) {
    console.error(`Error downloading file with ID ${fileId}:`, error);
    throw error; // Re-throw the error to handle it in the calling code
  }
};

//create venue
export const createVenue = async (venueData) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }

    const response = await axios.post(`${API_BASE}/venue/`, venueData, {
      headers: {
        "Content-Type": "application/json", // Make sure the content type is JSON
        accept: "application/json", // Expect a JSON response
        Authorization: `Bearer ${token}`,
      },
    });

    // Return the response if the venue is created successfully
    return response.data;
  } catch (error) {
    console.error("Error creating venue:", error);
    throw error;
  }
};

export const addPlayAreaToVenue = async (venueId, playAreas) => {
  try {
    const token = getAccessToken();

    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }

    const response = await axios.post(
      `${API_BASE}/venue/${venueId}/play_area`,
      playAreas,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );

    return response.data; // Return API response
  } catch (error) {
    console.error("Error adding play area:", error);
    throw error.response?.data || new Error("Failed to add play area.");
  }
};

export const createVenueSlot = async (venueId, payload) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }

    const response = await axios.post(
      `${API_BASE}/venue/${venueId}/allocations`,
      payload,
      {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating venue slot:", error);
    throw error;
  }
};

export const deleteVenue = async (venueId) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }

    await axios.delete(`${API_BASE}/venue/${venueId}`, {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return true; // Indicate success
  } catch (error) {
    console.error(`Error deleting venue with ID ${venueId}:`, error);
    throw error; // Re-throw error for handling in the calling code
  }
};

export const fetchVenueDetails = async (venueId) => {
  try {
    const response = await axios.get(`${API_BASE}/venue/${venueId}`);
    return response.data; // Returning venue data
  } catch (error) {
    console.error("Error fetching venue details:", error);
    throw error;
  }
};

export const createSponsor = async (sponsorData) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }

    const response = await axios.post(`${API_BASE}/sponsor`, sponsorData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating sponsor:", error);
    throw error;
  }
};

export const deleteSponsor = async (sponsorId, accessToken) => {
  try {
    const response = await axios.delete(`${API_BASE}/sponsors/${sponsorId}`, {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting sponsor:", error);
    throw error;
  }
};

//create team
export const createTeam = async (formData) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }
    const response = await axios.post(`${API_BASE}/teams/`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("error in creating team: ", error);
    throw error;
  }
};

// Fetch all teams
export const fetchTeams = async () => {
  try {
    const response = await axios.get(`${API_BASE}/teams/?limit=100&offset=0`, {
      headers: {
        accept: "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    console.error("Error fetching teams:", error);
    throw error;
  }
};

// Edit team name
export const UpdateTeamName = async (team_id, user_id, editedTeamName) => {
  const token = getAccessToken();
  if (!token) {
    throw new Error("Unauthorized: No valid token found.");
  }
  try {
    const response = await axios.patch(
      `${API_BASE}/teams/${team_id}/name?user_id=${user_id}`,
      { TeamName: editedTeamName },
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.error("Error updating team name: ", error);
    throw error;
  }
};

// Fetch teams by team id
export const fetchTeamById = async (id) => {
  try {
    const response = await axios.get(
      `${API_BASE}/teams/?limit=10&offset=0&team_id=${id}`,
      {
        headers: {
          accept: "application/json",
        },
      }
    );
    console.log("teams", response.data.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching team from id:", error);
    throw error;
  }
};

// Fetch team members
export const fetchTeamMembers = async (teamId) => {
  try {
    const response = await axios.get(`${API_BASE}/teams/members`);
    return response.data;
  } catch (error) {
    console.error("Error fetching team members:", error);
    throw error;
  }
};

//Fetch Event Members
export const fetchEventMembers = async (eventId) => {
  try {
    const response = await axios.get(`${API_BASE}/events/members/${eventId}`, {
      headers: {
        accept: "application/json",
      },
    });
    return response.data.filter((member) => member.EventId === eventId);
  } catch (error) {
    console.error("Error fetching event members:", error);
    throw error;
  }
};

export const fetchRazorpayDetails = async (eventId) => {
  try {
    const token = getAccessToken();
    if (!token) {
      console.log("Unauthorized: no valid token available");
    }
    const response = await axios.get(`${API_BASE}/events/${eventId}/razorpay`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching Razorpay details:", error);
    throw error;
  }
};

// Update event member
export const updateEventMember = async (memberId, updatedData) => {
  try {
    const response = await axios.put(
      `${API_BASE}/event-members/${memberId}`,
      updatedData
    );
    return response.data;
  } catch (error) {
    console.error("Error updating event member:", error);
    throw error;
  }
};

// Delete event member
export const deleteEventMember = async (memberId) => {
  try {
    const response = await axios.delete(
      `${API_BASE}/event-members/${memberId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting event member:", error);
    throw error;
  }
};

//create event team
export const createEventTeam = async (formData) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }
    const response = await axios.post(`${API_BASE}/event/add/teams`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("error in creating team: ", error);
    throw error;
  }
};

export const createRegion = async (regionData) => {
  try {
    const response = await axios.post(`${API_BASE}/regions/`, regionData, {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error creating region:", error);

    // Handle Axios-specific error details
    if (error.response) {
      // Server responded with a status other than 2xx
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
      throw new Error(error.response.data.message || "Failed to create region");
    } else if (error.request) {
      // No response received
      console.error("No response received:", error.request);
      throw new Error("No response received from the server.");
    } else {
      // Something else went wrong
      throw new Error(error.message || "Unexpected error occurred.");
    }
  }
};

export const fetchRegions = async () => {
  try {
    const response = await axios.get(`${API_BASE}/regions/`, {
      headers: {
        accept: "application/json",
      },
    });
    return response.data; // Return the list of regions from the response
  } catch (error) {
    console.error("Error fetching regions:", error);
    throw new Error("Unable to fetch regions");
  }
};

export const loginOTP = async ({ mobile_number, country_code }) => {
  try {
    const response = await axios.post(`${API_BASE}/login/OTP`, {
      mobile_number,
      country_code,
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to send OTP");
  }
};
export const verifyOTP = async ({ mobile_number, otp }) => {
  try {
    const response = await axios.post(`${API_BASE}/login/verifyOTP`, {
      mobile_number,
      otp,
    });
    return response.data;
  } catch (error) {
    throw new Error("Invalid OTP");
  }
};
export const checkAdmin = async ({ mobile_number, country_code }) => {
  try {
    const response = await axios.post(`${API_BASE}/login/OTP`, {
      mobile_number,
      country_code,
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to send OTP");
  }
};

export const fetchWaitlist = async (eventId) => {
  try {
    const response = await axios.get(`${API_BASE}/order/waitlist/`, {
      params: { event_id: eventId },
      headers: {
        accept: "application/json",
      },
    });

    console.log("Raw API Response:", response.data); // Debugging

    // Ensure the response contains 'items' and it's an array
    if (response.data && Array.isArray(response.data.items)) {
      return response.data; // Return full response including pagination info
    } else {
      return { items: [], message: "No waitlist entry available" }; // Corrected
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { items: [], message: "No waitlist entry available" };
    }

    console.error("Error fetching waitlist:", error);
    throw error;
  }
};

// Create a new event
export const createCoachingSession = async (data) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }
    const response = await axios.post(`${API_BASE}/coaching/offerings/`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    console.log("Coaching Session successfully created", response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      // Log the detailed error response
      console.error("Error creating coaching:", error.response.data);
      console.error("Error status:", error.response.status);
    } else {
      console.error("Unexpected error:", error);
    }
    throw error; // Re-throw the error to handle it elsewhere
  }
};

export const updateCoaching = async (CoachOfferingId, updatedEventData) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }
    const response = await axios.patch(
      `${API_BASE}/coaching/offerings/${CoachOfferingId}`, // Use the eventId in the URL
      updatedEventData, // Send the updated event data in the body
      {
        headers: {
          "Content-Type": "application/json", // Set the Content-Type header
          offering_id: `${CoachOfferingId}`,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // Return the updated event data from the response
    return response.data;
  } catch (error) {
    console.error("Error during event update:", error);
    throw new Error("Failed to update event");
  }
};

export const createCoachingSlot = async (payload) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }
    const response = await axios.post(
      `${API_BASE}/coaching/allocations`,
      payload,
      {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error creating coaching slot:", error);
    console.log(payload);
    throw error;
  }
};

// Fetch all coaching events
export const fetchCoachingEvents = async (
  order = "asc",
  limit = 10,
  offset = 0
) => {
  try {
    const response = await axios.get(
      `${API_BASE}/coaching/offerings/?order=${order}&limit=${limit}&offset=${offset}`,
      {
        headers: {
          accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching coaching events:", error);
    throw error;
  }
};

// Fetch details of a specific coaching event
export const fetchCoachingEventDetails = async (CoachOfferingId) => {
  try {
    const response = await axios.get(
      `${API_BASE}/coaching/offerings/${CoachOfferingId}/`,
      {
        headers: {
          accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching coaching event details:", error);
    throw error;
  }
};

// Delete a specific coaching event
export const deleteCoachingEvent = async (CoachOfferingId, accessToken) => {
  try {
    await axios.delete(`${API_BASE}/coaching/offerings/${CoachOfferingId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        accept: "application/json",
      },
    });
  } catch (error) {
    console.error("Error deleting coaching event:", error);
    throw error;
  }
};

export const fetchCoachingMembers = async (coachOfferingId) => {
  try {
    const response = await axios.get(`${API_BASE}/coaching/members/`, {
      params: { CoachOfferingId: coachOfferingId },
      headers: {
        accept: "application/json",
      },
    });

    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.warn("No coaching members found for:", coachOfferingId);
      return []; // Return empty array instead of throwing an error
    }
    console.error("Error fetching coaching members:", error);
    throw error;
  }
};

// Update event member
export const updateCoachingMember = async (memberId, updatedData) => {
  try {
    const response = await axios.put(
      `${API_BASE}/event-members/${memberId}`,
      updatedData
    );
    return response.data;
  } catch (error) {
    console.error("Error updating event member:", error);
    throw error;
  }
};

// Delete event member
export const deleteCoachingMember = async (memberId) => {
  try {
    const response = await axios.delete(
      `${API_BASE}/event-members/${memberId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting event member:", error);
    throw error;
  }
};

export const fetchCoachAllocationDetails = async (coachOfferingId) => {
  try {
    const response = await fetch(
      `${API_BASE}/coaching/allocation?coachOfferingId=${coachOfferingId}`,
      {
        headers: { accept: "application/json" },
      }
    );
    return await response.json();
  } catch (error) {
    console.error("Error fetching coaching allocation details:", error);
    return [];
  }
};

export const fetchCoachingRazorpayDetails = async (CoachingAllocationId) => {
  try {
    const token = getAccessToken();
    if (!token) {
      console.log("Unauthorized: no valid token available");
    }
    const response = await axios.get(
      `${API_BASE}/coach/offering/${CoachingAllocationId}/razorpay`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Razorpay details:", error);
    throw error;
  }
};

export const fetchCoachingWaitlist = async (coachOfferingId) => {
  try {
    const response = await axios.get(`${API_BASE}/order/waitlist`, {
      params: { coach_event_id: coachOfferingId },
      headers: {
        accept: "application/json",
      },
    });

    // Ensure the response is an array
    if (Array.isArray(response.data)) {
      return response.data;
    } else {
      console.warn("API returned unexpected response:", response.data);
      return []; // Default to empty array
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return []; // Ensure an empty array is returned instead of an object
    }
    console.error("Error fetching waitlist:", error);
    throw error;
  }
};

//function to fetch teams
export const getTeams = async () => {
  try {
    const token = getAccessToken();
    if (!token) {
      console.log("Unauthorized: no valid token available");
    }
    const response = await axios.get(`${API_BASE}/teams/`,{
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`
      },
    });

    return response.data.data;
  } catch (error) {
    console.error("Error in fetching team data", error);
  }
};

// Users
export const fetchAllUsers = async () => {
  const token = getAccessToken();

  if (!token) {
    throw new Error("Unauthorized: No valid token found.");
  }

  let allUsers = [];
  let offset = 0;
  const limit = 100; // Adjust based on API limits

  try {
    while (true) {
      const response = await axios.get(
        `${API_BASE}/users/?limit=${limit}&offset=${offset}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.users.length === 0) break; // Stop if no more users

      allUsers = [...allUsers, ...response.data.users];
      offset += limit;
    }

    return allUsers;
  } catch (error) {
    console.error("Couldn't fetch user data", error);
    return [];
  }
};

export const fetchUserDetails = async (id) => {
  const token = getAccessToken();

  if (!token) {
    throw new Error("Unauthorized: No valid token found.");
  }
  try {
    const response = await axios.get(`${API_BASE}/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Couldn't fetch user data", error);
  }
};

export const createUser = async (userData) => {
  const token = getAccessToken();

  if (!token) {
    throw new Error("Unauthorized: No valid token found.");
  }
  try {
    const response = await axios.post(
      `${API_BASE}/admin/user/create`,
      userData,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};

//fetch User Profile by UserID
export const fetchUserProfile = async (userId) => {
  const token = getAccessToken();

  if (!token) {
    throw new Error("Unauthorized: No valid token found.");
  }
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }
    const response = await axios.get(`${API_BASE}/users/${userId}/profile/`, {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching profile for UserId: ${userId}`, error);
    throw error;
  }
};

//fetch User UserID
export const fetchUserById = async (userId) => {
  const token = getAccessToken();

  if (!token) {
    throw new Error("Unauthorized: No valid token found.");
  }
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }
    const response = await axios.get(`${API_BASE}/users/${userId}`, {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching user for UserId: ${userId}`, error);
    throw error;
  }
};

export const fetchUserDetailsByPhone = async (mobileNumber) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }
    const response = await axios.get(`${API_BASE}/users/`, {
      params: {
        mobile_number: mobileNumber,
        limit: 10,
        offset: 0,
      },
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching user details:", error);
    throw new Error("Failed to fetch user details");
  }
};

// Function to get the list of users
export const getUsers = async (limit = 100, offset = 0) => {
  const token = getAccessToken();

  if (!token) {
    throw new Error("Unauthorized: No valid token found.");
  }
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found.");
    }
    const response = await axios.get(`${API_BASE}/users/`, {
      params: { limit, offset },
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Return the response data
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error; // Rethrow the error for error handling
  }
};

// Function to get user details by ID
export const getUserById = async (userId) => {
  const token = getAccessToken();
  if (!token) {
    throw new Error("Unauthorized: No valid token found.");
  }

  try {
    const response = await axios.get(`${API_BASE}/users/${userId}`, {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Return the response data
  } catch (error) {
    console.error(`Error fetching user details for ID ${userId}:`, error);
    throw error; // Rethrow the error for error handling
  }
};

export const updateUser = async (userId, userData, token) => {
  try {
    const response = await axios.patch(
      `${API_BASE}/users/${userId}`,
      userData,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

export const getTransactions = async (userId, limit = 20, offset = 0) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found");
    }
    const response = await fetch(
      `${API_BASE}/transactions/history/${userId}?limit=${limit}&offset=${offset}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch transactions");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in getTransactions:", error);
    throw error;
  }
};

export const getTransactionsAdmin = async (userId, limit = 100, offset = 0) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found");
    }
    const response = await fetch(
      `${API_BASE}/transactions/history/admin/?user_id=${userId}&limit=${limit}&offset=${offset}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch transactions");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in getTransactions:", error);
    throw error;
  }
};

export const getOrders = async (userId, page = 1, limit = 20) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found");
    }
    const response = await axios.get(`${API_BASE}/orders`, {
      params: { UserId: userId, page, limit },
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching orders:", error);
    throw error;
  }
};

// Function to fetch order details by OrderId
export const fetchOrderById = async (orderId) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found");
    }
    const response = await axios.get(`${API_BASE}/orders`, {
      params: {
        OrderId: orderId,
      },
      headers:{
        accept: "application/json",
        Authorization: `Bearer ${token}`
      }
    });
    return response.data.orders[0]; // Return the first order in the response
  } catch (error) {
    console.error(`Error fetching order with ID ${orderId}:`, error);
    throw error;
  }
};

export const getWalletBalance = async (userId) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found");
    }

    const response = await axios.get(
      `${API_BASE}/users/wallet/balance/${userId}`,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // Assuming the response contains { WalletId, Balance }
  } catch (error) {
    console.error(`Error fetching wallet balance for user ${userId}:`, error);
    throw error;
  }
};

// Fetch sponsors
export const fetchRewardSponsors = async () => {
  try {
    const response = await axios.get(`${API_BASE}/reward/sponsors`, {
      headers: { accept: "application/json" },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching sponsors:", error);
    throw error;
  }
};

// Create reward sponsor
export const createRewardSponsor = async (data) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found");
    }

    const response = await axios.post(`${API_BASE}/rewards/sponsors/`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating reward sponsors:", error);
    throw error;
  }
};

// Create a reward
export const createReward = async (rewardData) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found");
    }
    const response = await axios.post(`${API_BASE}/rewards`, rewardData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating reward:", error);
    throw error;
  }
};

export const fetchRewards = async () => {
  try {
    const response = await axios.get(`${API_BASE}/rewards`, {
      headers: { accept: "application/json" },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching rewards:", error);
    throw error;
  }
};

export const deleteReward = async (rewardId) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found");
    }
    const response = await axios.delete(`${API_BASE}/rewards/${rewardId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting Reward:", error.response?.data || error);
    throw error;
  }
};

export const fetchUserRewards = async (userId) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error("Unauthorized: No valid token found");
    }
    const response = await axios.get(`${API_BASE}/rewards/${userId}/user`, {
      headers: { accept: "application/json", Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user rewards:", error);
    throw error;
  }
};

export const createMilestone = async (milestoneData) => {
  try {
    const token = getAccessToken();
    if (!token) {
      console.log("Unauthorized, Valid token not available");
    }
    const response = await axios.post(`${API_BASE}/milestone/`, milestoneData, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data; // Return response data if successful
  } catch (error) {
    console.error(
      "Error creating milestone:",
      error.response?.data || error.message
    );
    throw new Error(
      error.response?.data?.message || "Failed to create milestone"
    );
  }
};

// Fetch all milestones
export const fetchMilestones = async () => {
  try {
    const response = await axios.get(`${API_BASE}/milestone/`, {
      headers: {
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching milestones:", error);
    throw error;
  }
};

export const deleteMilestone = async (milestoneId) => {
  try {
    const response = await axios.delete(
      `${API_BASE}/milestones/${milestoneId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting milestone:", error.response?.data || error);
    throw error;
  }
};
