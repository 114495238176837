import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // To get event ID from the URL
import { fetchPlaypassById, addMoreRounds, getTeams, updatePlaypass, getPlaypassTeams, deleteRounds, fetchPlaypassMembers, UpdatePlaypassTeamName } from "../../api/api";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
    FaClipboardList,
    FaEdit,
    FaChevronDown,
    FaChevronUp,
} from "react-icons/fa";
import EditPlaypassForm from "../../components/EditPlaypassForm";
import PlaypassScoring from "./PlaypassScoring";
import Loader from "../../components/Loader";
import ToastNotification from "../../components/ToastNotification";


const PlaypassDetails = () => {

    const { id } = useParams();
    const [playpass, setPlaypass] = useState(null)
    const [playpassMembers, setPlaypassMembers] = useState(null)
    // const [playerDetails, setPlayerDetails] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [inviteList, setInviteList] = useState([])
    const [teamInviteList, setTeamInviteList] = useState([])
    const [showSuccessNotification, setShowSuccessNotification] = useState(false);
    const [isEditing, setIsEditing] = useState(false); // To toggle the editing mode
    const [isUPdateScore, setIsUpdateScore] = useState(false)
    const [teamData, setPlaypassTeamData] = useState([])
    const [isAddRound, setIsAddRound] = useState(false)
    const [isDeleteRound, setIsDeleteRound] = useState(false)
    const [addedRoundNumbers, setAddedRoundNumbers] = useState(null)
    const [deletedRounds, setDeletedRounds] = useState(null)
    const [toast, setToast] = useState(null);
    const [isEditingTeamName, setIsEditingTeamName] = useState(null);
    const [editedTeamName, setEditedTeamName] = useState("");



    // Fetch event details when the component mounts
    useEffect(() => {

        const getPlaypassDetails = async () => {
            try {
                //setting playpass data
                const playpassData = await fetchPlaypassById(id);
                console.log("viewing playpass data")
                console.log(playpassData.games[0])
                setPlaypass(playpassData.games[0]);

                //fetching team details
                try {
                    console.log("pp id", playpassData.games[0].PlayPassId)
                    const getTeamsInPlaypass = await getPlaypassTeams(playpassData.games[0]?.PlayPassId)
                    console.log("teams data is: ", getTeamsInPlaypass)
                    setPlaypassTeamData(getTeamsInPlaypass)

                } catch (error) {
                    console.error("error in getPlaypassTeams in getPlaypassDetails function in PlaypassDetails.jsx", error)
                }


            } catch (error) {
                setError("Failed to load event details");
                console.error("Error fetching event details:", error);
            } finally {
                setLoading(false);
            }
        };
        getPlaypassDetails();
    }, [id]);


    useEffect(() => {
        const fetchData = async () => {

            setLoading(true)

            try {

                if (playpass?.TeamInviteList?.length > 0) {

                    const teams = await getTeams();
                    console.log('teams sjfkda', teams)

                    if (teams.length > 0) {
                        const invitedTeamIds = playpass.TeamInviteList.map(invite => invite.TeamId); // Extract TeamId values

                        const filteredTeams = teams.filter(team => invitedTeamIds.includes(team.TeamId));
                        console.log('filtered teams', filteredTeams)

                        setTeamInviteList(filteredTeams)
                    }
                }
            } catch (error) {
                console.log("error fetching team data: ", error)
            } finally {
                setLoading(false)
            }

        }
        fetchData()

    }, [playpass])

    useEffect(() => {
        const getPlaypassMembers = async () => {
            setLoading(true);
            try {
                const memberResponse = await fetchPlaypassMembers(id)
                console.log("playpass members are: ", memberResponse)
                setPlaypassMembers(memberResponse)

            } catch (error) {
                console.error("Error in getPlaypassMembers function in playpass details", error)
            }
        }
        getPlaypassMembers()
    }, [playpass])

    const handleEditClick = () => {
        setIsEditing(true); // Enable editing when the Edit button is clicked
    };

    // Handling Cancel Edit
    const handleCancelEdit = () => {
        setIsEditing(false); // Disable editing when cancel button is clicked
    };

    const handleUpdateScore = () => {
        setIsUpdateScore(true);
    };
    const handleCancelUpdateScore = () => {
        setIsUpdateScore(false)
    }

    console.log("this is teamInvitelist", teamInviteList)
    // Round related functions
    const handleAddRounds = () => {
        setIsAddRound(true);
        setIsDeleteRound(false)
    }
    const handleCancelAddRounds = () => {
        setIsAddRound(false);
    }
    const handleRoundNumberValueChange = (e) => {
        setAddedRoundNumbers(e.target.value)
    }
    const handleAddRoundSubmit = async () => {

        if (addedRoundNumbers < 1) {
            setToast({
                message: " Number can't less than 1"
            })
        } else {
            try {
                await addMoreRounds(id, addedRoundNumbers)
                setToast({ message: `Rounds added successfully!`, type: "success" });
                setTimeout(() => {
                    window.location.reload();
                }, 500);

            } catch (error) {
                console.error("error in handleAddRoundSubmit function in PlaypassDetails page", error)
                setToast({
                    message: "Failed to add more rounds.",
                    type: "error",
                });
            }
        }
    }

    //deleted rounds
    const handleDeletedRoundChange = (e) => {
        setDeletedRounds(e.target.value)
    }
    const handleCancelDeleteRounds = () => {
        setIsDeleteRound(false)
    }
    const handleDeleteRounds = () => {
        setIsDeleteRound(true);
        setIsAddRound(false)
    }
    const handleDeleteRoundSubmit = async () => {

        if (deletedRounds > playpass.Games.TotalRounds) {
            setToast({
                message: " Number can't be greater than total rounds!"
            })
        } else {



            try {
                await deleteRounds(id, deletedRounds)
                setToast({ message: `Rounds deleted successfully!`, type: "success" });
                setTimeout(() => {
                    window.location.reload();
                }, 500);

            } catch (error) {
                console.error("error in handleDeleteRoundSubmit function in PlaypassDetails page", error)
                setToast({
                    message: "Failed to delete rounds.",
                    type: "error",
                });
            }
        }
    }


    // team name changes
    // Handle input change
    const handleNameChange = (e) => {
        setEditedTeamName(e.target.value);
    };
    const editingTeamName = (teamId, currentName) => {
        setIsEditingTeamName(teamId); // Set only the clicked team to edit mode
        setEditedTeamName(currentName);
    };
    // Submit updated team name
    const editNameSubmit = async (teamId) => {
        if (!editedTeamName.trim()) {
            setToast({
                message: "Name can't be empty!", type: "error"
            })
            return
        }; // Prevent empty name updates


        try {
            console.log("entering here")
            await UpdatePlaypassTeamName(teamId, editedTeamName)
            console.log("now here")

            setToast({
                message: "Updated Team Name successfully!", type: "success"
            })
            setIsEditingTeamName(null); // Exit edit mode
            setTimeout(() => {
                window.location.reload();
            }, 500);
        } catch (error) {
            console.error("Error updating team name:", error);
            setToast({
                message: "Error in updating team name!", type: "error"
            })
        }
    };
    // Cancel editing
    const editNameCancel = () => {
        setIsEditingTeamName(null);
    };


    // Save changes to the playpass
    const handleSaveChanges = async (updatedPlaypassData) => {
        try {
            // Do NOT remove the EventId, pass it along with the updated data
            const updatedPlaypass = await updatePlaypass(playpass.PlayPassId, updatedPlaypassData);

            // If the updated event doesn't include all details, merge the old event data
            const finalPlaypass = {
                ...playpass, // Preserve existing event data
                ...updatedPlaypass, // Override with updated data
            };

            // Update the event data in state with the merged event data
            setPlaypass(finalPlaypass); // Save the updated event data to state
            setIsEditing(false); // Disable editing mode

            if (updatedPlaypass && updatedPlaypass.PlayPassId) {
                setShowSuccessNotification(true);

                // Hide notification after 3 seconds
                setTimeout(() => {
                    setShowSuccessNotification(false);
                }, 3000);
            }
        } catch (error) {
            setError("Failed to save changes");
            console.error("Error saving event:", error);
        }
    };

    if (loading) {
        return <Loader />
    }

    return (
        <div className="container mx-auto p-6 relative">
            {toast && (
                <ToastNotification
                    message={toast.message}
                    type={toast.type}
                    onClose={() => setToast(null)}
                />
            )}

            {/* Success Notification */}
            {showSuccessNotification && (
                <div className="absolute top-6 right-6 p-4 bg-green-500 text-white rounded-lg shadow-lg flex items-center">
                    <span className="mr-2">✔️</span>
                    <span>Event successfully updated!</span>
                </div>
            )}
            <div className="absolute top-8 right-8 flex flex-col lg:flex-row gap-2">
                {/* Edit Button */}
                <button
                    className="text-[#512D9A] py-1 px-3 border-2 border-[#F2F2F2] rounded hover:shadow-sm hover:font-medium  flex items-center justify-center"
                    onClick={handleEditClick} // Trigger editing mode when clicked
                >
                    {/* Show text on larger screens */}
                    <span className="hidden sm:inline">Edit Playpass</span>
                    {/* Show icon on small screens */}
                    <FaEdit className="h-5 w-5 sm:hidden" />
                </button>

                {/* Show Event Registrations Button */}
                <button
                    className="text-[#512D9A] py-1 px-3 border-2 border-[#F2F2F2] rounded hover:shadow-sm hover:font-medium  flex items-center justify-center"
                    onClick={handleUpdateScore} // Trigger event members page redirection
                >
                    {/* Show text on larger screens */}
                    <span className="hidden sm:inline">Update Score</span>
                    {/* Show icon on small screens */}
                    <FaClipboardList className="h-5 w-5 sm:hidden" />
                </button>
            </div>



            {/* Playpass game Details */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <h2 className="text-4xl font-bold text-gray-900 mb-4">
                    {playpass?.PlayPassTitle}
                </h2>

                <p className="text-lg text-gray-700 mb-4">
                    <strong>Description:</strong> {playpass?.Description}
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Organizer:</strong> {playpass?.OrganizerName}
                </p>
            </div>

            {/* Playpass game information Section */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <h3 className="text-4xl font-bold text-gray-900 mb-4">
                    Playpass Game Information
                </h3>
                <div className="space-y-4">
                    <p className="text-lg text-gray-700">
                        <strong>Playpass Type:</strong> {playpass?.PlayPassType}
                    </p>
                    <p className="text-lg text-gray-700">
                        <strong>Playpass access:</strong>{" "}
                        {playpass?.PlayPassAccess || "Not Available"}
                    </p>
                    <p className="text-lg text-gray-700">
                        <strong>Skill Level:</strong> {playpass?.SkillLevel + " "}
                    </p>
                    <p className="text-lg text-gray-700">
                        <strong>Team Size:</strong> {playpass?.TeamSize}
                    </p>
                    <p className="text-lg text-gray-700">
                        <strong>Total Teams:</strong> {playpass?.TotalTeams}
                    </p>
                    <p className="text-lg text-gray-700">
                        <strong>Total Registered Players:</strong> {playpass?.RegisteredPlayers}
                    </p>
                    <p className="text-lg text-gray-700">
                        <strong>Total Rounds:</strong> {playpass?.Games?.TotalRounds}
                    </p>

                    <div className="flex items-center gap-x-2">

                        {isAddRound ? (
                            <>
                                <input
                                    required
                                    type="number"
                                    value={addedRoundNumbers}
                                    onChange={handleRoundNumberValueChange}
                                    placeholder="Add more rounds"
                                    className="p-1 border border-black rounded-md outline-none w-44"
                                />
                                <button onClick={handleAddRoundSubmit} className="bg-green-500 px-2 py-1 text-white rounded-md">Add +</button>
                                <button onClick={handleCancelAddRounds} className="bg-gray-500 px-2 py-1 text-white rounded-md">Cancel</button>
                            </>
                        ) : (
                            <button onClick={handleAddRounds} className="bg-blue-500 text-white px-2 py-1 rounded-md">Add Rounds +</button>
                        )}

                    </div>

                    <div className="flex items-center gap-x-2">

                        {isDeleteRound ? (
                            <>
                                <input
                                    required
                                    type="number"
                                    min={1}
                                    max={playpass.Games.TotalRounds}
                                    value={deletedRounds}
                                    onChange={handleDeletedRoundChange}
                                    placeholder="Delete Rounds"
                                    className="p-1 border border-black rounded-md outline-none w-44"
                                />
                                <button onClick={handleDeleteRoundSubmit} className="bg-red-500 px-2 py-1 text-white rounded-md">Delete</button>
                                <button onClick={handleCancelDeleteRounds} className="bg-gray-500 px-2 py-1 text-white rounded-md">Cancel</button>
                            </>
                        ) : (
                            <button onClick={handleDeleteRounds} className="bg-red-500 text-white px-2 py-1 rounded-md">Delete Rounds -</button>
                        )}

                    </div>


                </div>
            </div>

            {/* Invite List */}

            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <h2 className="text-4xl font-bold text-gray-900 mb-4">
                    Invite List
                </h2>
                {playpass?.InviteList?.length > 0 ? (
                    <div className="text-lg text-gray-700 mb-4">
                        {playpass.InviteList.map((player, index) => (
                            <div key={index} className="mb-2">
                                <p className="font-semibold">Player {index + 1}: {player.Name} </p>
                            </div>
                        ))}
                    </div>
                ) : ("No invite list found!")}


            </div>


            {/* Team invite list */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <h2 className="text-4xl font-bold text-gray-900 mb-4">
                    Team Invite List
                </h2>
                {playpass?.TeamInviteList?.length > 0 ? (
                    <div className="text-lg text-gray-700 mb-4">
                        {playpass.TeamInviteList.map((player, index) => (
                            <div key={index} className="mb-2">
                                <p>Team {index + 1}: {teamInviteList[index]?.TeamName}</p>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className="text-gray-700">No team invites available.</p>
                )}
            </div>
            {/* Playpass Members */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <h2 className="text-4xl font-bold text-gray-900 mb-4">
                    Playpass Members
                </h2>
                {playpassMembers?.length > 0 ?
                    (
                        <div className="text-lg text-gray-700 mb-4">
                            {playpassMembers.map((team, index) => (
                                <div key={team.TeamId} className="mb-2 text-xl font-medium">

                                    {/* Show Team Name and Edit */}
                                    {isEditingTeamName === team.TeamId ?
                                        (
                                            <span className='flex items-center gap-x-2'>
                                                <input type='text'
                                                    name='teamName'
                                                    value={editedTeamName}
                                                    onChange={handleNameChange}
                                                    placeholder='Update Team Name'
                                                    className='border border-black rounded-md text-base p-1'
                                                />
                                                <button onClick={() => editNameSubmit(team.TeamId)} type='submit'
                                                    className='text-sm bg-blue-500 text-white rounded-md px-2 py-2'
                                                > Update Name
                                                </button>
                                                <button onClick={editNameCancel} type='submit'
                                                    className='text-sm bg-red-500 text-white rounded-md px-2 py-2'
                                                > Cancel
                                                </button>
                                            </span>
                                        )
                                        :
                                        (
                                            <span className='flex items-center gap-x-2'>
                                                {index + 1}: {team.TeamName}<FaEdit onClick={() => editingTeamName(team.TeamId, team.TeamName)}
                                                    className="h-4 w-4 text-blue-600 cursor-pointer"
                                                />
                                            </span>
                                        )}

                                    {/* <p>{index + 1}: {team.TeamName}</p> */}

                                    {team.TeamDetails ?
                                        (
                                            team.TeamDetails.map((member, index) => (
                                                <div>
                                                    <div key={member.PlayPassMemberId} className="ml-2 font-normal text-lg">
                                                        <p>Player {index + 1}: {member.UserDetail.Name}</p>
                                                    </div>
                                                </div>
                                            ))
                                        )
                                        :
                                        (<p className="text-base font-normal ml-2 text-red-400"> Member details for team {team.TeamName} not found</p>)}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="text-gray-700">No teams available.</p>
                    )}
            </div>



            {/* Game Scoring Details */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <h2 className="text-4xl font-bold text-gray-900 mb-4 text-center">
                    Game Scoring
                </h2>

                {playpass?.Games?.Rounds?.map((round) => (
                    <div
                        className="w-[90%] mx-auto mb-8 bg-[#dee2e6] py-3 rounded-md"
                        key={round.RoundNumber}
                    >
                        <h2 className="text-center text-2xl font-semibold">
                            Round {round.RoundNumber + 1}
                        </h2>

                        <div className="flex justify-between mt-6">
                            {round.RoundScores.map((score) => {
                                const team = teamData?.find(t => t.TeamId === score.TeamId);
                                return (
                                    <div
                                        className="w-1/2 flex flex-col items-center"
                                        key={score.GameTakeawayId}
                                    >
                                        <p className="text-xl font-medium">
                                            Team: {team?.TeamName || 'N/A'}
                                        </p>
                                        <p className="text-xl font-medium">
                                            {score.Score}
                                        </p>
                                        {score.Result ? (
                                            <p className="text-xl font-medium">
                                                Result: {score.Result}
                                            </p>
                                        ) : ('')}


                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ))}

                {playpass?.Games?.TeamWon ? (
                    <div className="mt-14 w-full flex justify-center">

                        <div className="bg-green-300 md:w-[80%] text-center p-4 rounded-md">
                            <h1 className="text-3xl font-semibold">Final Result:</h1>
                            <h2 className="mt-4 text-xl font-medium">Team: {(teamData?.find(t => t.TeamId === (playpass?.Games?.TeamWon))).TeamName} Won</h2>
                        </div>

                    </div>
                ) : ('')}


            </div>



            {/* Venue Details */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <h2 className="text-4xl font-bold text-gray-900 mb-4">
                    Venue
                </h2>

                <p className="text-lg text-gray-700 mb-4">
                    <strong>Name:</strong> {playpass?.VenueName}
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Map Link: </strong>  <a className="text-blue-700" target="_blank" href={`${playpass?.VenueMapLink}`}> {playpass?.VenueMapLink} </a>
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Play Area: </strong> {playpass?.VenueAllocations[0]?.PlayArea?.PlayAreaName}
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Slot: </strong>{new Date(playpass?.VenueAllocations[0]?.Slot).toLocaleDateString(
                        "en-US",
                        {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                        }
                    )}
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Duration: </strong> {playpass?.VenueAllocations[0]?.Duration} mins</p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Price (INR): </strong> {playpass?.VenueAllocations[0]?.FinalPrice}
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Price (Credits): </strong> {playpass?.VenueAllocations[0]?.FinalPriceCredits}
                </p>
                <p className="text-lg text-gray-700 mb-4">
                    <strong>Is Booked: </strong> {playpass?.VenueAllocations[0]?.IsBooked ? "Yes" : "No"}
                </p>
            </div>




            {/* FAQs*/}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <h2 className="text-4xl font-bold text-gray-900 mb-4">
                    FAQs
                </h2>
                {playpass?.FAQ?.length > 0 ? (
                    <div className="text-lg text-gray-700">
                        {playpass.FAQ.map((faq, index) => (
                            <div key={index} className="mb-4">
                                <p className="font-bold ">Ques {index + 1}: {faq.question}</p>
                                <p className="font-semibold">Ans: {faq.answer}</p>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className="text-gray-700">No FAQs available.</p>
                )}

            </div>

            {/* Edit Event Form Overlay */}
            {isEditing && (
                <EditPlaypassForm
                    playpass={playpass}
                    onSave={handleSaveChanges}
                    onCancel={handleCancelEdit}
                />
            )}
            {/* Edit Event Form Overlay */}
            {isUPdateScore && (
                <PlaypassScoring
                    playpass={playpass}
                    teamData={teamData}
                    onSave={handleSaveChanges}
                    onCancel={handleCancelUpdateScore}
                />
            )}

        </div>
    )
}

export default PlaypassDetails
