import React, { useState, useEffect } from "react";
import ToastNotification from "../../components/ToastNotification.js";
import { eventOutcome, getEventOutcome, updateEventOutcome } from "../../api/api.js";
import { FaTimes } from "react-icons/fa";

const UpdateScore = ({ event, onCancel, eventMemberData }) => {

    console.log("evmbdt", eventMemberData)
    const [toast, setToast] = useState(null);
    const [outcome, setOutcome] = useState(null);
    const [formData, setFormData] = useState(
        {
            "TeamId": "",
            "Position": 0,
        }
    )


    useEffect(() => {
        const getOutcome = async () => {
            try {
                const outcomeResponse = await getEventOutcome(event.EventId)
                console.log("event outcome is: ", outcomeResponse)
                setOutcome(outcomeResponse)
            } catch (error) {
                console.error('error in getOutcome function in event details page', error)
            }
        }
        getOutcome();
    }, [])


    console.log("event member data", eventMemberData)
    // Extract unique teams
    let uniqueTeams
    if(eventMemberData){
         uniqueTeams = Array.from(
            new Map(eventMemberData.filter((member) => member.TeamId) // Remove null TeamId
                .map((member) => [member.TeamId, member.TeamName]) // Map to [id, name]
            ).entries()
        ).map(([TeamId, TeamName]) => ({ TeamId, TeamName })); // Convert back to array
    }
    



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.TeamId) {
            setToast({ message: "Please select a team.", type: "error" });
            return;
        }
        const existingTeam = outcome?.find((team) => team.TeamId === formData.TeamId);

        try {
            const payload = [{
                TeamId: formData.TeamId,
                Position: parseInt(formData.Position, 10),
                Points: parseInt(formData.Points, 10),
            }];

            if(existingTeam){
                await updateEventOutcome(event.EventId, payload)
            }else{
                await eventOutcome(event.EventId, payload);
            }

            setToast({ message: "Score updated successfully!", type: "success" });
            setTimeout(() => {
                onCancel();
                window.location.reload();
            }, 500);

        } catch (error) {
            console.error("Error updating score:", error);
            setToast({
                message: "Failed to update/ edit outcome.",
                type: "error",
            });
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="relative bg-white p-4 md:p-6 rounded-lg shadow-lg w-11/12 max-w-2xl">
                {/* Close Button */}
                <button
                    type="button"
                    onClick={onCancel}
                    className="absolute top-3 right-3 text-gray-600 hover:text-red-500 text-2xl"
                >
                    <FaTimes />
                </button>

                <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-center">
                    Enter Outcome Here
                </h2>
                {toast && (
                    <ToastNotification
                        message={toast.message}
                        type={toast.type}
                        onClose={() => setToast(null)}
                    />
                )}

                <div className="overflow-y-auto max-h-[70vh] pr-2">
                    <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
                        {/* Select Team */}
                        <div>
                            <label className="block text-lg font-semibold mb-1">
                                Select Team *
                            </label>
                            <select
                                name="TeamId"
                                value={formData.TeamId}
                                onChange={handleInputChange}
                                className="w-full p-2 border border-gray-300 rounded-lg"
                                required
                            >
                                <option value="" disabled>Select a team</option>
                                {uniqueTeams?
                                (uniqueTeams.map((team) => (
                                    <option key={team.TeamId} value={team.TeamId}>
                                        {team.TeamName}
                                    </option>
                                ))): (
                                    <option value="" disabled>No Teams available!</option>
                                )}
                            </select>
                        </div>
                        {/* Enter Position */}
                        <div>
                            <label className="block text-lg font-semibold mb-1">
                                Enter Position *
                            </label>
                            <p className="text-base">Enter number between (1-{event.TotalTeams})</p>
                            <input
                                type="number"
                                name="Position"
                                min="1"
                                max={event.TotalTeams}
                                value={formData.Position}
                                onChange={handleInputChange}
                                className="w-full p-2 border border-gray-300 rounded-lg"
                                required
                            />
                        </div>


                        {/* Submit Button */}
                        <div className="text-right">
                            <button
                                type="submit"
                                className="bg-blue-500 text-white p-2 rounded-lg"
                            >
                                Save Changes
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    )
}

export default UpdateScore
