import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom"; // To get event ID from the URL
import {
  fetchEventDetails,
  updateEvent,
  downloadFile,
  getEventMembers,
  getEventOutcome,
} from "../../api/api"; // Fetch event details by ID
import EditEventForm from "../../components/EditEventForm";
import UpdateScore from "./UpdateScoreEvent";
import { useNavigate } from "react-router-dom";
import {
  FaClipboardList,
  FaEdit,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import { MdPeopleAlt } from "react-icons/md";
import { TbScoreboard } from "react-icons/tb";
import Loader from "../../components/Loader";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

const EventDetails = () => {
  const { id } = useParams(); // Get the event ID from the URL
  const [event, setEvent] = useState(null);
  const [eventMemberData, setEventMemberData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // To toggle the editing mode
  const [isUpdateScore, setIsUpdateScore] = useState(false);
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [sponsorImages, setSponsorImages] = useState([]);
  const [openFAQIndex, setOpenFAQIndex] = useState(null);
  const [outcome, setOutcome] = useState(null);
  const navigate = useNavigate();

  const convertToIST = (date) => {
    // Convert to Date object if it's not already one
    const validDate = date instanceof Date ? date : new Date(date);
    if (isNaN(validDate)) {
      throw new Error("Invalid date provided to convertToIST");
    }
    const ISTOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
    const dateInIST = new Date(validDate.getTime() + ISTOffset);
    return dateInIST.toISOString();
  };

  // Fetch event details when the component mounts
  useEffect(() => {
    const getEventDetails = async () => {
      try {
        const eventData = await fetchEventDetails(id);
        setEvent({
          ...eventData,
          FAQ: eventData.FAQ && eventData.FAQ.length > 0 ? eventData.FAQ : null,
        });

        // Fetch sponsor images if SponsorImageFileId exists
        if (eventData?.sponsors?.length > 0) {
          const images = await Promise.all(
            eventData.sponsors.map(async (sponsor) => {
              try {
                // Find the rectangle image in the sponsor's images
                const rectangleImage = sponsor.SponsorImages.find(
                  (image) => image.Tags === "RECTANGLE"
                );

                if (rectangleImage?.ImageId) {
                  const imageUrl = await downloadFile(rectangleImage.ImageId);
                  return {
                    SponsorId: sponsor.SponsorId,
                    imageUrl,
                  };
                }

                return { SponsorId: sponsor.SponsorId, imageUrl: null };
              } catch (err) {
                console.error(
                  `Failed to fetch image for sponsor ${sponsor.SponsorId}:`,
                  err
                );
                return { SponsorId: sponsor.SponsorId, imageUrl: null };
              }
            })
          );
          setSponsorImages(images);
        }
      } catch (error) {
        setError("Failed to load event details");
        console.error("Error fetching event details:", error);
      } finally {
        setLoading(false);
      }
    };

    getEventDetails();
  }, [id]);

  useEffect(() => {
    const getEventMemberData = async () => {
      try {
        const eventMember = await getEventMembers(id);
        setEventMemberData(eventMember);
      } catch (error) {
        console.error(
          "Error in getEventMemberData func in EventDetails page",
          error
        );
      }
    };
    getEventMemberData();
  }, []);

  useEffect(() => {
    const getOutcome = async () => {
      try {
        const outcomeResponse = await getEventOutcome(id);
        console.log("event outcome is: ", outcomeResponse);
        setOutcome(outcomeResponse);
      } catch (error) {
        console.error(
          "error in getOutcome function in event details page",
          error
        );
      }
    };
    getOutcome();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p className="text-center text-red-500">{error}</p>;
  }
  const validFAQs = event?.FAQ?.filter(
    (faq) => faq.question.trim() !== "" || faq.answer.trim() !== ""
  );
  const handleUpdateScore = () => {
    setIsUpdateScore(true);
  };
  const handleCancelUpdateScore = () => {
    setIsUpdateScore(false);
  };

  const handleEditClick = () => {
    setIsEditing(true); // Enable editing when the Edit button is clicked
  };

  // Handling Cancel Edit
  const handleCancelEdit = () => {
    setIsEditing(false); // Disable editing when cancel button is clicked
  };

  // Save changes to the event
  const handleSaveChanges = async (updatedEventData) => {
    try {
      // Do NOT remove the EventId, pass it along with the updated data
      const updatedEvent = await updateEvent(event.EventId, updatedEventData);

      // If the updated event doesn't include all details, merge the old event data
      const finalEvent = {
        ...event, // Preserve existing event data
        ...updatedEvent, // Override with updated data
      };

      // Update the event data in state with the merged event data
      setEvent(finalEvent); // Save the updated event data to state
      setIsEditing(false); // Disable editing mode

      if (updatedEvent && updatedEvent.EventId) {
        setShowSuccessNotification(true);

        // Hide notification after 3 seconds
        setTimeout(() => {
          setShowSuccessNotification(false);
        }, 3000);
      }
    } catch (error) {
      setError("Failed to save changes");
      console.error("Error saving event:", error);
    }
  };

  const handleShowMembersClick = () => {
    // Navigate to the Event Members page for this event
    navigate(`/event/details/${id}/registration`); // Use the correct event ID
  };

  const toggleFAQ = (index) => {
    setOpenFAQIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="container mx-auto p-6 relative">
      {/* Success Notification */}
      {showSuccessNotification && (
        <div className="absolute top-6 right-6 p-4 bg-green-500 text-white rounded-lg shadow-lg flex items-center">
          <span className="mr-2">✔️</span>
          <span>Event successfully updated!</span>
        </div>
      )}
      {/* Edit Button */}
      <div className="flex gap-x-2 absolute top-8 right-8">
        <button
          className="text-[#512D9A] py-1 px-3 border-2 border-[#F2F2F2] rounded hover:shadow-sm hover:font-medium  flex items-center justify-center"
          onClick={handleEditClick} // Trigger editing mode when clicked
        >
          {/* Show text on larger screens */}
          <span className="hidden sm:inline">Edit</span>
          {/* Show icon on small screens */}
          <FaEdit className="h-5 w-5 sm:hidden" />
        </button>

        <button
          className="text-[#512D9A] py-1 px-3 border-2 border-[#F2F2F2] rounded hover:shadow-sm hover:font-medium  flex items-center justify-center"
          onClick={handleUpdateScore}
        >
          {/* Show text on larger screens */}
          <span className="hidden sm:inline">Update/ Edit Outcome</span>
          {/* Show icon on small screens */}
          <TbScoreboard className="h-5 w-5 sm:hidden" />
        </button>

        <Link to={`/events/details/${id}/teams`} state={{ eventData: event }}>
          <div
            className=" text-[#512D9A] py-1 px-3 border-2 border-[#F2F2F2] rounded hover:shadow-sm hover:font-medium  flex items-center justify-center"
            // Trigger editing mode when clicked
          >
            {/* Show text on larger screens */}
            <span className="hidden sm:inline">Teams</span>
            {/* Show icon on small screens */}
            <MdPeopleAlt className="h-5 w-5 sm:hidden" />
          </div>
        </Link>

        {/* Show Event Registrations Button */}
        <button
          className="text-[#512D9A] py-1 px-3 border-2 border-[#F2F2F2] rounded hover:shadow-sm hover:font-medium  flex items-center justify-center"
          onClick={handleShowMembersClick} // Trigger event members page redirection
        >
          {/* Show text on larger screens */}
          <span className="hidden sm:inline">Registration Details</span>
          {/* Show icon on small screens */}
          <FaClipboardList className="h-5 w-5 sm:hidden" />
        </button>
      </div>
      {/* Event Details */}
      <div className="bg-white shadow-lg rounded-lg p-6 pt-10 mb-6">
        <h2 className="text-4xl font-bold text-gray-900 mb-4">
          {event?.EventTitle}
        </h2>

        <div className="text-lg text-gray-700 mb-4">
          <strong>Description:</strong>
          <ReactMarkdown
            children={event?.EventDescription || "No description provided."}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            components={{
              p: ({ children }) => <p className="text-gray-700">{children}</p>,
              strong: ({ children }) => (
                <strong className="font-bold">{children}</strong>
              ),
              em: ({ children }) => <em className="italic">{children}</em>,
              a: ({ children, href }) => (
                <a href={href} className="text-blue-500 underline">
                  {children}
                </a>
              ),
              ul: ({ children }) => (
                <ul className="list-disc pl-5">{children}</ul>
              ),
            }}
          />
        </div>
        <div className="text-lg text-gray-700 mb-4">
          <strong>About Event:</strong>
          <ReactMarkdown
      children={event?.About || "No About provided."}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        p: ({ children }) => (
          <p className="text-gray-700">{children}</p>
        ),
        strong: ({ children }) => (
          <strong className="font-bold">{children}</strong>
        ),
        em: ({ children }) => (
          <em className="italic">{children}</em>
        ),
        a: ({ children, href }) => (
          <a href={href} className="text-blue-500 underline">
            {children}
          </a>
        ),
        ul: ({ children }) => (
          <ul className="list-disc pl-5">{children}</ul>
        ),
      }}
    />
        </div>
      </div>
      {/* Event Information Section */}
      <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
        <h3 className="text-2xl font-semibold text-gray-900 mb-4">
          Event Information
        </h3>
        <div className="space-y-4">
          <p className="text-lg text-gray-700">
            <strong>Event Type:</strong> {event?.EventType}
          </p>
          <p className="text-lg text-gray-700">
            <strong>Event Sport:</strong>{" "}
            {event?.sport?.SportName || "Not Available"}
          </p>
          <p className="text-lg text-gray-700">
            <strong>Event Level:</strong> {event?.EventLevel?.join(", ")}
          </p>
          <p className="text-lg text-gray-700">
            <strong>Team Size:</strong> {event?.TeamSize}
          </p>
          <p className="text-lg text-gray-700">
            <strong>Total Teams:</strong> {event?.TotalTeams}
          </p>
          <p className="text-lg text-gray-700">
            <strong>Is Partner Allowed:</strong>{" "}
            {event?.IsPartnerAllowed == true ? "Yes" : "No"}
          </p>
          <p className="text-lg text-gray-700">
            <strong>Is Paid:</strong> {event?.isPaid == true ? "Yes" : "No"}
          </p>

          <p className="text-lg text-gray-700">
            <strong>Price:</strong> {event?.PriceCurrency + " " + event?.Price}
          </p>
          <p className="text-lg text-gray-700">
            <strong>Price (Credits):</strong> {event?.PriceCredits}
          </p>
          <p className="text-lg text-gray-700">
            <strong>Registration Status:</strong>{" "}
            {event?.isRegistrationOpen == true ? "Open" : "Closed"}
          </p>
          <p className="text-lg text-gray-700">
            <strong>Is Full ? :</strong> {event?.isFull == true ? "Yes" : "No"}
          </p>
        </div>
      </div>
      {/* Event Outcome */}
      <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
        <h3 className="text-2xl font-semibold text-gray-900 mb-4">
          Event Outcome
        </h3>

        {outcome && outcome.length > 0 ? (
          <div className="space-y-4">
            {outcome.map((team, index) => (
              <div
                key={index}
                className="p-2 border rounded-lg shadow-sm bg-green-200"
              >
                <p className="text-gray-700">
                  <strong>Team:</strong> {team.TeamName}
                </p>
                <p className="text-gray-700">
                  <strong>Position:</strong> {team.Position}
                </p>
                {/* <p className="text-gray-700">
                  <strong>Points:</strong> {team.Points}
                </p> */}
              </div>
            ))}
          </div>
        ) : (
          <p className="text-lg text-red-400">
            Outcome hasn't been recorded yet!
          </p>
        )}
      </div>
      {/* Venue Details */}
      <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
        <h3 className="text-2xl font-semibold text-gray-900 mb-4">Venue</h3>
        <p className="text-lg text-gray-700">
          <strong>Name:</strong> {event?.venue?.VenueName}
        </p>
        <p className="text-lg text-gray-700">
          <strong>Address:</strong> {event?.venue?.VenueAddress?.city},{" "}
          {event?.venue?.VenueAddress?.state},{" "}
          {event?.venue?.VenueAddress?.country}
        </p>
        <p className="text-lg text-gray-700">
          <strong>Map Link: </strong>
          {event?.venue?.MapLink ? (
            <a
              href={event?.venue?.MapLink}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              {event?.venue?.MapLink}
            </a>
          ) : (
            " Not available"
          )}
        </p>
      </div>
      {/* Event Schedule */}
      <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
        <h3 className="text-2xl font-semibold text-gray-900 mb-4">Schedule</h3>

        <p className="text-lg text-gray-700">
          <strong>Start Time Stamp:</strong>{" "}
          {new Date(event?.EventSchedule?.StartTimeStamp).toLocaleDateString(
            "en-US",
            {
              day: "2-digit",
              month: "short",
              year: "numeric",
              hour: "2-digit",
              hour12: true,
              minute: "2-digit",
            }
          )}
        </p>
        <p className="text-lg text-gray-700 mb-2">
          <strong>End Time Stamp:</strong>{" "}
          {new Date(event?.EventSchedule?.EndTimeStamp).toLocaleDateString(
            "en-US",
            {
              day: "2-digit",
              month: "short",
              year: "numeric",
              hour: "2-digit",
              hour12: true,
              minute: "2-digit",
            }
          )}
        </p>

        <p className="text-lg text-gray-700">
          <strong>Registration Start Time Stamp:</strong>{" "}
          {new Date(
            event?.EventSchedule?.RegistrationStartTimeStamp
          ).toLocaleDateString("en-US", {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            hour12: true,
            minute: "2-digit",
          })}
        </p>
        <p className="text-lg text-gray-700">
          <strong>Registration End Time Stamp:</strong>{" "}
          {new Date(
            event?.EventSchedule?.RegistrationEndTimeStamp
          ).toLocaleDateString("en-US", {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            hour12: true,
            minute: "2-digit",
          })}
        </p>
      </div>
      {/* Sponsors */}
      <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
        <h3 className="text-2xl font-semibold text-gray-900 mb-4">Sponsors</h3>
        {event?.sponsors?.length > 0 ? (
          event.sponsors.map((sponsor) => {
            const sponsorImage = sponsorImages.find(
              (img) => img.SponsorId === sponsor.SponsorId
            );
            return (
              <div
                key={sponsor.SponsorId}
                className="flex items-center space-x-4 mb-4"
              >
                {sponsorImage?.imageUrl ? (
                  <img
                    src={sponsorImage.imageUrl}
                    alt={sponsor.SponsorName}
                    className="w-32 h-20 object-cover rounded-lg"
                  />
                ) : (
                  <div className="w-32 h-20 rounded-lg bg-gray-200 flex items-center justify-center">
                    <span className="text-gray-500">No Image</span>
                  </div>
                )}
                <p className="text-lg text-gray-700 font-medium">
                  {sponsor.SponsorName}
                </p>
              </div>
            );
          })
        ) : (
          <p className="text-lg text-gray-700">
            No sponsors listed for this event.
          </p>
        )}
      </div>
      {/* Filter out FAQs that have both empty question and answer */}

      {validFAQs.length > 0 && (
        <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
          <h3 className="text-2xl font-semibold text-gray-900 mb-6">FAQs</h3>
          <div className="space-y-4">
            {validFAQs.map((faq, index) => (
              <div
                key={index}
                className={`transition-all border border-gray-200 rounded-lg overflow-hidden shadow-md ${
                  openFAQIndex === index ? "bg-gray-50" : ""
                }`}
              >
                <div
                  className="flex justify-between items-center p-4 cursor-pointer hover:bg-gray-100 transition-colors duration-300"
                  onClick={() => toggleFAQ(index)}
                >
                  <p className="text-lg text-gray-900 font-medium">
                    <span className="text-gray-400 font-bold mr-2">{`Q${
                      index + 1
                    }`}</span>
                    {faq.question}
                  </p>
                  {openFAQIndex === index ? (
                    <FaChevronUp className="text-blue-600" />
                  ) : (
                    <FaChevronDown className="text-gray-600" />
                  )}
                </div>
                {openFAQIndex === index && (
                  <div className="p-4 text-gray-700 bg-gray-50 border-t border-gray-200">
                    <span className="block text-gray-800 font-semibold mb-1">{`Answer:`}</span>
                    {faq.answer}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {/* Edit Event Form Overlay */}
      {isEditing && (
        <EditEventForm
          event={event}
          onSave={handleSaveChanges}
          onCancel={handleCancelEdit}
        />
      )}
      {isUpdateScore && (
        <UpdateScore
          event={event}
          onCancel={handleCancelUpdateScore}
          eventMemberData={eventMemberData}
        />
      )}
    </div>
  );
};

export default EventDetails;
