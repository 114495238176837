import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getEventMembers, fetchUserDetails, fetchTeamById, createEventTeam, UpdateTeamName } from '../../api/api';
import ToastNotification from '../../components/ToastNotification';
import { FaEdit } from 'react-icons/fa';
import { useAuthContext } from '../../AuthContext';

const EventTeams = () => {

    const { UserId } = useAuthContext();

    const location = useLocation();
    const { eventData } = location.state;
    const [allUsers, setAllUsers] = useState([]);
    const [nonTeamUsers, setNonTeamUsers] = useState([]);
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
    const [teamName, setTeamName] = useState('');
    const [skillLevel, setSkillLevel] = useState('Novice');
    const [toast, setToast] = useState(null);
    const [uniqueTeams, setUniqueTeams] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isEditingTeamName, setIsEditingTeamName] = useState(null);
    const [editedTeamName, setEditedTeamName] = useState("");

    useEffect(() => {
        const getEventMemberData = async () => {
            try {
                console.log("event data: ", eventData);
                const eventMember = await getEventMembers(eventData.EventId);

                setAllUsers(eventMember);

                // Filter non-team users
                const nonTeam = eventMember.filter(member => member.TeamId === null);
                setNonTeamUsers(nonTeam);

                // Process team members
                const teamMembers = eventMember.filter(member => member.TeamId !== null);

                // Find unique teams
                const teamSet = new Map();
                teamMembers.forEach(member => {
                    if (!teamSet.has(member.TeamId)) {
                        teamSet.set(member.TeamId, { teamId: member.TeamId, teamName: member.TeamName || "Unknown Team" });
                    }
                });

                // Convert to array and store in state
                const uniqueTeamsArray = Array.from(teamSet.values());
                setUniqueTeams(uniqueTeamsArray);

                // Organize team members
                const teamsMap = teamMembers.reduce((acc, member) => {
                    if (!acc[member.TeamId]) {
                        acc[member.TeamId] = { teamName: member.TeamName || "Unknown Team", members: [] };
                    }
                    acc[member.TeamId].members.push(member);
                    return acc;
                }, {});

                const formattedTeams = Object.entries(teamsMap).map(([teamId, data]) => ({
                    teamId,
                    teamName: data.teamName,
                    members: data.members
                }));

                setTeams(formattedTeams);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };
        getEventMemberData();
    }, [eventData, eventData.EventId]);


    const handleCreateTeam = async () => {
        const newTeam = {
            EventId: eventData.EventId,
            TeamCreation: [
                {
                    TeamName: teamName,
                    TeamImages: [
                        {
                            FileId: "",
                            Tags: [
                                "SPOTLIGHT"
                            ]
                        }
                    ],
                    SportId: eventData.sport.SportId,
                    TeamType: eventData.EventType,
                    SkillLevel: skillLevel,
                    EventMemberIds: selectedUsers.filter(id => id)
                }
            ]
        }
        console.log(newTeam.TeamCreation[0].EventMemberIds.length); // Debugging output


        if (newTeam.TeamCreation[0].EventMemberIds.length === eventData.TeamSize) {
            try {
                await createEventTeam(newTeam);
                setShowCreateTeamModal(false);
                setTeams([...teams, { teamId: new Date().getTime(), teamName, members: [] }]);
                setToast({ message: "Team created successfully!", type: "success" });
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            } catch (error) {
                console.error("Error creating team:", error);
                setToast({
                    message: error.response?.data?.message || "Failed to update score.",
                    type: "error",
                });
            }
        } else {
            setToast({
                message: `team must have ${eventData.TeamSize} people`, type: "error"
            })
        }
    };


    // Handle input change
    const handleNameChange = (e) => {
        setEditedTeamName(e.target.value);
    };
    const editingTeamName = (teamId, currentName) => {
        setIsEditingTeamName(teamId); // Set only the clicked team to edit mode
        setEditedTeamName(currentName);
    };
    // Submit updated team name
    const editNameSubmit = async (teamId) => {
        if (!editedTeamName.trim()) {
            setToast({
                message: "Name can't be empty!", type: "error"
            })
            return
        }; // Prevent empty name updates


        try {
            console.log("entering here")
            await UpdateTeamName(teamId, UserId, editedTeamName)
            console.log("now here")

            setToast({
                message: "Updated Team Name successfully!", type: "success"
            })
            setIsEditingTeamName(null); // Exit edit mode
            setTimeout(() => {
                window.location.reload();
            }, 500);
        } catch (error) {
            console.error("Error updating team name:", error);
            setToast({
                message: "Error in updating team name!", type: "error"
            })
        }
    };
    // Cancel editing
    const editNameCancel = () => {
        setIsEditingTeamName(null);
    };

    if (loading) {
        return <div className="p-4">Loading participants...</div>;
    }

    return (
        <div className="p-4">
            <div className='bg-white shadow-lg rounded-lg p-6 mb-6 flex flex-col md:flex-row md:justify-between md:items-center'>
                <h1 className="text-4xl font-bold text-gray-900 mb-4">Event Participants</h1>

                <div>
                    {/* Create Team Box */}
                    {uniqueTeams.length < eventData.TotalTeams && nonTeamUsers.length > 0 && (
                        <button className="bg-blue-500 text-white p-2 rounded-lg cursor-pointer text-center mb-4" onClick={() => setShowCreateTeamModal(true)}>
                            Group to Team +
                        </button>
                    )}
                </div>
            </div>
            {toast && (
                <ToastNotification
                    message={toast.message}
                    type={toast.type}
                    onClose={() => setToast(null)}
                />
            )}


            {/* Teams Section */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <h2 className="text-2xl font-semibold text-gray-900 mb-4">Teams</h2>
                <div className="grid gap-4">
                    {teams?.length > 0 ? (
                        teams.map((team, i) => (
                            <div key={team.teamId} className="bg-white p-4 rounded-lg shadow-md">
                                <h3 className="font-semibold text-lg mb-2 flex gap-x-3 items-center">Team {i + 1}:

                                    {/* Show Team Name and Edit */}
                                    {isEditingTeamName === team.teamId ?
                                        (
                                            <span className='flex items-center gap-x-2'>
                                                <input type='text'
                                                    name='teamName'
                                                    value={editedTeamName}
                                                    onChange={handleNameChange}
                                                    placeholder='Update Team Name'
                                                    className='border border-black rounded-md text-base p-1'
                                                />
                                                <button onClick={() => editNameSubmit(team.teamId)} type='submit'
                                                    className='text-sm bg-blue-500 text-white rounded-md px-2 py-2'
                                                > Update Name
                                                </button>
                                                <button onClick={editNameCancel} type='submit'
                                                    className='text-sm bg-red-500 text-white rounded-md px-2 py-2'
                                                > Cancel
                                                </button>
                                            </span>
                                        )
                                        :
                                        (
                                            <span className='flex items-center gap-x-2'>
                                                {team.teamName}<FaEdit onClick={() => editingTeamName(team.teamId, team.teamName)}
                                                    className="h-4 w-4 text-blue-600 cursor-pointer"
                                                />
                                            </span>
                                        )}

                                </h3>
                                <h3 className="font-normal text-sm mb-2">Team ID: {team.teamId}</h3>
                                <ul className="space-y-2">
                                    {team.members.map((member, i) => (
                                        <li key={member.EventMemberId} className="bg-gray-50 p-2 rounded-lg">
                                            <p className="font-medium">
                                                Player {i + 1}{": "} {member.UserName}
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                {member.userDetails?.User?.EmailAddress}
                                            </p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))
                    ) : (
                        <div>No teams found</div>
                    )}
                </div>
            </div>


            {/* Two Column Layout */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <div className="flex gap-6 mb-8">
                    {/* All Users Column */}
                    <div className="flex-1">
                        <h2 className="text-2xl font-semibold text-gray-900 mb-4">Confirmed Participants</h2>
                        <ul className="space-y-2">
                            {allUsers && allUsers.filter(member => member.TeamId !== null).length > 0 ? (
                                allUsers
                                    .filter(member => member.TeamId !== null) // Only include members with a valid TeamId
                                    .map((member, index) => (
                                        <li key={member.EventMemberId} className="bg-gray-100 p-3 rounded-lg">


                                            <p className="font-medium">
                                                {index + 1} {" "} {member.UserName}
                                            </p>
                                            <p className="text-sm text-gray-600">User ID: {member.UserId}</p>
                                            <p className="text-sm text-gray-600">Event Member ID: {member.EventMemberId}</p>
                                            <p className="text-sm text-gray-600">
                                                {member.TeamId ? `Team: ${member.TeamName || 'Unknown Team'}` : <p className='text-blue-500'>Individual</p>}
                                            </p>
                                        </li>
                                    ))
                            ) : (<p>No participants found!</p>)}
                        </ul>
                    </div>

                    {/* Non-team Users Column */}

                    <div className="flex-1">
                        <h2 className="text-2xl font-semibold text-gray-900 mb-4">Participants without team</h2>
                        <ul className="space-y-2">
                            {nonTeamUsers.length > 0 ?
                                nonTeamUsers.map((member) => (
                                    <li key={member.EventMemberId} className="bg-blue-50 p-3 rounded-lg">
                                        <p className="font-medium">
                                            {member.UserName}
                                        </p>
                                        <p className="text-sm text-gray-600">User ID: {member.UserId}</p>
                                        <p className="text-sm text-gray-600">
                                            {member.userDetails?.User?.EmailAddress}
                                        </p>
                                    </li>
                                ))
                                :
                                (
                                    <p className="bg-blue-50 p-3 rounded-lg text-center" >No Participants found</p>
                                )}

                        </ul>
                    </div>


                </div>
            </div>



            {/* Create Team Modal */}
            {showCreateTeamModal && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                        {/* <div className='w-full flex flex-col md:flex-row md:gap-x-2 md:items-center mb-4'>
                        <h2 className="text-lg font-semibold ">Create Team</h2>
                        <p className='' >({eventData.TeamSize} members required)</p>
                        </div> */}
                        <h2 className="text-2xl font-semibold mb-4 ">Create Team</h2>
                        <p className='mb-4 text-base' >{eventData.TeamSize} members required <span className='text-red-500'>*</span></p>

                        <div id="form-content-event-team" className='max-h-96 overflow-y-auto mb-4'>
                            {/* Team Name Input */}
                            <h2 className='font-medium'>Enter Team Name:</h2>
                            <input
                                type="text"
                                placeholder="Team Name"
                                value={teamName}
                                onChange={(e) => setTeamName(e.target.value)}
                                className="border p-2 w-full mb-4"
                            />

                            {/* Skill Level Dropdown */}
                            <h2 className='font-medium'>Select Skill Level:</h2>
                            <select
                                value={skillLevel}
                                onChange={(e) => setSkillLevel(e.target.value)}
                                className="border p-2 w-full mb-4"
                            >
                                <option value="Novice">Novice</option>
                                <option value="Beginner">Beginner</option>
                                <option value="Intermediate">Intermediate</option>
                                <option value="Expert">Expert</option>
                            </select>

                            {/* User Selection Dropdowns */}
                            <h2 className='font-medium '>Select Users:</h2>
                            {Array.from({ length: eventData.TeamSize }).map((_, index) => (
                                <select
                                    required
                                    key={index}
                                    value={selectedUsers[index] || ""}
                                    onChange={(e) => {
                                        const newSelectedUsers = [...selectedUsers];
                                        newSelectedUsers[index] = e.target.value;
                                        setSelectedUsers(newSelectedUsers);
                                    }}
                                    className="border p-2 w-full mb-2"
                                >
                                    <option value="">Select User</option>
                                    {nonTeamUsers
                                        .filter((user) => {
                                            // Show user if not selected in other dropdowns
                                            return !selectedUsers.some(
                                                (id, idx) => idx !== index && id === user.EventMemberId
                                            );
                                        })
                                        .map((user) => (
                                            <option key={user.EventMemberId} value={user.EventMemberId}>
                                                {user.UserName}
                                            </option>
                                        ))}
                                </select>
                            ))}
                        </div>


                        {/* Submit Button */}
                        <button
                            className="bg-blue-500 text-white p-2 rounded-lg w-full"
                            onClick={handleCreateTeam}
                        >
                            Submit
                        </button>

                        {/* Cancel Button */}
                        <button
                            className="bg-gray-400 text-white p-2 rounded-lg w-full mt-2"
                            onClick={() => setShowCreateTeamModal(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}

        </div>
    );
};

export default EventTeams;
