import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchUserRewards, downloadFile } from "../../api/api";

const UserRewards = () => {
  const { userId } = useParams();
  const [rewards, setRewards] = useState([]);
  const [filteredRewards, setFilteredRewards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [imageCache, setImageCache] = useState({}); // Store downloaded images

  useEffect(() => {
    if (!userId) {
      setError("Invalid User ID");
      setLoading(false);
      return;
    }

    const fetchRewards = async () => {
      try {
        const data = await fetchUserRewards(userId);
        setRewards(data);
        setFilteredRewards(data);
        setError(null);
      } catch (err) {
        if (err.response?.status === 404) {
          setError("No rewards found for this user.");
        } else {
          setError("Failed to fetch rewards.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchRewards();
  }, [userId]);

  // Fetch images for rewards
  useEffect(() => {
    const fetchImages = async () => {
      const newImageCache = { ...imageCache };

      await Promise.all(
        rewards.map(async (reward) => {
          if (reward.RewardImageFileId && !newImageCache[reward.RewardImageFileId]) {
            try {
              const fileUrl = await downloadFile(reward.RewardImageFileId);
              newImageCache[reward.RewardImageFileId] = fileUrl;
            } catch (error) {
              console.error("Error fetching reward image:", error);
              newImageCache[reward.RewardImageFileId] = null; // Handle missing images gracefully
            }
          }
        })
      );

      setImageCache(newImageCache);
    };

    if (rewards.length > 0) {
      fetchImages();
    }
  }, [rewards]);

  // Search Functionality
  useEffect(() => {
    const filtered = rewards.filter((reward) =>
      reward.RewardName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredRewards(filtered);
  }, [searchQuery, rewards]);

  return (
    <div className="p-6 bg-white shadow rounded-lg">
      <h1 className="text-xl font-bold mb-4">User Rewards</h1>

      {/* Search Bar */}
      <input
        type="text"
        placeholder="Search rewards by name..."
        className="w-full p-2 border border-gray-300 rounded mb-4"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      {loading ? (
        <p>Loading rewards...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : filteredRewards.length === 0 ? (
        <p className="text-gray-500">No matching rewards found.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredRewards.map((reward) => (
            <div
              key={reward.RewardId}
              className="bg-gray-50 p-4 rounded-lg shadow-md flex flex-col items-center"
            >
              {/* Reward Image */}
              {imageCache[reward.RewardImageFileId] ? (
                <img
                  src={imageCache[reward.RewardImageFileId]}
                  alt={reward.RewardName}
                  className="w-full h-40 object-cover rounded-lg mb-2"
                />
              ) : (
                <div className="w-full h-40 bg-gray-200 rounded-lg mb-2 flex items-center justify-center">
                  <span className="text-gray-500">Image Not Available</span>
                </div>
              )}

              {/* Reward Details */}
              <h2 className="text-lg font-bold">{reward.RewardName}</h2>
              <p className="text-sm text-gray-600 text-center">
                {reward.RewardDescription}
              </p>

              {/* Coupon Code */}
              <p className="mt-2 text-sm">
                <strong>Coupon Code:</strong> {reward.CouponCode || "N/A"}
              </p>

              {/* Expiry Date */}
              <p className="text-sm">
                <strong>Expiry Date:</strong>{" "}
                {new Date(reward.RewardExpiry).toLocaleDateString()}
              </p>

              {/* Terms (if available) */}
              {reward.Terms && (
                <p className="mt-2 text-sm text-gray-500 italic">
                  {reward.Terms}
                </p>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserRewards;
