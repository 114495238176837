import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // make sure you're importing Routes and Route
import { AuthProvider } from "./AuthContext"; // Auth provider
import PrivateRoute from "./components/PrivateRoute"; // PrivateRoute to handle protected routes
import DashboardHome from "../admin/pages/DashboardHome"; 
import Sidebar from "../admin/Sidebar";
import Profile from "./pages/Profile"; // Admin Profile Page
import LoginPage from "./pages/LoginPage"; 


//Events components import
import CreateEvent from "./pages/Events/CreateEvent"; 
import EventDetails from "./pages/Events/EventDetails"; 
import EventsList from "./pages/Events/EventsList"; 
import RegistrationDetails from "./pages/Events/RegistrationDetails";

//Sponsors components import
import SponsorsList from "../admin/pages/Sponsors/SponsorsList"; 
import CreateSponsor from "./pages/Sponsors/CreateSponsor";

//venues components import
import CreateVenue from "./pages/Venues/CreateVenue";
import VenuesList from "./pages/Venues/VenuesList"; 
import SlotDetails from "./pages/Venues/VenueSlotDetail";
import CreateVenueSlot from "./pages/Venues/CreateVenueSlot";
import CreateRegion from "./pages/Venues/CreateRegion";
import PlayArea from "./pages/Venues/PlayAreaList";
import AddPlayArea from "./pages/Venues/AddPlayArea";

//coachings components import
import CoachingList from "./pages/Coaching/CoachingList";
import CoachingDetails from "./pages/Coaching/CoachingDetails";
import CreateCoaching from "./pages/Coaching/CreateCoaching";
import CreateCoachingAllocation from "./pages/Coaching/CreateCoachingAllocation";
import CoachingMembers from "./pages/Coaching/CoachingMembers";

//Playpass components
import PlayPassList from "./pages/PlayPass/PlayPassList";
import PlaypassDetails from "./pages/PlayPass/PlaypassDetails";
import CreatePlaypass from "./pages/PlayPass/CreatePlaypass";

//Master-Data components
import TeamsList from "./pages/TeamsList";
import UserList from "./pages/Users/UserList";
import UserDetails from "./pages/Users/UserDetails";
import CreateUser from "./pages/Users/Createuser";
import UserRewards from "./pages/Users/UserRewards";



//Rewards and Milestones
import CreateRewards from "./pages/Rewards/CreateRewards";
import RewardsList from "./pages/Rewards/RewardsList";
import CreateRewardSponsorForm from "./pages/Rewards/CreateRewardSponsor";
import CreateMilestone from "./pages/Milestones/CreateMilestones";
import EventGames from "./pages/Events/EventGames";
import EventTeams from "./pages/Events/EventTeams";
import MilestoneList from "./pages/Milestones/MilestoneList";



const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="flex flex-col md:flex-row">
          {/* Sidebar Component */}
          <Sidebar />

          <div className="flex-1 bg-gray-100 p-4 md:ml-64">
            {/* Define Routes within <Routes> */}
            <Routes>
              {/* Public Routes */}
              <Route path="/login" element={<LoginPage />} />{" "}
              {/* Login route */}
              {/* Protected Routes - wrapped with PrivateRoute to ensure authentication */}
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <DashboardHome />
                  </PrivateRoute>
                }
              />
              <Route
                path="/events"
                element={
                  <PrivateRoute>
                    <EventsList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/events/details/:id"
                element={
                  <PrivateRoute>
                    <EventDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/event/details/:eventId/registration"
                element={
                  <PrivateRoute>
                    <RegistrationDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/events/create"
                element={
                  <PrivateRoute>
                    <CreateEvent />
                  </PrivateRoute>
                }
              />
              <Route
                path="/events/details/:eventId/games"
                element={
                  <PrivateRoute>
                    <EventGames />
                  </PrivateRoute>
                }
              />
              <Route 
                path="/events/details/:eventId/teams"
                element={
                  <PrivateRoute>
                    <EventTeams />
                  </PrivateRoute>
                }
              />
              {/* Play pass listing */}
              <Route
                path="/playpass"
                element={
                  <PrivateRoute>
                    <PlayPassList />
                  </PrivateRoute>
                }
              />
              {/* Play Pass details */}
              <Route
                path="/playpass/details/:id"
                element={
                  <PrivateRoute>
                    <PlaypassDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/playpass/create"
                element={
                  <PrivateRoute>
                    <CreatePlaypass />
                  </PrivateRoute>
                }
              />
              <Route
                path="/sponsors"
                element={
                  <PrivateRoute>
                    <SponsorsList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/sponsors/create"
                element={
                  <PrivateRoute>
                    <CreateSponsor />
                  </PrivateRoute>
                }
              />
              <Route
                path="/venues"
                element={
                  <PrivateRoute>
                    <VenuesList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/venues/create"
                element={
                  <PrivateRoute>
                    <CreateVenue />
                  </PrivateRoute>
                }
              />
              <Route
                path="/venues/createSlot"
                element={
                  <PrivateRoute>
                    <CreateVenueSlot />
                  </PrivateRoute>
                }
              />
              <Route
                path="/venues/slots/:venueId"
                element={
                  <PrivateRoute>
                    <SlotDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/venues/:venueId/playareas/add"
                element={
                  <PrivateRoute>
                    <AddPlayArea />
                  </PrivateRoute>
                }
              />
              <Route path="/venues/:venueId/playareas" element={<PlayArea />} />
              <Route
                path="/region/create"
                element={
                  <PrivateRoute>
                    <CreateRegion />
                  </PrivateRoute>
                }
              />
              <Route
                path="/master/teams"
                element={
                  <PrivateRoute>
                    <TeamsList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/master/user-master"
                element={
                  <PrivateRoute>
                    <UserList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/master/user-master/:userId"
                element={
                  <PrivateRoute>
                    <UserDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/master/user-master/:userId/rewards"
                element={
                  <PrivateRoute>
                    <UserRewards />
                  </PrivateRoute>
                }
              />

              <Route
                path="/user/create"
                element={
                  <PrivateRoute>
                    <CreateUser />
                  </PrivateRoute>
                }
              />
              <Route
                path="/coaching"
                element={
                  <PrivateRoute>
                    <CoachingList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/coaching/create"
                element={
                  <PrivateRoute>
                    <CreateCoaching />
                  </PrivateRoute>
                }
              />
              <Route
                path="/coaching/create/slot"
                element={
                  <PrivateRoute>
                    <CreateCoachingAllocation />
                  </PrivateRoute>
                }
              />
              <Route
                path="/coaching/details/:id"
                element={
                  <PrivateRoute>
                    <CoachingDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/coaching/details/:coachOfferingId/registration"
                element={
                  <PrivateRoute>
                    <CoachingMembers />
                  </PrivateRoute>
                }
              />
              <Route
                path="/rewards"
                element={
                  <PrivateRoute>
                    <RewardsList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/rewards-sponsor"
                element={
                  <PrivateRoute>
                    <CreateRewardSponsorForm />
                  </PrivateRoute>
                }
              />
              <Route
                path="/rewards-sponsor/create"
                element={
                  <PrivateRoute>
                    <CreateRewardSponsorForm />
                  </PrivateRoute>
                }
              />
              <Route
                path="/rewards/create"
                element={
                  <PrivateRoute>
                    <CreateRewards />
                  </PrivateRoute>
                }
              />
              <Route
                path="/milestones"
                element={
                  <PrivateRoute>
                    <MilestoneList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/milestones/create"
                element={
                  <PrivateRoute>
                    <CreateMilestone />
                  </PrivateRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                }
              />
            </Routes>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;
