import React, { useState } from 'react'
import ToastNotification from '../../components/ToastNotification';
import { FaTimes } from 'react-icons/fa';
import { createGame } from '../../api/api';

const CreateEventGame = ({ eventData, onCancel }) => {

  const [formData, setFormData] = useState({
    OfferingId: eventData.EventId,
    OfferingType: "Event",
    SportId: eventData.sport.SportId,
    TotalRounds: "",
    StartTime: "",
    EndTime: ""
  })
  const [toast, setToast] = useState(null);

  const convertToIST = (date) => {
    // Convert to Date object if it's not already one
    const validDate = date instanceof Date ? date : new Date(date);
    if (isNaN(validDate)) {
      throw new Error("Invalid date provided to convertToIST");
    }
    const ISTOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
    const dateInIST = new Date(validDate.getTime() + ISTOffset);
    return dateInIST.toISOString();
  };


  const formatDateTimeForInput = (isoString) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleDateTimeChange = (e, dateTimeField) => {
    const { value } = e.target; // e.g., "2024-11-30"
    setFormData((prevData) => ({
      ...prevData,
      [dateTimeField]: value, // Update time field in UTC
    }));
  };

  console.log("formdata is: ", formData)

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      const payload = {
        ...formData,
        StartTime: new Date(formData.StartTime).toISOString(),
        EndTime: new Date(formData.EndTime).toISOString()

      }
      console.log('payload is: ', payload)

      await createGame(payload);
      setToast({
        message: "Game created successfully!",
        type: "success",
      });
      // setTimeout(() => {
      //   onCancel();
      //   window.location.reload();
      // }, 500);
    } catch (error) {
      console.error("Error creating event game", error);
      setToast({
        message: "Failed to create a Game",
        type: "error",
      });
    }
  }

  return (
    <div>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="relative bg-[#ffffff] p-4 md:p-6 rounded-lg shadow-lg w-11/12 max-w-3xl">
          <button
            type="button"
            onClick={onCancel}
            className="absolute top-3 right-3 text-gray-600 hover:text-red-500 text-2xl"
          >
            <FaTimes />
          </button>

          <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-center">
            Create Game
          </h2>
          <h2 className="text-xl md:text-2xl font-medium mb-4 md:mb-6 text-center">
            Event: {eventData.EventTitle}
          </h2>
          {toast && (
            <ToastNotification
              message={toast.message}
              type={toast.type}
              onClose={() => setToast(null)}
            />
          )}


          <form onSubmit={handleSubmit} className="w-full">
            <div className='mb-6'>
              <label className="block text-lg font-semibold mb-2" htmlFor="TotalRounds">Total Rounds</label>
              <div>
                <input className='w-1/2 p-3 border border-gray-300 rounded-lg'
                  type="number" name='TotalRounds' value={formData.TotalRounds} onChange={(e) => {
                    handleInputChange(e, "TotalRounds")
                  }} />
              </div>
            </div>

            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2">
                Start Time
              </label>
              <div className="flex gap-4">
                <input
                  type="datetime-local"
                  name="StartTime"
                  value={formData.StartTime}
                  min={convertToIST(new Date())}
                  onChange={(e) =>
                    handleDateTimeChange(e, "StartTime")
                  }
                  className="w-1/2 p-3 border border-gray-300 rounded-lg"
                  required
                />
              </div>
            </div>
            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2">
                End Time
              </label>
              <div className="flex gap-4">
                <input
                  type="datetime-local"
                  name="StartTime"
                  value={formData.EndTime}
                  min={convertToIST(new Date())}
                  onChange={(e) =>
                    handleDateTimeChange(e, "EndTime")
                  }
                  className="w-1/2 p-3 border border-gray-300 rounded-lg"
                  required
                />
              </div>
              {/* Submit Button */}
              <div className="text-right">
                <button
                  type="submit"
                  className="bg-blue-500 text-white p-2 rounded-lg"
                >
                 Create Game
                </button>
              </div>
            </div>
          </form>



        </div>




      </div>

    </div>
  )
}

export default CreateEventGame
