import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchRegions, createVenue, fetchSports } from "../../api/api"; // Import the API functions

const CreateVenue = () => {
  const navigate = useNavigate();

  // State for venue form data
  const [formData, setFormData] = useState({
    VenueName: "",
    VenueAddress: {
      country: "",
      state: "",
      city: "",
      pincode: "",
      landmark: "",
    },
    VenueRegionId: "", // This will be populated by the selected region
    MapLink: "",
    VenueDescription: "",
    Latitude: "",
    Longitude: "",
    PlayAreas: [],
  });

  const [regions, setRegions] = useState([]); // To store the fetched regions
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPlayAreaFields, setShowPlayAreaFields] = useState(false);
  const sportFormats = {
    Football: ["5v5", "6v6", "7v7", "8v8"],
    Padel: ["2v2"],
    Pickleball: ["1v1", "2v2"],
  };
  const [sports, setSports] = useState([]); // To store sports fetched from the API
  const [playAreaInput, setPlayAreaInput] = useState({
    PlayAreaName: "",
    SportId: "",
    SupportedFormats: [],
    Price: 0,
    PriceCredits: 0,
  });

  useEffect(() => {
    const fetchAndSetSports = async () => {
      try {
        const sportsData = await fetchSports();
        setSports(sportsData);
      } catch (error) {
        setError("Failed to fetch sports");
      }
    };

    fetchAndSetSports();
  }, []);

  // Fetch regions when the component mounts
  useEffect(() => {
    const getRegions = async () => {
      try {
        const regionsData = await fetchRegions();
        setRegions(regionsData); // Store the fetched regions in the state
      } catch (error) {
        setError("Failed to fetch regions");
        console.error("Error fetching regions:", error);
      }
    };

    getRegions();
  }, []);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const [field, subfield] = name.split(".");

    if (subfield) {
      setFormData((prev) => ({
        ...prev,
        [field]: {
          ...prev[field],
          [subfield]: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handlePlayAreaToggle = () => {
    setShowPlayAreaFields(!showPlayAreaFields);

    if(!showPlayAreaFields){
      setPlayAreaInput({
        PlayAreaName: "",
        SportId: "",
        SupportedFormats: "",
        Price: 0,
        PriceCredits: 0,
      });
    }
  };

  const handleSportChange = (e) => {
    const selectedSportId = e.target.value;
    const selectedSportName = sports.find(
      (sport) => sport.SportId === selectedSportId
    )?.SportType;

    setPlayAreaInput((prev) => ({
      ...prev,
      SportId: selectedSportId,
      SupportedFormats:
        selectedSportName === "Padel"
          ? ["2v2"]
          : selectedSportName === "Pickleball"
          ? ["1v1", "2v2"]
          : "",
    }));
  };

  const handleFormatChange = (format) => {
    setPlayAreaInput((prev) => ({
      ...prev,
      SupportedFormats: [format], // Override for Football and Pickleball
    }));
  };

  const handlePlayAreaAdd = () => {
    if (!playAreaInput.PlayAreaName || !playAreaInput.SportId) return;
    setFormData((prev) => ({
      ...prev,
      PlayAreas: [...prev.PlayAreas, playAreaInput],
    }));

    setPlayAreaInput({
      PlayAreaName: "",
      SportId: "",
      SupportedFormats: "",
      Price: 0,
      PriceCredits: 0,
    });
  };

  const handlePlayAreaRemove = (index) => {
    setFormData((prev) => ({
      ...prev,
      PlayAreas: prev.PlayAreas.filter((_, i) => i !== index),
    }));
  };

  // Handle region selection change
  const handleRegionChange = (e) => {
    const selectedRegionId = e.target.value; // Get the selected region ID
    setFormData((prevData) => ({
      ...prevData,
      VenueRegionId: selectedRegionId, // Set the venue_regionId with selected region
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // const token = localStorage.getItem("auth")
    //   ? JSON.parse(localStorage.getItem("auth")).token
    //   : null;

    setLoading(true);
    setError(null);

    const adJustedformData = {
      ...formData,
      Latitude: formData.Latitude ? Number(formData.Latitude) : null,
      Longitude: formData.Longitude ? Number(formData.Longitude) : null,
      PlayAreas: formData.PlayAreas.length > 0 ? formData.PlayAreas : null,
    };
    try {
      const createdVenue = await createVenue(adJustedformData); // Call the createVenue function
      console.log("Venue created successfully:", createdVenue);
      // console.log(adJustedformData);
      setTimeout(() => {
        navigate("/venues");
      }, 1500);
    } catch (error) {
      setError("Failed to create venue");
      console.log("Payload", adJustedformData);
      console.error("Error creating venue:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg space-y-8">
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
        Create New Venue
      </h2>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Venue Name */}
        <div className="mb-6">
          <label
            htmlFor="VenueName"
            className="block text-lg font-semibold mb-2"
          >
            Venue Name
          </label>
          <input
            type="text"
            name="VenueName"
            id="VenueName"
            value={formData.VenueName}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        {/* Venue Address */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <div className="mb-6">
            <label
              htmlFor="VenueAddress.city"
              className="block text-lg font-semibold mb-2"
            >
              City
            </label>
            <input
              type="text"
              name="VenueAddress.city"
              id="VenueAddress.city"
              value={formData.VenueAddress.city}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <div className="mb-6">
            <label
              htmlFor="VenueAddress.state"
              className="block text-lg font-semibold mb-2"
            >
              State
            </label>
            <input
              type="text"
              name="VenueAddress.state"
              id="VenueAddress.state"
              value={formData.VenueAddress.state}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <div className="mb-6">
            <label
              htmlFor="VenueAddress.country"
              className="block text-lg font-semibold mb-2"
            >
              Country
            </label>
            <input
              type="text"
              name="VenueAddress.country"
              id="VenueAddress.country"
              value={formData.VenueAddress.country}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <div className="mb-6">
            <label
              htmlFor="VenueAddress.pincode"
              className="block text-lg font-semibold mb-2"
            >
              Pincode
            </label>
            <input
              type="text"
              name="VenueAddress.pincode"
              id="VenueAddress.pincode"
              value={formData.VenueAddress.pincode}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <div className="mb-6">
            <label
              htmlFor="VenueAddress.landmark"
              className="block text-lg font-semibold mb-2"
            >
              Landmark
            </label>
            <input
              type="text"
              name="VenueAddress.landmark"
              id="VenueAddress.landmark"
              value={formData.VenueAddress.landmark}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        {/* Region Selection */}
        <div className="mb-6">
          <label
            htmlFor="VenueRegionId"
            className="block text-lg font-semibold mb-2"
          >
            Region
          </label>
          <select
            name="VenueRegionId"
            id="VenueRegionId"
            value={formData.VenueRegionId}
            onChange={handleRegionChange}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          >
            <option value="">Select a Region</option>
            {regions.map((region) => (
              <option key={region.RegionId} value={region.RegionId}>
                {region.RegionName}
              </option>
            ))}
          </select>
        </div>

        {/* Map Link */}
        <div className="mb-6">
          <label htmlFor="MapLink" className="block text-lg font-semibold mb-2">
            Map Link
          </label>
          <input
            type="url"
            name="MapLink"
            id="MapLink"
            value={formData.MapLink}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Venue Description */}
        <div className="mb-6">
          <label
            htmlFor="VenueDescription"
            className="block text-lg font-semibold mb-2"
          >
            Venue Description
          </label>
          <textarea
            name="VenueDescription"
            id="VenueDescription"
            value={formData.VenueDescription}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {/* Latitude Input */}
          <div className="mb-6">
            <label
              htmlFor="Latitude"
              className="block text-lg font-semibold mb-2"
            >
              Latitude
            </label>
            <input
              type="text"
              name="Latitude"
              id="Latitude"
              value={formData.Latitude}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              step="any" // Allows decimal values
            />
          </div>
          {/* Longitude Input */}
          <div className="mb-6">
            <label
              htmlFor="Longitude"
              className="block text-lg font-semibold mb-2"
            >
              Longitude
            </label>
            <input
              type="text"
              name="Longitude"
              id="Longitude"
              value={formData.Longitude}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              step="any" // Allows decimal values
            />
          </div>
        </div>

        {/* Toggle Play Area Section */}
        <div className="bg-gray-100 p-4 rounded-lg shadow-md flex items-center justify-between">
          <label className="text-lg font-semibold text-gray-700">
            Want to add Play Areas?
          </label>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={showPlayAreaFields}
              onChange={handlePlayAreaToggle}
              className="sr-only peer"
            />
            <div className="w-11 h-6 bg-gray-300 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-500 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-5 peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
          </label>
        </div>

        {/* Play Area Fields - Appear only when toggle is on */}
        {showPlayAreaFields && (
          <div className="bg-white p-6 rounded-lg shadow-md mt-4 space-y-6 border border-gray-200">
            <h3 className="text-xl font-semibold text-gray-800">
              Play Area Details
            </h3>

            {/* Play Area Name */}
            <div>
              <label className="block text-lg font-semibold text-gray-700 mb-2">
                Play Area Name
              </label>
              <input
                type="text"
                value={playAreaInput.PlayAreaName}
                onChange={(e) =>
                  setPlayAreaInput((prev) => ({
                    ...prev,
                    PlayAreaName: e.target.value,
                  }))
                }
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter Play Area Name"
              />
            </div>

            {/* Sport Selection */}
            <div>
              <label className="block text-lg font-semibold text-gray-700 mb-2">
                Sport
              </label>
              <select
                value={playAreaInput.SportId}
                onChange={handleSportChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select a Sport</option>
                {sports.map((sport) => (
                  <option key={sport.SportId} value={sport.SportId}>
                    {sport.SportType}
                  </option>
                ))}
              </select>
            </div>

            {/* Supported Formats - Proper UI & Selection Fix for Football */}
            {playAreaInput.SportId && (
              <div>
                <label className="block text-lg font-semibold text-gray-700 mb-2">
                  Supported Formats
                </label>
                <div className="flex flex-wrap gap-3">
                  {playAreaInput.SportId &&
                  sports.find((s) => s.SportId === playAreaInput.SportId)
                    ?.SportType === "Football" ? (
                    sportFormats.Football.map((format) => (
                      <button
                        key={format}
                        type="button"
                        onClick={() => handleFormatChange(format)}
                        className={`px-4 py-2 rounded-lg border ${
                          playAreaInput.SupportedFormats.includes(format)
                            ? "bg-blue-500 text-white border-blue-500"
                            : "bg-gray-100 text-gray-800 border-gray-300"
                        } hover:border-blue-500 hover:text-blue-500`}
                      >
                        {format}
                      </button>
                    ))
                  ) : (
                    <p className="text-gray-600">
                      {playAreaInput.SportId &&
                      sports.find((s) => s.SportId === playAreaInput.SportId)
                        ?.SportType === "Padel"
                        ? "Doubles (2v2)"
                        : "Singles (1v1) & Doubles (2v2)"}
                    </p>
                  )}
                </div>
              </div>
            )}

            {/* Price & Price Credits */}
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-lg font-semibold text-gray-700 mb-2">
                  Price (INR)
                </label>
                <input
                  type="text"
                  value={playAreaInput.Price}
                  onChange={(e) =>
                    setPlayAreaInput((prev) => ({
                      ...prev,
                      Price: Number(e.target.value),
                    }))
                  }
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter price in INR"
                />
              </div>
              <div>
                <label className="block text-lg font-semibold text-gray-700 mb-2">
                  Price Credits
                </label>
                <input
                  type="text"
                  value={playAreaInput.PriceCredits}
                  onChange={(e) =>
                    setPlayAreaInput((prev) => ({
                      ...prev,
                      PriceCredits: Number(e.target.value),
                    }))
                  }
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter price in credits"
                />
              </div>
            </div>

            {/* Add Play Area Button */}
            <button
              type="button"
              onClick={handlePlayAreaAdd}
              className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition"
            >
              Add Play Area
            </button>

            {/* Display Added Play Areas */}
            {formData.PlayAreas.length > 0 && (
              <div className="mt-4 bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200">
                <h4 className="text-lg font-semibold text-gray-700 mb-3">
                  Added Play Areas
                </h4>
                <ul className="space-y-3">
                  {formData.PlayAreas.map((area, index) => (
                    <li
                      key={index}
                      className="flex justify-between items-center bg-white p-3 rounded-lg shadow-sm border"
                    >
                      <span className="text-gray-800 font-medium">
                        {area.PlayAreaName} -{" "}
                        {
                          sports.find((s) => s.SportId === area.SportId)
                            ?.SportType
                        }{" "}
                        -{" "}
                        <span className="text-sm text-gray-500">
                          {area.SupportedFormats.join(", ")}
                        </span>
                      </span>
                      <button
                        type="button"
                        onClick={() => handlePlayAreaRemove(index)}
                        className="text-red-500 hover:underline"
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}

        {/* Submit Button */}
        <div className="mb-6">
          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-3 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={loading}
          >
            {loading ? "Creating Venue..." : "Create Venue"}
          </button>
        </div>
      </form>

      {error && <p className="text-red-500 text-center">{error}</p>}
    </div>
  );
};

export default CreateVenue;
