import React, { useState, useEffect } from "react";
import { fetchVenues, createVenueSlot, fetchSports } from "../../api/api";
import { useNavigate } from "react-router-dom";
import ToastNotification from "../../components/ToastNotification";

const CreateVenueSlot = () => {
  const navigate = useNavigate();

  const [venues, setVenues] = useState([]);
  const [selectedVenueId, setSelectedVenueId] = useState("");
  const [playAreas, setPlayAreas] = useState([]);
  const [selectedPlayAreaId, setSelectedPlayAreaId] = useState("");
  const [calculatedEndTime, setCalculatedEndTime] = useState("");
  const [formData, setFormData] = useState({
    PlayAreaId: "",
    Duration: "",
    AddPrice: "",
    AddPriceCredits: "",
    Schedule: {
      StartDate: "",
      StartTime: "",
      StartTimeStamp: "",
      EndTimeStamp: "",
      IsRecurring: false,
      RecurrenceRule: "",
      RecurrenceInterval: 0,
      RecurrenceEndDate: "",
      RecurrenceDaysOfWeek: [],
    },
  });
  const [toast, setToast] = useState(null);

  // Fetch venues
  useEffect(() => {
    const getVenues = async () => {
      try {
        const data = await fetchVenues();
        setVenues(data);
      } catch (error) {
        setToast({ message: "Failed to fetch venues.", type: "error" });
      }
    };
    getVenues();
  }, []);

  // Handle venue selection and update PlayAreas
  const handleVenueChange = (e) => {
    const venueId = e.target.value;
    setSelectedVenueId(venueId);

    const selectedVenue = venues.find((venue) => venue.VenueId === venueId);
    if (selectedVenue) {
      setPlayAreas(selectedVenue.PlayAreas || []);

      // Reset PlayAreaId ONLY if the venue changes
      if (
        !selectedPlayAreaId ||
        !selectedVenue.PlayAreas.some(
          (area) => area.PlayAreaId === selectedPlayAreaId
        )
      ) {
        setSelectedPlayAreaId("");
      }
    } else {
      setPlayAreas([]);
      setSelectedPlayAreaId("");
    }
  };

  const handlePlayAreaChange = (e) => {
    const playAreaId = e.target.value;
    setSelectedPlayAreaId(playAreaId);
  
    setFormData((prevData) => ({
      ...prevData,
      PlayAreaId: playAreaId, // Store PlayAreaId in formData
    }));
  };
  

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Calculate and set EndTime dynamically
  const calculateEndTime = () => {
    const { StartDate, StartTime } = formData.Schedule;
    const { Duration } = formData;

    if (StartDate && StartTime && Duration) {
      const startDateTime = new Date(`${StartDate}T${StartTime}`);
      const endDateTime = new Date(startDateTime.getTime() + Duration * 60000);

      setFormData((prevData) => ({
        ...prevData,
        Schedule: {
          ...prevData.Schedule,
          StartTimeStamp: `${StartDate}T${StartTime}`, // Append 'Z' for ISO-like format
          EndTimeStamp: `${endDateTime.toISOString().split("T")[0]}T${
            endDateTime.toTimeString().split(" ")[0]
          }`, // Generate EndTimeStamp in similar format
        },
      }));

      setCalculatedEndTime(
        endDateTime.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
    } else {
      setCalculatedEndTime(""); // Reset if inputs are incomplete
    }
  };

  // Handle schedule input changes
  const handleScheduleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      Schedule: {
        ...prevData.Schedule,
        [name]: value,
      },
    }));
  };

  // Trigger EndTime calculation whenever relevant fields change
  useEffect(() => {
    calculateEndTime();
  }, [
    formData.Schedule.StartDate,
    formData.Schedule.StartTime,
    formData.Duration,
  ]);

  // Recurrence Handlers
  const handleIsRecurringChange = (e) => {
    const isRecurring = e.target.checked;

    setFormData((prevData) => ({
      ...prevData,
      Schedule: {
        ...prevData.Schedule,
        IsRecurring: isRecurring,
        ...(isRecurring
          ? {}
          : {
              RecurrenceRule: "",
              RecurrenceInterval: 0,
              RecurrenceEndDate: "",
              RecurrenceDaysOfWeek: [],
            }),
      },
    }));
  };

  const handleRecurrenceRuleChange = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      Schedule: {
        ...prev.Schedule,
        RecurrenceRule: value,
      },
    }));
  };

  const handleRecurrenceIntervalChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      Schedule: {
        ...prev.Schedule,
        RecurrenceInterval: Number(e.target.value),
      },
    }));
  };

  const handleRecurrenceEndDateChange = (e) => {
    const dateValue = e.target.value;
    const currentTime = formData.Schedule.RecurrenceEndDate
      ? formData.Schedule.RecurrenceEndDate.slice(11, 16)
      : "00:00";

    const newISODateTime = `${dateValue}T${currentTime}`;
    setFormData((prevData) => ({
      ...prevData,
      Schedule: {
        ...prevData.Schedule,
        RecurrenceEndDate: newISODateTime,
      },
    }));
  };

  const handleRecurrenceDaysChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      Schedule: {
        ...prev.Schedule,
        RecurrenceDaysOfWeek: checked
          ? [...prev.Schedule.RecurrenceDaysOfWeek, value]
          : prev.Schedule.RecurrenceDaysOfWeek.filter((day) => day !== value),
      },
    }));
  };
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    let recurrenceEndDateTime = formData.Schedule.RecurrenceEndDate;
    if (formData.Schedule.RecurrenceEndDate && formData.Schedule.EndTimeStamp) {
      const recurrenceDate = formData.Schedule.RecurrenceEndDate.split("T")[0]; // Get date from RecurrenceEndDate
      const endTime = formData.Schedule.EndTimeStamp.split("T")[1].replace(
        "Z",
        ""
      ); // Get time from EndTimeStamp
      recurrenceEndDateTime = `${recurrenceDate}T${endTime}`; // Combine date and time
      console.log("recurrenceEndDateTime = ", recurrenceEndDateTime)
    }

    console.log("start time stamp before: ", formData.Schedule.StartTimeStamp);
    console.log("end time stamp before: ", formData.Schedule.EndTimeStamp);
    const payload = {
      VenueId: selectedVenueId,
      PlayAreaId: formData.PlayAreaId,
      Duration: Number(formData.Duration),
      AddPrice: Number(formData.AddPrice),
      AddPriceCredits: Number(formData.AddPriceCredits),
      Schedule: {
        StartTimeStamp: new Date(
          formData.Schedule.StartTimeStamp
        ).toISOString(),
        EndTimeStamp: new Date(formData.Schedule.EndTimeStamp).toISOString(),
        IsRecurring: formData.Schedule.IsRecurring,
        RecurrenceRule: formData.Schedule.IsRecurring
          ? formData.Schedule.RecurrenceRule
          : null,
        RecurrenceInterval: formData.Schedule.IsRecurring
          ? formData.Schedule.RecurrenceInterval
          : null,
        RecurrenceEndDate: formData.Schedule.IsRecurring
          ? new Date(recurrenceEndDateTime).toISOString()
          : null,
        RecurrenceDaysOfWeek: formData.Schedule.IsRecurring
          ? formData.Schedule.RecurrenceDaysOfWeek
          : [],
      },
    };
    console.log("start time stamp after: ", payload.Schedule.StartTimeStamp);
    console.log("end time stamp after: ", payload.Schedule.EndTimeStamp);
    console.log("recurrence end date time stamp after: ", payload.Schedule.RecurrenceEndDate);
    try {
      await createVenueSlot(selectedVenueId, payload);
      setToast({
        message: "Venue slot created successfully!",
        type: "success",
      });
      navigate("/venues");
    } catch (error) {
      console.error("Payload to be sent", payload);
      setToast({ message: "Failed to create venue slot.", type: "error" });
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Create Venue Slots</h2>

      {toast && (
        <ToastNotification
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      <form onSubmit={handleSubmit}>
        {/* Venue Dropdown */}
        <div className="mb-4">
          <label className="block text-lg font-semibold mb-2">
            Select Venue
          </label>
          <select
            value={selectedVenueId}
            onChange={handleVenueChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          >
            <option value="">Select a Venue</option>
            {venues.map((venue) => (
              <option key={venue.VenueId} value={venue.VenueId}>
                {venue.VenueName}
              </option>
            ))}
          </select>
        </div>

        {/* Play Area Dropdown */}
        <div className="mb-4">
          <label className="block text-lg font-semibold mb-2">
            Select Play Area
          </label>
          <select
            value={selectedPlayAreaId}
            onChange={handlePlayAreaChange} 
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          >
            <option value="">Select a Play Area</option> {/* Empty option */}
            {playAreas.length > 0 ? (
              playAreas.map((playArea) => (
                <option key={playArea.PlayAreaId} value={playArea.PlayAreaId}>
                  {`${playArea.PlayAreaName} - ${playArea.SupportedFormats.join(
                    ", "
                  )}`}
                </option>
              ))
            ) : (
              <option disabled>No Play Areas available</option>
            )}
          </select>
        </div>

        {/* Add Duration */}
        <div className="mb-4">
          <label className="block text-lg font-semibold mb-2">
            Duration(in minutes)
          </label>
          <select
            name="Duration"
            value={formData.Duration}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          >
            <option value="">Select a Duration</option>
            <option value="30">30 Minutes</option>
            <option value="60">1 Hour</option>
            <option value="90">1 Hour 30 Minutes</option>
            <option value="120">2 Hours</option>
          </select>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
          {/* Price */}
          <div className="mb-4">
            <label className="block text-lg font-semibold mb-2">Price</label>
            <input
              type="text"
              name="AddPrice"
              value={formData.AddPrice}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              required
            />
          </div>
          {/* PriceCredits */}
          <div className="mb-4">
            <label className="block text-lg font-semibold mb-2">
              Price Credits
            </label>
            <input
              type="text"
              name="AddPriceCredits"
              value={formData.AddPriceCredits}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
          {/* Schedule Start Date */}
          <div>
            <label className="block text-lg font-semibold mb-2">
              Schedule Start Date
            </label>
            <input
              type="date"
              name="StartDate"
              value={formData.Schedule.StartDate}
              onChange={handleScheduleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              required
            />
          </div>

          {/* Schedule Start Time */}
          <div>
            <label className="block text-lg font-semibold mb-2">
              Schedule Start Time
            </label>
            <input
              type="time"
              name="StartTime"
              value={formData.Schedule.StartTime}
              onChange={handleScheduleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              required
            />
          </div>
        </div>

        {/* Display Calculated End Time */}
        <div className="mb-4">
          <label className="block text-lg font-semibold mb-2">
            Calculated End Time
          </label>
          <p className="text-gray-600 p-3 border border-gray-300 rounded-lg">
            {calculatedEndTime || "End time will be calculated automatically"}
          </p>
        </div>

        {/* Toggle Recurrence */}
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={formData.Schedule.IsRecurring}
              onChange={handleIsRecurringChange}
              className="mr-2"
            />
            Recurring Slot
          </label>
        </div>

        {/* Recurrence Options */}
        {formData.Schedule.IsRecurring && (
          <>
            <div className="mb-4">
              <label className="block font-semibold mb-2">
                Recurrence Rule
              </label>
              <select
                value={formData.Schedule.RecurrenceRule}
                onChange={handleRecurrenceRuleChange}
                className="w-full p-3 border rounded"
              >
                <option value="">Select Rule</option>
                <option value="DAILY">Daily</option>
                <option value="WEEKLY">Weekly</option>
                <option value="MONTHLY">Monthly</option>
              </select>
            </div>

            <div className="mb-4">
              <label className="block font-semibold mb-2">
                Recurrence Interval
              </label>
              <input
                type="number"
                value={formData.Schedule.RecurrenceInterval}
                onChange={handleRecurrenceIntervalChange}
                className="w-full p-3 border rounded"
                min="1"
              />
            </div>

            <div className="mb-4">
              <label className="block font-semibold mb-2">
                Recurrence End Date
              </label>
              <input
                type="date"
                value={formData.Schedule.RecurrenceEndDate.slice(0, 10)}
                onChange={handleRecurrenceEndDateChange}
                className="w-full p-3 border rounded"
              />
            </div>

            {/* Recurrence Days of Week - Only for Weekly */}
            {formData.Schedule.RecurrenceRule === "WEEKLY" && (
              <div className="mb-4">
                <label className="block font-semibold mb-2">
                  Recurrence Days of the Week
                </label>
                {[
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday",
                ].map((day) => (
                  <label key={day} className="inline-flex items-center mr-4">
                    <input
                      type="checkbox"
                      value={day}
                      checked={formData.Schedule.RecurrenceDaysOfWeek.includes(
                        day
                      )}
                      onChange={handleRecurrenceDaysChange}
                      className="mr-2"
                    />
                    {day}
                  </label>
                ))}
              </div>
            )}
          </>
        )}

        {/* Submit Button */}
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
        >
          Create Slot
        </button>
      </form>
    </div>
  );
};

export default CreateVenueSlot;
