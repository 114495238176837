import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchVenues } from "../../api/api";
import Loader from "../../components/Loader";

const PlayArea = () => {
  const { venueId } = useParams();
  const [playAreas, setPlayAreas] = useState([]);
  const [venueName, setVenueName] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getVenueDetails = async () => {
      try {
        const venues = await fetchVenues();
        const venue = venues.find((v) => v.VenueId === venueId);

        if (venue) {
          setPlayAreas(venue.PlayAreas || []);
          setVenueName(venue.VenueName || "Unknown Venue");
        } else {
          setError("Venue not found.");
        }
      } catch (err) {
        console.error("Error fetching venues:", err);
        setError("Failed to load play areas. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    getVenueDetails();
  }, [venueId]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <div className="text-center p-6">
        <p className="text-red-500 font-semibold">{error}</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6 bg-gray-50 rounded-lg shadow-lg space-y-8">
      <h2 className="text-3xl font-bold text-center text-gray-800">
        Play Areas for {venueName}
      </h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {playAreas.map((playArea) => (
          <div
            key={playArea.PlayAreaId}
            className="bg-white p-6 rounded-lg shadow-md transition hover:shadow-lg"
          >
            <h3 className="text-xl font-semibold text-gray-700 mb-2">
              {playArea.PlayAreaName}
            </h3>
            <p className="text-gray-600 text-sm mb-4">
              <strong>Supported Formats:</strong>{" "}
              {playArea.SupportedFormats.join(", ")}
            </p>
            <p className="text-gray-600 text-sm mb-2">
              <strong>Sport:</strong> {playArea.SportId}
            </p>
            <p className="text-gray-600 text-sm mb-2">
              <strong>Price:</strong> ₹{playArea.Price}
            </p>
            <p className="text-gray-600 text-sm mb-2">
              <strong>Price Credits:</strong> {playArea.PriceCredits}
            </p>
          </div>
        ))}
      </div>

      {playAreas.length === 0 && (
        <p className="text-gray-500 text-center">No play areas found.</p>
      )}
    </div>
  );
};

export default PlayArea;
