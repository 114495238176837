import React, { useEffect, useState } from 'react'
import { useAuthContext } from '../../AuthContext';
import { Link } from 'react-router-dom';
import { FaPlus, FaTrashAlt, FaFilter, FaTimes, FaDownload } from "react-icons/fa";
import { fetchPlaypassGames, deletePlayPass, fetchUserDetails, exportPlaypass } from '../../api/api';
import Loader from '../../components/Loader';


const PlayPassList = () => {

    const { UserId } = useAuthContext();
    console.log("user id is: ", UserId)
    const [playPassData, setPlayPassData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filteredPlayPass, setFilteredPlayPass] = useState([])
    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const [sortOption, setSortOption] = useState(""); // Default no sort option
    const [showDropdown, setShowDropdown] = useState(false); // For controlling dropdown visibility
    const [organizerInfo, setOrganizerInfo] = useState(null)
    const [filteredOrganizerPlaypass, setFilteredOrganizerPlaypass] = useState([])

    useEffect(() => {
        setLoading(true)
        const fetchPlayPassData = async () => {
            try {
                const playPassResponse = await fetchPlaypassGames()
                console.log(playPassResponse.games)
                setPlayPassData(playPassResponse.games);
            } catch (error) {
                setError(error);

            } finally {
                setLoading(false)
            }
        }
        fetchPlayPassData();
    }, [])





    useEffect(() => {

        let updatedPlayPass = [...playPassData]

        // Apply search query filtering
        if (searchQuery) {
            updatedPlayPass = updatedPlayPass.filter((playPassData) =>
                playPassData.PlayPassTitle?.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        setFilteredPlayPass(updatedPlayPass)

    }, [searchQuery, playPassData])

    // Handle event deletion
    const handleDeletePlayPass = async (UserId, playpassId) => {
        if (window.confirm("Are you sure you want to delete this event?")) {
            try {
                // const token = localStorage.getItem("auth")
                //   ? JSON.parse(localStorage.getItem("auth")).token
                //   : null;

                // if (!token) {
                //   alert("Access token is missing. Please log in.");
                //   return;
                // }
                console.log("function plp id", playpassId   )

                await deletePlayPass(UserId, playpassId); // Pass the token to the deleteEvent API
                setPlayPassData((prevEvents) =>
                    prevEvents.filter((playpass) => playpass.PlayPassId !== playpassId)
                );
                alert("Playpass deleted successfully!");
            } catch (error) {
                console.error("Error deleting Playpass:", error);
                alert("Failed to delete Playpass. Please try again.");
            }
        }
    };

    const handleExport = async () => {
        try {
            const exportResponse = await exportPlaypass();
            // Create a URL for the Blob and trigger the download
            const url = window.URL.createObjectURL(new Blob([exportResponse]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `playpass_details.csv`); // Set the desired file name
            document.body.appendChild(link);
            link.click();
            link.remove(); // Clean up the link element
        } catch (error) {
            console.error("Error in exporting playpass details: ", error)
        }
    }

    const filterOrganizer = () =>{

    }

    if (loading) {
        return <Loader />
    }


    return (
        <div className='bg-gray-100 min-h-screen p-6'>
            <div className="flex justify-between items-center mb-6">
                {/* Heading */}
                <h1 className="text-3xl font-bold sm:text-4xl text-gray-800">
                    All Playpass Games
                </h1>

                <div className='flex items-center justify-center gap-x-4'>
                    <button
                        onClick={handleExport}
                        className='bg-gradient-to-r from-purple-500 to-indigo-600 text-white p-3 rounded-lg shadow-lg hover:bg-indigo-700 flex items-center justify-center sm:w-auto w-12 h-12 gap-x-2'>
                        <p> Export Details</p> <FaDownload size={14} />
                    </button>

                    {/* Create PlayPass Button */}
                    <Link
                        to="/playpass/create"
                        className="bg-gradient-to-r from-purple-500 to-indigo-600 text-white p-3 rounded-lg shadow-lg hover:bg-indigo-700 flex items-center justify-center sm:w-auto w-12 h-12"
                    >
                        <FaPlus size={20} className="sm:hidden" />
                        <span className="hidden sm:inline-block ml-2 text-base sm:text-lg">
                            Create Playpass Game
                        </span>
                    </Link>



                </div>


            </div>

            {/* Search and Sort Controls */}
            <div className="mb-6 flex justify-between items-center">

                {/* Search Box */}
                <input
                    type="text"
                    placeholder="Search PlayPasses"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full sm:w-1/2 md:w-1/3 px-4 py-2 border border-gray-300 rounded-lg shadow-md focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                />


                {/* Dropdown Button with Cross Icon */}
                <div className="relative flex items-center">
                    <button
                        onClick={() => setShowDropdown(!showDropdown)}
                        className={`px-4 py-2 rounded-lg shadow-md focus:ring-2 focus:ring-indigo-500 focus:outline-none flex items-center gap-2 ${sortOption
                            ? "bg-indigo-500 text-white hover:bg-indigo-600"
                            : "bg-gray-200 text-gray-600 hover:bg-gray-300"
                            }`}
                    >
                        <FaFilter />
                        <span className="hidden sm:inline">Filter/Sort</span>
                    </button>

                    {/* Cross Icon to Remove Filter */}
                    {sortOption && (
                        <button
                            onClick={() => setSortOption("")}
                            className="ml-2 bg-red-100 text-red-500 hover:bg-red-200 rounded-full p-2 shadow transition duration-200"
                            title="Remove Filter"
                        >
                            <FaTimes />
                        </button>
                    )}

                    {showDropdown && (
                        <div className="absolute right-0 mt-2 w-56 bg-white rounded-lg shadow-lg z-10">
                            <ul className="py-2">
                                <li
                                    onClick={() => {
                                        setSortOption("Vinayak Ghosh");
                                        setShowDropdown(false);
                                        filterOrganizer();
                                    }}
                                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${sortOption === "Vinayak Ghosh" ? "font-semibold" : ""
                                        }`}
                                >
                                    Host (Net Gala)
                                </li>
                                <li
                                    onClick={() => {
                                        setSortOption("startdate");
                                        setShowDropdown(false);
                                    }}
                                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${sortOption === "startdate" ? "font-semibold" : ""
                                        }`}
                                >
                                    Games With Score
                                </li>
                                <li
                                    onClick={() => {
                                        setSortOption("startdate");
                                        setShowDropdown(false);
                                    }}
                                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${sortOption === "startdate" ? "font-semibold" : ""
                                        }`}
                                >
                                    Games Without Score
                                </li>
                            </ul>
                        </div>
                    )}
                </div>

            </div>

            {error ? (
                <div className='text-center'>
                    <h1>No Playpass games found</h1>
                </div>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                    {filteredPlayPass ? (
                        filteredPlayPass.map((playpass) => (
                            <div
                                key={playpass.PlayPassId}
                                className="event-card p-4 bg-white shadow-lg rounded-lg hover:shadow-2xl transition duration-200 relative"
                            >
                                <h3 className="text-xl font-semibold text-gray-800 mb-3">
                                    {playpass.PlayPassTitle}
                                </h3>
                                <p className="text-gray-600 text-[0.9rem] mb-3">
                                   <span className='font-medium' >Organizer:</span> {playpass.OrganizerName}
                                </p>
                                <p className="text-gray-600 text-sm">
                                    {playpass.Description.length > 70
                                        ? `${playpass.Description.slice(0, 70)}...`
                                        : playpass.Description}
                                </p>
                                <p className='text-sm mt-4'>
                                    Created on : {new Date(playpass.CreatedAt).toLocaleTimeString(
                                        "en-IN", {
                                        day: "2-digit",
                                        month: "short",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true
                                    }
                                    )}
                                </p>

                                <Link
                                    to={`/playpass/details/${playpass.PlayPassId}`}
                                    className="text-indigo-500 hover:text-indigo-600 mt-4 inline-block font-medium"
                                >
                                    View Details
                                </Link>
                                <button
                                    onClick={() => handleDeletePlayPass(UserId, playpass.PlayPassId)}
                                    className="absolute top-2 right-2 bg-red-100 text-red-500 hover:bg-red-200 rounded-full p-2 shadow transition duration-200"
                                    title="Delete Event"
                                >
                                    <FaTrashAlt size={16} />
                                </button>
                            </div>
                        ))
                    ) : (
                        <p className="text-gray-700 text-center col-span-3">
                            No events found matching the search query
                        </p>
                    )}
                </div>
            )}





        </div>
    )
}

export default PlayPassList
