import React from 'react'

function formatDate(string) {
    var options = { 
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short'
    };
    return new Date(string).toLocaleTimeString([], options);
}

const TxnList = ({ txns }) => {

    console.log("Props received: ", txns);

    return (

        <div
            style={{
                width: '100%',
                borderRadius: 12,
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    fontFamily: 'Monrope-Medium',
                    fontSize: 30,
                    color: '#38382D',
                    // marginLeft: '5%'
                }}>All Transactions</div>


            {/* List of Transactions */}
            <div
                style={{
                    // width: '90%',
                    // marginLeft: '5%',
                    // backgroundColor: '#ff0000'
                    paddingTop: '2%',
                    paddingBottom: '2%'
                }}
            >


                {txns && txns.length > 0 && txns.map((item) => {
                    console.log("Item: ", item);
                    if (!item) {
                        return null
                    }

                    let isSuccess = item.OrderStatus === true && item.SubOrderStatus == true;

                    return (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: '2%',
                            // padding: "20px",
                            border: '1px solid #eeeeee',
                            justifyContent: 'space-between'
                        }}>
                            <div style={{
                                width: '50%',
                                padding: '20px'
                            }}>
                                <div style={{
                                    fontFamily: 'Manrope-Medium',
                                    color: '#512D9A',
                                    fontSize: 16
                                }}>
                                    {`${item.OfferingType} : ${item.OfferingTitle}`}
                                </div>
                                <div style={{
                                    fontFamily: 'Manrope-Medium',
                                    color: '#5E5E5E',
                                    fontSize: 12
                                }}>
                                    {`Sub Order Id : ${item.SuborderId}`}
                                </div>
                                <div style={{
                                    fontFamily: 'Manrope-Medium',
                                    color: '#5E5E5E',
                                    fontSize: 12
                                }}>
                                    {`Order Date : ${formatDate(item.OrderDate)}`}
                                </div>
                                <div style={{
                                    fontFamily: 'Manrope-Medium',
                                    color: '#5E5E5E',
                                    fontSize: 12
                                }}>
                                    {`Payment Date : ${formatDate(item.PaymentTimeStamp)}`}
                                </div>

                                <span
                                    style={{
                                        fontFamily: 'Manrope-Medium',
                                        color: '#5E5E5E',
                                        fontSize: 12
                                    }}
                                >Order Status: </span>
                                <span style={{
                                    fontFamily: 'Manrope-Medium',
                                    fontWeight: 'bold',
                                    color: item.OrderStatus === 'Success' ? '#3F7D58' : '#FF0000',
                                    fontSize: 12
                                }}>
                                    {`${item.OrderStatus}`}
                                </span>
                                <br />
                                <span
                                    style={{
                                        fontFamily: 'Manrope-Medium',
                                        color: '#5E5E5E',
                                        fontSize: 12
                                    }}
                                >Sub Order Status: </span>
                                <span style={{
                                    fontFamily: 'Manrope-Medium',
                                    fontWeight: 'bold',
                                    color: item.SubOrderStatus === 'Success' ? '#3F7D58' : '#FF0000',
                                    fontSize: 12
                                }}>
                                    {`${item.SubOrderStatus}`}
                                </span>
                            </div>

                            <div style={{
                                fontFamily: 'Manrope-Medium',
                                fontSize: 20,
                                alignSelf: 'center',
                                textAlign: 'center',
                                fontWeight: 'bold',
                                color: item.SubOrderStatus === 'Success' ? '#3F7D58' : '#FF0000',
                                width: '50%',
                            }}>
                                {item.SubOrderAmount + " " + item.PaymentMode}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default TxnList;