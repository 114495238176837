import React, { useState, useEffect } from "react";
import {
  fetchPlaypassById, fetchUserDetails, getTeams, updatePlaypass, fetchVenues,
  fetchSponsors,
  fetchSports,
  fetchAllUsers,
  uploadEventImage,
  downloadFile,
  fetchVenueSlots
} from "../api/api";
import Select from 'react-select'
import CropperEventImage from "../components/CropperEventImage";
import ToastNotification from "../components/ToastNotification";
import Loader from "../components/Loader";
import { FaTimes } from "react-icons/fa";
import imageCompression from "browser-image-compression";
import ClipLoader from "react-spinners/ClipLoader";


const EditPlaypassForm = ({ playpass, onCancel }) => {

  const [formData, setFormData] = useState({
    OrganizerId: playpass?.OrganizerId,
    PlayPassTitle: playpass?.PlayPassTitle || "",
    Description: playpass?.Description || "",
    PlayPassType: playpass?.PlayPassType || "", // Default value
    MatchType: playpass?.MatchType || '',      // Default value
    // TotalRounds: playpass?.TotalRounds || '',
    // EventImageFileId: [],
    PlayPassImages: playpass?.PlayPassImages || null,// Array of strings for uploaded image IDs
    // Array of strings for uploaded image IDs
    // MatchFormat: playpass?.MatchFormat || '',        // Default value
    SkillLevel: playpass?.SkillLevel || [],  // Default value
    PlayPassAccess: playpass?.PlayPassAccess || "",   // Default value
    InviteList: playpass?.InviteList || null,      // Default placeholder
    TeamInviteList: playpass?.TeamInviteList || null,  // Default placeholder
    SportId: playpass?.Sport?.SportId || "",
    VenueAllocationId: playpass?.VenueAllocations[0]?.VenueAllocationId || "",
    FAQ: playpass?.FAQ || [{ question: "", answer: "", },],
  });

  const [venues, setVenues] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState("");
  const [venueSlots, setVenueSlots] = useState({});
  const [sports, setSports] = useState([]);
  const [toast, setToast] = useState(null);
  const [inviteOption, setInviteOption] = useState([])
  const [teamOption, setTeamOption] = useState([]);
  const [loading, setLoading] = useState(true);

  //for image
  const [showCropper, setShowCropper] = useState(false);
  const [usedCropOptions, setUsedCropOptions] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  //for slot selection
  const [selectedDay, setSelectedDay] = useState(""); // To store selected day
  const [availableDays, setAvailableDays] = useState([]); // To store unique days
  const [availableTimes, setAvailableTimes] = useState([]); // To store available slots for selected day
  const [selectedTime, setSelectedTime] = useState(""); // Track the selected time
  const [updateVenueOption, setUpdateVenueOption] = useState(false)
  //for checking sport selection
  const [selectedSportId, setSelectedSportId] = useState(formData.SportId)

  //fetching users
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const fetchAllUserData = await fetchAllUsers();

        // Map the API data to the format required by react-select
        const formattedOptions = fetchAllUserData.map((user) => ({
          value: user.UserId, // User ID will be stored in 'value'
          label: `${user.FirstName} ${user.LastName}`, // Full name will be stored in 'label'
        }));
        setInviteOption(formattedOptions)

      } catch (error) {
        console.error("Error fetching user data: ", error)
      }
    }
    setLoading(false)
    fetchData()
  }, [])

  //fetching teams
  useState(() => {
    setLoading(true)
    const fetchData = async () => {
      try {
        const fetchTeamData = await getTeams();
        // Map the API data to the format required by react-select
        const formattedOptions = fetchTeamData.map((team) => ({
          value: team.TeamId, // User ID will be stored in 'value'
          label: `${team.TeamName}`, // Full name will be stored in 'label'
        }));
        setTeamOption(formattedOptions)

      } catch (error) {
        console.error("Error fetching team data", error)
      }
    }
    setLoading(false)
    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const fetchedSports = await fetchSports();
        setSports(fetchedSports);
      } catch (error) {
        console.error("Error fetching details:", error);
      } finally {
        setLoading(false)
      }

    }
    fetchData();

  }, [])

  useState(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const fetchedVenues = await fetchVenues();
        setVenues(fetchedVenues)
      } catch (error) {
        console.error("Error fetching venues: ", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData()
  }, [])



  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };




  //handle faq
  const handleFAQChange = (index, e) => {
    const { name, value } = e.target;
    const newFaqs = [...formData.FAQ];
    newFaqs[index] = { ...newFaqs[index], [name]: value };
    setFormData((prevData) => ({
      ...prevData,
      FAQ: newFaqs,
    }));
  };

  const handleMediaChange = (index, e) => {
    const { name, value } = e.target;
    const newMedia = [...formData.PlayPassImages];
    newMedia[index] = { ...newMedia[index], [name]: value };
    setFormData((prevData) => ({
      ...prevData,
      PlayPassImages: newMedia
    }))
  }
  const addMedia = () => {
    setFormData((prevData) => ({
      ...prevData,
      PlayPassImages: [...(prevData.PlayPassImages || []), { Url: "", MimeType: "", Description: "" }]
    }))
  }

  const removeMedia = (indexToRemove) => {
    setFormData((prevData) => {
      const updateMedia = prevData.PlayPassImages.filter((_, index) => index !== indexToRemove);
      return {
        ...prevData,
        PlayPassImages: updateMedia?.length > 0 ? updateMedia : null,
      }
    })
  }

  const addFAQ = () => {
    setFormData((prevData) => ({
      ...prevData,
      FAQ: [...prevData.FAQ, { question: "", answer: "" }],
    }));
  };

  // Remove FAQ
  const removeFAQ = (indexToRemove) => {
    setFormData((prevData) => {
      const updatedFAQ = prevData.FAQ.filter((_, index) => index !== indexToRemove);
      return {
        ...prevData,
        FAQ: updatedFAQ?.length > 0 ? updatedFAQ : null, // Set FAQ to null if it's empty
      };
    });
  };

  //for handling skill
  const addSkillLevel = (e) => {
    const { value, checked } = e.target;

    setFormData((prevData) => {
      const updatedSkillLevels = checked
        ? [...prevData.SkillLevel, value] // Add value if checked
        : prevData.SkillLevel.filter((skill) => skill !== value); // Remove value if unchecked

      return { ...prevData, SkillLevel: updatedSkillLevels };
    });
  }

  //for handling  invite
  const handleInviteChange = (index, selectedOption) => {

    const UserId = selectedOption.value
    setFormData((prevFormData) => {
      const updatedInviteList = [...prevFormData.InviteList];
      console.log("first", updatedInviteList)
      updatedInviteList[index] = UserId // Update the specific team invite at the given index
      console.log("second", updatedInviteList)

      // Check if all values in the InviteList are empty, and set it to null if true
      const isAllEmpty = updatedInviteList.every((item) => item === "");

      return {
        ...prevFormData,
        InviteList: isAllEmpty ? null : updatedInviteList,
      };
    });
  };

  const addInvite = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      InviteList: prevFormData.InviteList ? [...prevFormData.InviteList, ""] : [""], // Add a new empty string
    }));
  };
  const removeInvite = (indexToRemove) => {
    setFormData((prevFormData) => {
      // Filter out the invite at the specified index
      const updatedInviteList = prevFormData.InviteList
        ? prevFormData.InviteList.filter((_, index) => index !== indexToRemove)
        : [];

      // Check if the updatedInviteList is empty, set it to null if true
      const isAllEmpty = updatedInviteList.length === 0;

      return {
        ...prevFormData,
        InviteList: isAllEmpty ? null : updatedInviteList,
      };
    });
  };




  //for handling team invite
  const handleTeamInviteChange = (index, selectedOption) => {

    const teamId = selectedOption.value
    setFormData((prevFormData) => {
      const updatedTeamInviteList = [...prevFormData.TeamInviteList];
      updatedTeamInviteList[index] = teamId.trim(); // Update the 
      const isAllEmpty = updatedTeamInviteList.every((item) => item === "");

      return {
        ...prevFormData,
        TeamInviteList: isAllEmpty ? null : updatedTeamInviteList,
      };
    });
  };

  const addTeamInvite = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      TeamInviteList: prevFormData.TeamInviteList ? [...prevFormData.TeamInviteList, ""] : [""],
    }));
  };

  const removeTeamInvite = (indexToRemove) => {
    setFormData((prevFormData) => {
      const updatedTeamInviteList = prevFormData.TeamInviteList
        ? prevFormData.TeamInviteList.filter((_, index) => index !== indexToRemove)
        : [];

      const isAllEmpty = updatedTeamInviteList.length === 0
      return {
        ...prevFormData,
        TeamInviteList: isAllEmpty ? null : updatedTeamInviteList,
      }

    })
  }


  // For match format
  const matchFormatOptions = {
    "Pickleball": [
      { value: 1, label: 'Singles' },
      { value: 2, label: 'Doubles' },
    ],
    "Football": [
      { value: 5, label: '5V5' },
      { value: 6, label: '6V6' },
      { value: 7, label: '7V7' },
      { value: 8, label: '8V8' },
    ],
    "Padel": [
      { value: 2, label: 'Doubles' },
    ],
  };


  const selectedSportID = formData.SportId;

  // Find the SportType
  const selectedSport = sports.find(
    (sport) => sport.SportId === selectedSportID
  );

  let sportMatchFormats
  if (selectedSport) {
    sportMatchFormats = matchFormatOptions[selectedSport.SportType]

  }


  // Handle Venue Change and Slot
  const handleVenueInputChange = async (e) => {
    const venueId = e.target.value; // Selected venue ID
    setSelectedVenue(venueId); // Set the selected venue
    setAvailableDays([]); // Reset available days
    setAvailableTimes([]); // Reset available times
    setSelectedDay(""); // Reset selected day
    setSelectedTime("");
    setFormData((prevFormData) => ({
      ...prevFormData,
      VenueAllocationId: [""], // Clear VenueAllocationId in formData
    }));

    try {
      // Fetch venue slots from the API
      const fetchedVenueSlots = await fetchVenueSlots(venueId);
      console.log("Fetched venue data:", fetchedVenueSlots);

      // Ensure VenueAllocation exists in the response
      if (fetchedVenueSlots && fetchedVenueSlots.VenueAllocation) {
        // Filter VenueAllocation based on the selectedSportId
        const filteredSlots = fetchedVenueSlots.VenueAllocation.filter(
          (slot) => slot.SportId === selectedSportId // Compare with selectedSportId
        );

        if (filteredSlots?.length > 0) {
          setVenueSlots(filteredSlots); // Update venueSlots with filtered data

          // Extract unique days for dates greater than today's date
          const today = new Date().setHours(0, 0, 0, 0); // Set time to midnight for comparison
          const uniqueDays = [
            ...new Set(
              filteredSlots
                .map((alloc) => new Date(alloc.Slot)) // Convert to Date object
                .filter((slotDate) => slotDate > today) // Filter dates greater than today
                .map((validDate) => validDate.toISOString().split("T")[0]) // Format to YYYY-MM-DD
            ),
          ];
          setAvailableDays(uniqueDays); // Update available days
        } else {
          console.warn("No slots available for the selected sport.");
          setVenueSlots([]);
          setAvailableDays([]);
        }
      } else {
        console.warn("No VenueAllocation data found");
        setVenueSlots([]);
        setAvailableDays([]);
      }
    } catch (error) {
      console.error("Error fetching venue slot data:", error);
      setVenueSlots([]);
      setAvailableDays([]);
    }
  };

  // Function to handle day selection
  const handleDayChange = (e) => {
    const selectedDate = e.target.value; // Get the selected date
    setSelectedDay(selectedDate); // Update the selected day
    //reset selected time

    // Clear available times and VenueAllocationId
    setAvailableTimes([]);
    setFormData((prevFormData) => ({
      ...prevFormData,
      VenueAllocationId: [""], // Clear VenueAllocationId
    }));

    console.log("venue slots", venueSlots)
    // Filter times for the selected day
    const timesForDay = venueSlots
      .filter((alloc) => alloc.Slot.split("T")[0] === selectedDate) // Directly compare the date part of the Slot
      .map((alloc) => {
        const [hour, minute] = alloc.Slot.slice(11, 16).split(":").map(Number); // Extract hour and minute from ISO string
        const isPM = hour >= 12;
        const formattedHour = hour % 12 || 12; // Convert to 12-hour format (12 AM/PM edge case)
        const formattedTime = `${formattedHour}:${minute.toString().padStart(2, "0")} ${isPM ? "PM" : "AM"}`;
        return {
          time: formattedTime,
          id: alloc.VenueAllocationId,
          PlayArea: alloc.PlayArea.PlayAreaName
        }

      });


    console.log("time of the day", timesForDay)
    setAvailableTimes(timesForDay); // Update available times
    setSelectedTime("");

  };


  // Function to handle time selection
  const handleTimeChange = (e) => {

    const selectedTimeSlotId = e.target.value; // Get the selected VenueAllocationId


    const timeSlot = availableTimes.find((slot) => slot.id === selectedTimeSlotId);

    if (timeSlot) {
      setSelectedTime(timeSlot.time); // Update with the selected time
    }

    // Update formData with the selected VenueAllocationId
    setFormData((prevFormData) => ({
      ...prevFormData,
      VenueAllocationId: selectedTimeSlotId, // Store the selected ID
    }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("playpass,", playpass?.PlayPassId)

    try {
      await updatePlaypass(playpass?.PlayPassId, formData);
      setToast({
        message: "Playpass updated successfully!",
        type: "success",
      });
      setTimeout(() => {
        onCancel();
        window.location.reload();
      }, 500);
    } catch (error) {
      console.error("Error updating playpass:", error);
      setToast({
        message: "Failed to update playpass. Please try again.",
        type: "error",
      });
    }
  };

  if (loading) {
    return <Loader />
  }


  return (

    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative bg-white p-4 md:p-6 rounded-lg shadow-lg w-11/12 max-w-2xl">
        {/* Close Button */}
        <button
          type="button"
          onClick={onCancel}
          className="absolute top-3 right-3 text-gray-600 hover:text-red-500 text-2xl"
        >
          <FaTimes />
        </button>

        <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-center">
          Edit Playpass
        </h2>
        {toast && (
          <ToastNotification
            message={toast.message}
            type={toast.type}
            onClose={() => setToast(null)}
          />
        )}
        <div className="overflow-y-auto max-h-[70vh] pr-2">
          {/* Update playpass form here */}
          <form onSubmit={handleSubmit}>

            {/* Playpass game title */}
            <div className="mb-6">
              <label
                htmlFor="PlayPassTitle"
                className="block text-lg font-semibold mb-2"
              >
                Playpass Game Title *
              </label>
              <input
                type="text"
                name="PlayPassTitle"
                id="PlayPassTitle"
                value={formData.PlayPassTitle}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg"

              />
            </div>

            {/* Playpass Game Description */}
            <div className="mb-6">
              <label
                htmlFor="Description"
                className="block text-lg font-semibold mb-2"
              >
                Playpass Game Description *
              </label>
              <textarea
                name="Description"
                id="Description"
                value={formData.Description}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg"

              />
            </div>

            {/* Playpass game type */}
            <div className="mb-6">
              <label htmlFor="PlayPassType" className="block text-lg font-semibold mb-2">
                Playpass Game Type*
              </label>

              <select
                name="PlayPassType"
                id="PlayPassType"
                value={formData.PlayPassType}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
              >

                <option selected hidden disabled value="">Select Playpass Game Type</option>
                <option value="Games">Games</option>


              </select>
            </div>

            {/* Sport Dropdown */}
            <div className="mb-6">
              <label htmlFor="SportId" className="block text-lg font-semibold mb-2">
                Sport *
              </label>
              <select
                name="SportId"
                id="SportId"
                value={formData.SportId}
                onChange={(e) => {
                  const { name, value } = e.target;
                  // Store the selected sport ID in state
                  setSelectedSportId(value);
                  setFormData((prevData) => ({
                    ...prevData,
                    [name]: value,        // Update the selected SportId
                    MatchFormat: '',
                    // Reset MatchFormat to an empty string
                  }));
                }}
                className="w-full p-3 border border-gray-300 rounded-lg"

              >
                <option disabled selected hidden value="">Select a Sport</option>
                {sports.map((sport) => (
                  <option key={sport.SportId} value={sport.SportId}>
                    {sport.SportType}
                  </option>
                ))}
              </select>
            </div>

            {
              sportMatchFormats ? (
                <div className="mb-6">
                  <label htmlFor="MatchFormat" className="block text-lg font-semibold mb-2">
                    Match Format *
                  </label>
                  <select
                    name="MatchFormat"
                    id="MatchFormat"
                    value={formData.MatchFormat}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        MatchFormat: parseInt(e.target.value, 10), // Parse value as an integer
                      })
                    }
                    className="w-full p-3 border border-gray-300 rounded-lg"

                  >
                    <option disabled hidden value="">
                      Select Match Format
                    </option>
                    {sportMatchFormats.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null
            }




            {/* Match Type Dropdown */}
            <div className="mb-6">
              <label htmlFor="MatchType" className="block text-lg font-semibold mb-2">
                Match Type *
              </label>
              <select
                name="MatchType"
                id="MatchType"
                value={formData.MatchType}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg"

              >
                <option disabled selected hidden value="">Select Match Type</option>
                <option value="Men">Men's</option>
                <option value="Women">Women's</option>
                <option value="Mixed">Mixed / Co-ed</option>
              </select>

            </div>



            {/* Playpass Game Description */}
            <div className="mb-6">
              <label
                htmlFor="TotalRounds"
                className="block text-lg font-semibold mb-2"
              >
                Total Rounds *
              </label>
              <input
                type="text"
                name="TotalRounds"
                id="TotalRounds"
                value={formData.TotalRounds}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg"

              />
            </div>



            {/* Skill Level */}
            <div className="mb-6">
              <label
                htmlFor="SkillLevel"
                className="block text-lg font-semibold mb-2"
              >
                Skill Level
              </label>

              <div className="w-full flex  gap-x-4">
                {["Novice", "Beginner", "Intermediate", "Expert"].map((level) => (
                  <span key={level} className="flex justify-center items-center gap-x-1">
                    <label htmlFor={level}>{level}</label>
                    <input type="checkbox"
                      name={level}
                      value={level}
                      id={level}
                      onChange={addSkillLevel}
                      checked={formData.SkillLevel.includes(level)} />
                  </span>
                ))}

              </div>


            </div>



            {/* Media Section */}
            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2">Add Media</label>
              {formData.PlayPassImages?.length > 0 ? (
                formData.PlayPassImages.map((PlayPassImages, index) => (
                  <div key={index} className="mb-4">
                    <input
                      type="text"
                      name="Url"
                      placeholder="Enter Media URL"
                      value={PlayPassImages.Url}
                      onChange={(e) => handleMediaChange(index, e)}
                      className="w-full p-3 border border-gray-300 rounded-lg mb-2"
                    />

                    <select
                      name="MimeType"
                      id="MimeType"
                      value={PlayPassImages.MimeType}
                      onChange={(e) => handleMediaChange(index, e)}
                      className="w-full p-3 border border-gray-300 rounded-lg mb-2"
                    >
                      <option disabled selected hidden value="">Select MimeType</option>
                      <option value="apng">apng</option>
                      <option value="avif">avif</option>
                      <option value="gif">gif</option>
                      <option value="jpeg">jpeg</option>
                      <option value="png">png</option>
                      <option value="svg+xml">svg+xml</option>
                      <option value="webp">webp</option>
                      <option value="webm">webm</option>
                      <option value="mp4">mp4</option>
                      <option value="mpeg">mpeg</option>
                    </select>

                    <textarea
                      name="Description"
                      placeholder="Enter Description of Media"
                      value={PlayPassImages.Description}
                      onChange={(e) => handleMediaChange(index, e)}
                      className="w-full p-3 border border-gray-300 rounded-lg"
                      aria-label={`Answer ${index + 1}`}
                    />
                    <button
                      type="button"
                      onClick={() => removeMedia(index)} // Remove specific FAQ
                      className="bg-red-600 text-white px-2 py-1 rounded-lg text-sm"
                    >
                      Remove
                    </button>
                  </div>
                ))
              ) : (
                <p className="text-gray-500 -mt-2">No Media added yet.</p>
              )}
              <button
                type="button"
                onClick={addMedia}
                className="bg-green-500 text-white px-4 py-2 rounded-lg mt-2"
              >
                Add Media
              </button>
            </div>



            {/* Venue Dropdown */}
            {updateVenueOption === true ?
              (
                <>
                  <div className="mb-6">
                    <label htmlFor="SportId" className="block text-lg font-semibold mb-2">
                      Select Venue *
                    </label>
                    <select
                      name="venueId"
                      id="venueId"
                      value={selectedVenue}
                      onChange={handleVenueInputChange}
                      className="w-full p-3 border border-gray-300 rounded-lg"

                    >
                      <option disabled selected hidden value="">Select a Venue</option>
                      {venues.map((venue) => (
                        <option key={venue.VenueId} value={venue.VenueId}>
                          {venue.VenueName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="-mt-2">
                    {selectedSportId.length > 0 ? ('') : (<p className="text-red-500 text-base mb-4">Select a sport first!</p>)}
                  </div>

                  {/* Slot selection Dropdown */}
                  <div className="mb-3 flex gap-x-4">
                    {/* Day */}
                    <div className="w-[95%]">
                      <label htmlFor="day" className="block text-lg font-semibold mb-2">
                        Available Days*
                      </label>
                      <select
                        name="day"
                        id="day"
                        value={selectedDay}
                        onChange={handleDayChange}
                        className="w-full p-3 border border-gray-300 rounded-lg"

                      >
                        <option disabled selected hidden value="">Select Day</option>
                        {availableDays.map((day) => (
                          <option key={day} value={day}>
                            {new Date(day).toLocaleDateString("en-US", {
                              day: "2-digit",
                              month: "long",
                              year: "numeric"
                            })} {/* Format day */}
                          </option>
                        ))}
                      </select>

                    </div>

                    {/* Time */}

                    <div className="w-[95%]">
                      <label htmlFor="time" className="block text-lg font-semibold mb-2">
                        Select Slot*
                      </label>
                      <select
                        name="time"
                        id="time"
                        value={selectedTime}
                        onChange={handleTimeChange}
                        className="w-full p-3 border border-gray-300 rounded-lg"

                      >
                        <option disabled selected hidden value="">Select Time</option>
                        {availableTimes.map((slot) => (

                          <option key={slot.id} value={slot.id}>
                            {slot.time} {"  "} {`\t(Play Area: ${slot.PlayArea.PlayAreaName})`}

                          </option>

                        ))}
                      </select>
                    </div>
                  </div>

                  <div >
                    {availableDays.length > 0 ? ('') : (<p className="mb-2 text-base text-red-500">No slots available!</p>)}
                  </div>
                  <button
                    type="button"
                    onClick={() => setUpdateVenueOption(false)}
                    className="bg-red-500 text-white px-4 py-2 rounded-lg mb-4"
                  >
                    Cancel Venue Updation
                  </button>

                </>

              ) : (
                <div className="my-6">
                  <div>

                  </div>
                  <div className=" block text-lg font-semibold">
                    Venue details are:
                  </div>
                  <p className="ml-4"> <span className="font-medium">Venue Name:</span>  {playpass?.VenueName}</p>
                  <p className="ml-4"> <span className="font-medium">Play Area:</span> {playpass?.VenueAllocation?.PlayArea.PlayAreaName}</p>

                  <button
                    type="button"
                    onClick={() => setUpdateVenueOption(true)}
                    className="bg-green-500 text-white px-4 py-2 rounded-lg mt-2"
                  >
                    Update Venue
                  </button>

                </div>


              )
            }






            {/* FAQ Section */}
            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2">FAQs</label>
              {formData.FAQ?.length > 0 ? (
                formData.FAQ.map((faq, index) => (
                  <div key={index} className="mb-4">
                    <input
                      type="text"
                      name="question"
                      placeholder="Question"
                      value={faq.question}
                      onChange={(e) => handleFAQChange(index, e)}
                      className="w-full p-3 border border-gray-300 rounded-lg mb-2"
                      aria-label={`Question ${index + 1}`}
                    />
                    <textarea
                      name="answer"
                      placeholder="Answer"
                      value={faq.answer}
                      onChange={(e) => handleFAQChange(index, e)}
                      className="w-full p-3 border border-gray-300 rounded-lg"
                      aria-label={`Answer ${index + 1}`}
                    />
                    <button
                      type="button"
                      onClick={() => removeFAQ(index)} // Remove specific FAQ
                      className="bg-red-600 text-white px-2 py-1 rounded-lg text-sm"
                    >
                      Remove
                    </button>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">No FAQs added yet.</p>
              )}
              <button
                type="button"
                onClick={addFAQ}
                className="bg-green-500 text-white px-4 py-2 rounded-lg mt-2"
              >
                Add FAQ
              </button>
            </div>


            {/* Match Type Dropdown */}
            <div className="mb-6">
              <label htmlFor="PlayPassAccess" className="block text-lg font-semibold mb-2">
                Game Access *
              </label>
              <select
                name="PlayPassAccess"
                id="PlayPassAccess"
                value={formData.PlayPassAccess}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 rounded-lg"

              >
                <option disabled selected hidden value="">Select Game Access</option>
                <option value="Invite">Invite</option>
                <option value="Open">Open</option>

              </select>

            </div>


            {formData.PlayPassAccess === "Invite" ? (

              <>
                {/* Add invite Section */}
                <div className="mb-6">

                  {console.log("invite option", inviteOption)}
                  {console.log("consoling invitelist", formData.InviteList)}

                  <label className="block text-lg font-semibold mb-2">Invite People</label>
                  {formData.InviteList?.map((InviteList, index) => (
                    <div key={index} className="mb-4">
                      {console.log("invite list", inviteOption)}
                      {console.log("consoling the find option", inviteOption.find((option) => option.value === InviteList.UserId) || null)}
                      <Select
                        options={inviteOption}
                        value={inviteOption.find((option) => option.value === InviteList.UserId) || inviteOption.UserId}
                        onChange={(selectedOption) => handleInviteChange(index, selectedOption)}
                        placeholder="Search and select a user..."
                        isSearchable // Enable search
                        className="mb-2"
                      />
                      <button
                        type="button"
                        onClick={() => removeInvite(index)} // Remove specific invite
                        className="bg-red-600 text-white px-2 py-1 rounded-lg text-sm "
                      >
                        Remove
                      </button>
                    </div>
                  ))}



                  <div className="flex gap-x-2">

                    <button
                      type="button"
                      onClick={addInvite}
                      className="bg-green-500 text-white p-2 rounded-lg"
                    >
                      Add +
                    </button>

                  </div>

                </div>
                {/* Team invite Section */}

                <div className="mb-6">
                  <label className="block text-lg font-semibold mb-2">Invite Teams</label>
                  {formData.TeamInviteList?.map((teamInvite, index) => (
                    <div key={index} className="mb-4">
                      {console.log("team  list", teamInvite)}
                      <Select
                        options={teamOption}
                        value={teamOption.find((option) => option.value === teamInvite.TeamId) || teamOption.value}
                        onChange={(selectedOption) => handleTeamInviteChange(index, selectedOption)}
                        placeholder="Search and select a team..."
                        isSearchable // Enable search
                        className="mb-2"
                      />
                      <button
                        type="button"
                        onClick={() => removeTeamInvite(index)} // Remove specific invite
                        className="bg-red-600 text-white px-2 py-1 rounded-lg text-sm "
                      >
                        Remove
                      </button>

                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={addTeamInvite}
                    className="bg-green-500 text-white p-2 rounded-lg"
                  >
                    Add +
                  </button>
                </div>

              </>

            ) : ('')}

            {/* Submit Button */}
            <button
              type="submit"
              className="bg-blue-500 text-white p-3 rounded-lg mt-4 w-full"
            >
              Update Playpass
            </button>


          </form>
        </div>

      </div>
    </div>
  )
}

export default EditPlaypassForm
