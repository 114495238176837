import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // To get event ID from the URL
import {
  fetchCoachingEventDetails,
  updateEvent,
  downloadFile,
} from "../../api/api"; // Fetch event details by ID
import CalendarView from "../../components/CalendarView";
import EditCoaching from "../../components/Coachings/EditCoaching"; 
import AddCoachingSlot from "../../components/AddCoachingSlot";
import { useNavigate } from "react-router-dom";
import {
  FaClipboardList,
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaPlus,
} from "react-icons/fa";

const CoachingDetails = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditingCoaching, setIsEditingCoaching] = useState(false);
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [sponsorImages, setSponsorImages] = useState([]);
  const [openFAQIndex, setOpenFAQIndex] = useState(null);
  const [isAddingSlot, setIsAddingSlot] = useState(false);
  const navigate = useNavigate();

  // Fetch coaching details when the component mounts
  useEffect(() => {
    const getCoachingDetails = async () => {
      try {
        const eventData = await fetchCoachingEventDetails(id);
        setEvent(eventData);

        // Fetch sponsor images if SponsorImageFileId exists
        if (eventData?.Sponsors?.length > 0) {
          const images = await Promise.all(
            eventData.Sponsors.map(async (sponsor) => {
              try {
                // Find the rectangle image in the sponsor's images
                const rectangleImage = sponsor.SponsorImages.find(
                  (image) => image.Tags === "RECTANGLE"
                );

                if (rectangleImage?.ImageId) {
                  const imageUrl = await downloadFile(rectangleImage.ImageId);
                  return {
                    SponsorId: sponsor.SponsorId,
                    imageUrl,
                  };
                }

                return { SponsorId: sponsor.SponsorId, imageUrl: null };
              } catch (err) {
                console.error(
                  `Failed to fetch image for sponsor ${sponsor.SponsorId}:`,
                  err
                );
                return { SponsorId: sponsor.SponsorId, imageUrl: null };
              }
            })
          );
          setSponsorImages(images);
        }
      } catch (error) {
        setError("Failed to load Coaching details");
        console.error("Error fetching Coaching details:", error);
      } finally {
        setLoading(false);
      }
    };

    getCoachingDetails();
  }, [id]);

  if (loading) {
    return (
      <p className="text-center text-gray-700">Loading coaching details...</p>
    );
  }

  if (error) {
    return <p className="text-center text-red-500">{error}</p>;
  }

  const formatAllocationsForCalendar = (allocations) => {
    return allocations.map((allocation) => ({
      id: allocation.CoachingAllocationId,
      title: `Allocation (${allocation.Learners} Learners)`,
      start: new Date(allocation.StartTimeStamp),
      end: new Date(
        new Date(allocation.StartTimeStamp).getTime() +
          allocation.Duration * 60000
      ),
      allDay: false,
    }));
  };


  const handleUpdateCoaching = async (updatedCoachingId) => {
    try {
      setLoading(true); // Show loading while fetching
      const updatedData = await fetchCoachingEventDetails(updatedCoachingId);
      setEvent(updatedData); // ✅ Update event state with the latest data
    } catch (error) {
      console.error("Error fetching updated coaching details:", error);
      setError("Failed to refresh coaching details.");
    } finally {
      setLoading(false); // Stop loading
    }
  };
  


  // Handling Cancel Edit
  const handleCancelEdit = () => {
    setIsEditingCoaching(false); // Disable editing when cancel button is clicked
  };

  // Save changes to the event
  const handleSaveChanges = async (updatedEventData) => {
    try {
     
      const updatedEvent = await updateEvent(event.EventId, updatedEventData);

      const finalEvent = {
        ...event, 
        ...updatedEvent, 
      };

      setEvent(finalEvent); 
      setIsEditingCoaching(false); 

      if (updatedEvent && updatedEvent.EventId) {
        setShowSuccessNotification(true);

   
        setTimeout(() => {
          setShowSuccessNotification(false);
        }, 3000);
      }
    } catch (error) {
      setError("Failed to save changes");
      console.error("Error saving Coaching:", error);
    }
  };

  const handleAddSlot = async () => {
    try {
      const updatedEventData = await fetchCoachingEventDetails(id);
      setEvent(updatedEventData);
    } catch (error) {
      console.error("Error fetching updated event details:", error);
    }
  };

  const handleShowMembersClick = () => {
    // Navigate to the Event Members page for this event
    navigate(`/coaching/details/${id}/registration`); // Use the correct event ID
  };

  const toggleFAQ = (index) => {
    setOpenFAQIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="container mx-auto p-6 relative">
      {/* Success Notification */}
      {showSuccessNotification && (
        <div className="absolute top-6 right-6 p-4 bg-green-500 text-white rounded-lg shadow-lg flex items-center">
          <span className="mr-2">✔️</span>
          <span>Coaching successfully updated!</span>
        </div>
      )}
      {/* Edit Button */}
      <button
        className="text-[#512D9A] py-1 px-3 border-2 border-[#F2F2F2] rounded hover:shadow-sm hover:font-medium absolute top-8 right-8 flex items-center justify-center"
        onClick={() => setIsEditingCoaching(true)}
      >
        <span className="hidden sm:inline">Edit</span>
        <FaEdit className="h-5 w-5 sm:hidden" />
      </button>

      {/* Show Event Registrations Button */}
      <button
        className="text-[#512D9A] py-1 px-3 border-2 border-[#F2F2F2] rounded hover:shadow-sm hover:font-medium absolute top-8 right-24 flex items-center justify-center"
        onClick={handleShowMembersClick} // Trigger event members page redirection
      >
      <span className="hidden sm:inline">Registration Details</span>
      <FaClipboardList className="h-5 w-5 sm:hidden" />
      </button>

      {/* Event Details */}
      <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
        <h2 className="text-4xl font-bold text-gray-900 mb-4">
          {event?.CoachingTitle}
        </h2>

        <p className="text-lg text-gray-700 mb-4">
          <strong>Description:</strong> {event?.CoachingDescription}
        </p>
      </div>

      {/* Event Information Section */}
      <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
        <h3 className="text-2xl font-semibold text-gray-900 mb-4">
          Coaching Information
        </h3>
        <div className="space-y-4">
          <p className="text-lg text-gray-700">
            <strong>Coaching Type:</strong> {event?.CoachingAccess}
          </p>
          <p className="text-lg text-gray-700">
            <strong>Coaching Sport:</strong>{" "}
            {event?.sport?.SportName || "Not Available"}
          </p>

          <p className="text-lg text-gray-700">
            <strong>Is Paid:</strong> {event?.isPaid == true ? "Yes" : "No"}
          </p>
        </div>
      </div>

      {/* Slot Details Section */}
      <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-2xl font-semibold text-gray-900">
            Coaching Slots
          </h3>
          <button
            className="text-[#512D9A] px-3 py-1 border-2 border-[#512D9A] rounded hover:bg-[#512D9A] hover:text-white flex items-center"
            onClick={() => setIsAddingSlot(true)}
          >
            <FaPlus className="mr-2" /> Add Slot
          </button>
        </div>

        <CalendarView coachingId={id} />
      </div>

      {/* Sponsors */}
      <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
        <h3 className="text-2xl font-semibold text-gray-900 mb-4">Sponsors</h3>
        {event?.Sponsors?.length > 0 ? (
          event.Sponsors.map((sponsor) => {
            const sponsorImage = sponsorImages.find(
              (img) => img.SponsorId === sponsor.SponsorId
            );
            return (
              <div
                key={sponsor.SponsorId}
                className="flex items-center space-x-4 mb-4"
              >
                {sponsorImage?.imageUrl ? (
                  <img
                    src={sponsorImage.imageUrl}
                    alt={sponsor.SponsorName}
                    className="w-32 h-20 object-cover rounded-lg"
                  />
                ) : (
                  <div className="w-32 h-20 rounded-lg bg-gray-200 flex items-center justify-center">
                    <span className="text-gray-500">No Image</span>
                  </div>
                )}
                <p className="text-lg text-gray-700 font-medium">
                  {sponsor.SponsorName}
                </p>
              </div>
            );
          })
        ) : (
          <p className="text-lg text-gray-700">
            No sponsors listed for this event.
          </p>
        )}
      </div>

      {/* FAQs Section */}
      <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
        <h3 className="text-2xl font-semibold text-gray-900 mb-6">FAQs</h3>
        {event?.FAQ?.length > 0 ? (
          <div className="space-y-4">
            {event.FAQ.map((faq, index) => (
              <div
                key={index}
                className={`transition-all border border-gray-200 rounded-lg overflow-hidden shadow-md ${
                  openFAQIndex === index ? "bg-gray-50" : ""
                }`}
              >
                <div
                  className="flex justify-between items-center p-4 cursor-pointer hover:bg-gray-100 transition-colors duration-300"
                  onClick={() => toggleFAQ(index)}
                >
                  <p className="text-lg text-gray-900 font-medium">
                    <span className="text-gray-400 font-bold mr-2">{`Q${
                      index + 1
                    }`}</span>
                    {faq.question || "No question provided"}
                  </p>
                  {openFAQIndex === index ? (
                    <FaChevronUp className="text-blue-600" />
                  ) : (
                    <FaChevronDown className="text-gray-600" />
                  )}
                </div>
                {openFAQIndex === index && (
                  <div className="p-4 text-gray-700 bg-gray-50 border-t border-gray-200">
                    <span className="block text-gray-800 font-semibold mb-1">{`Answer:`}</span>
                    {faq.answer || "No answer provided"}
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p className="text-lg text-gray-700">
            No FAQs available for this coaching.
          </p>
        )}
      </div>

      {/* Edit Coaching Overlay */}
      {isEditingCoaching && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl max-h-[90vh] overflow-auto relative">
            {/* Close Button */}
            <button
              onClick={() => setIsEditingCoaching(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 text-2xl"
            >
              ✕
            </button>
            <div className="p-6">
              <EditCoaching
                coachingDetails={event}
                onClose={() => setIsEditingCoaching(false)}
                onUpdate={handleUpdateCoaching}
              />
            </div>
          </div>
        </div>
      )}

      {/* Add Slot Modal */}
      {isAddingSlot && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl max-h-[90vh] overflow-auto relative">
            {/* Close Button */}
            <button
              onClick={() => setIsAddingSlot(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 text-2xl"
            >
              ✕
            </button>
            <div className="p-6">
              <AddCoachingSlot
                coachingId={id}
                onClose={() => setIsAddingSlot(false)}
                onAddSlot={handleAddSlot}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoachingDetails;
