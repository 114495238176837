import React, { useState, useEffect } from 'react';
import { updateGameScore } from '../../api/api';
import ToastNotification from '../../components/ToastNotification';
import { FaTimes } from 'react-icons/fa';
import ClipLoader from 'react-spinners/ClipLoader';


const PlaypassScoring = ({ playpass, teamData, onCancel }) => {
    const [toast, setToast] = useState(null);
    const [scores, setScores] = useState({});
    const [initialScores, setInitialScores] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingMarkDone,  setLoadingMarkDone] = useState(false)

    // Initialize scores state
    useEffect(() => {
        if (playpass?.Games?.Rounds) {
            const initialScores = {};
            playpass.Games.Rounds.forEach(round => {
                round.RoundScores.forEach(score => {
                    initialScores[score.GameTakeawayId] = score.Score;
                });
            });
            setScores(initialScores);
            setInitialScores(initialScores);
        }
    }, [playpass]);

    // Handle score change
    const handleScoreChange = (gameTakeawayId, newScore) => {
        setScores(prev => ({ ...prev, [gameTakeawayId]: Number(newScore) }));
    };

    // Submit handler
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            let isGameComplete = false
            await Promise.all(
                Object.entries(scores).map(([gameTakeawayId, score]) => 
                    updateGameScore(gameTakeawayId, score, isGameComplete)
                )
            );
            setToast({ message: 'Scores updated successfully!', type: 'success' });
            setInitialScores(scores);
            setTimeout(() => {
                onCancel();
                window.location.reload();
              }, 500);
        } catch (error) {
            console.error('Error updating scores:', error);

            if(error && error.status===409){
                setToast({ message: 'Score can be updated after game is complete!', type: 'error' });
            }else{
                setToast({ message: 'Failed to update scores', type: 'error' });
            }

            
            setScores(initialScores);
        } finally {
            setLoading(false);
        }
    };

    const handleMarkDone = async()=>{
        setLoadingMarkDone(true);
        console.log(playpass?.Games?.Rounds[0].RoundScores[0])
        let gameTakeawayId = playpass?.Games?.Rounds[0].RoundScores[0].GameTakeawayId
        let score = playpass?.Games?.Rounds[0].RoundScores[0].Score
        let isGameComplete = true
        
        try {
            alert("Mark Scoring as Completed?")
            await updateGameScore(gameTakeawayId, score, isGameComplete)
            setToast({ message: 'Marked Completed!', type: 'success' });
            setTimeout(() => {
                onCancel();
                window.location.reload();
              }, 500);
            
        } catch (error) {
            console.error('Error marking complete:', error);
            if(error && error.status===409){
                setToast({ message: 'Score can be updated after game is complete!', type: 'error' });
            }else{
                setToast({ message: 'Failed to update scores', type: 'error' });
            }
        } finally{
            setLoadingMarkDone(false)
        }
    }


    return (
        <div>
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="relative bg-[#ffffff] p-4 md:p-6 rounded-lg shadow-lg w-11/12 max-w-3xl">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="absolute top-3 right-3 text-gray-600 hover:text-red-500 text-2xl"
                    >
                        <FaTimes />
                    </button>

                    <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-center">
                        Update Playpass Score
                    </h2>
                    <h2 className="text-xl md:text-2xl font-medium mb-4 md:mb-6 text-center">
                        {playpass.PlayPassTitle}
                    </h2>
                    
                    {toast && <ToastNotification {...toast} onClose={() => setToast(null)} />}

                    <div className="pickleball_scoring w-full mt-8 overflow-y-auto max-h-[70vh]">
                        <form onSubmit={handleSubmit} className="w-full">
                            {playpass?.Games?.Rounds?.map((round) => (
                                <div 
                                    className="w-[90%] mx-auto mb-8 bg-[#dee2e6] py-3 rounded-md"
                                    key={round.RoundNumber}
                                >
                                    <h2 className="text-center text-2xl font-semibold">
                                        Round {round.RoundNumber + 1}
                                    </h2>

                                    <div className="flex justify-between mt-6">
                                        {round.RoundScores.map((score) => {
                                            const team = teamData.find(t => t.TeamId === score.TeamId);
                                            return (
                                                <div 
                                                    className="w-1/2 flex flex-col items-center"
                                                    key={score.GameTakeawayId}
                                                >
                                                    <p className="text-xl font-medium">
                                                        Team: {team?.TeamName || 'N/A'}
                                                    </p>
                                                    <input
                                                        type="number"
                                                        placeholder="Enter Score"
                                                        className="outline-none border border-black p-1 rounded-md"
                                                        value={scores[score.GameTakeawayId] ?? ''}
                                                        onChange={(e) => 
                                                            handleScoreChange(
                                                                score.GameTakeawayId, 
                                                                e.target.value
                                                            )
                                                        }
                                                        disabled={loading}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ))}

                            <div className="w-full flex justify-center gap-x-3">
                                <button
                                    type="submit"
                                    className="bg-blue-500 text-white p-3 rounded-lg w-36"
                                    disabled={loading}
                                >
                                    {loading ? <ClipLoader color="white" size={20} /> : 'Update Scores'}
                                </button>

                                <div
                                    className="bg-green-500 text-white p-3 rounded-lg w-36 text-center cursor-pointer"
                                    onClick={handleMarkDone}
                                >
                                    {loadingMarkDone? (
                                        <ClipLoader color="white" size={20} />
                                    ) : ('Mark Done')}
                                    
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlaypassScoring;