import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchRewards, deleteReward } from "../../api/api";
import { FaTrashAlt } from "react-icons/fa";
const RewardsList = () => {
  const [rewards, setRewards] = useState([]);
  const [filteredRewards, setFilteredRewards] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deletingRewards, setDeletingRewards] = useState(null);

  const navigate = useNavigate();

  // Fetch rewards on component mount
  useEffect(() => {
    const getRewards = async () => {
      try {
        setLoading(true);
        const rewardsData = await fetchRewards();
        setRewards(rewardsData);
        setFilteredRewards(rewardsData);
      } catch (error) {
        console.error("Error fetching rewards:", error);
        setError("Failed to load rewards. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    getRewards();
  }, []);

  const handleDelete = async (rewardId) => {
    if (!rewardId || typeof rewardId !== "string" || rewardId.length !== 36) {
      alert("Invalid reward ID. Cannot delete.");
      return;
    }

    const confirmDelete = window.confirm(
      "Are you sure you want to delete this reward?"
    );
    if (!confirmDelete) return;

    setDeletingRewards(rewardId); // Show loading state for this reward

    try {
      await deleteReward(rewardId);

      // Remove deleted reward from the list
      setRewards((prevRewards) =>
        prevRewards.filter((reward) => reward.RewardId !== rewardId)
      );
      setFilteredRewards((prevFiltered) =>
        prevFiltered.filter((reward) => reward.RewardId !== rewardId)
      );
    } catch (error) {
      console.error("Failed to delete reward:", error);
      alert("Error deleting reward. Please try again.");
    } finally {
      setDeletingRewards(null); // Reset loading state
    }
  };

  // Handle search input
  const handleSearchChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    // Filter rewards by name, description, or type
    const filtered = rewards.filter(
      (reward) =>
        reward.RewardName.toLowerCase().includes(term) ||
        reward.RewardDescription.toLowerCase().includes(term) ||
        reward.RewardType.toLowerCase().includes(term)
    );
    setFilteredRewards(filtered);
  };

  return (
    <div className="container mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">Rewards</h1>
        <button
          onClick={() => navigate("/rewards/create")}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600"
        >
          Create Reward
        </button>
      </div>

      {/* Search Bar */}
      <div className="mb-6">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search rewards by name, description, or type..."
          className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Error Message */}
      {error && <p className="text-red-500 text-center">{error}</p>}

      {/* Loading State */}
      {loading ? (
        <p className="text-center text-gray-500">Loading rewards...</p>
      ) : (
        // Rewards Table
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white rounded-lg shadow-md overflow-hidden">
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm">
                <th className="py-3 px-6 text-left">Reward Name</th>
                <th className="py-3 px-6 text-left">Type</th>
                <th className="py-3 px-6 text-left">Description</th>
                <th className="py-3 px-6 text-left">Coupon Code</th>
                <th className="py-3 px-6 text-left">Expiry Date</th>
                <th className="py-3 px-6 text-left">Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {filteredRewards.length > 0 ? (
                filteredRewards.map((reward, index) => (
                  <tr
                    key={index}
                    className="border-b border-gray-200 hover:bg-gray-100"
                  >
                    <td className="py-3 px-6 font-medium">
                      {reward.RewardName}
                    </td>
                    <td className="py-3 px-6">{reward.RewardType}</td>
                    <td className="py-3 px-6">{reward.RewardDescription}</td>
                    <td className="py-3 px-6">{reward.CouponCode}</td>
                    <td className="py-3 px-6">
                      {reward.RewardExpiry
                        ? new Date(reward.RewardExpiry).toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )
                        : "N/A"}
                    </td>

                    <td className="py-3 px-6 text-center">
                      <button
                        onClick={() => handleDelete(reward.RewardId)}
                        className="text-red-500 hover:text-red-700"
                        disabled={deletingRewards === reward.RewardId}
                      >
                        {deletingRewards === reward.RewardId ? (
                          <span className="text-gray-500">Deleting...</span>
                        ) : (
                          <FaTrashAlt />
                        )}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="4"
                    className="text-center py-3 text-gray-500 italic"
                  >
                    No rewards found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default RewardsList;
