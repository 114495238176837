import React, { useState, useEffect } from "react";
import {
  updateCoaching,
  fetchSports,
  fetchSponsors,
  getUsers,
  downloadFile,
  uploadEventImage,
} from "../../api/api";
import ToastNotification from "../ToastNotification";
import CropperEventImage from "../CropperEventImage";
import { FaTimes, FaPlus, FaTrash } from "react-icons/fa";

const EditCoachingOverlay = ({ coachingDetails, onClose, onUpdate }) => {
  const [formData, setFormData] = useState({
    CoachId: coachingDetails?.CoachId || "",
    CoachingTitle: coachingDetails?.CoachingTitle || "",
    CoachingDescription: coachingDetails?.CoachingDescription || "",
    CoachingType: coachingDetails?.CoachingType || "League",
    CoachingImages: coachingDetails?.CoachingImages || [],
    CoachingTags: coachingDetails?.CoachingTags || [],
    CoachingLevel: coachingDetails?.CoachingLevel || "",
    CoachingAccess: coachingDetails?.CoachingAccess || "Open",
    SportId: coachingDetails?.sport?.SportId || "",
    isPaid: coachingDetails?.isPaid || false,
    InviteList: coachingDetails?.InviteList || [],
    FAQ: coachingDetails?.FAQ || [{ question: "", answer: "" }],
    SponsorIds:
      coachingDetails?.Sponsors?.map((sponsor) => sponsor.SponsorId) || [],
    IsActive: coachingDetails?.IsActive ?? true,
  });

  const [sports, setSports] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [toast, setToast] = useState(null);
  const [coaches, setCoaches] = useState([]);
  const [filteredCoaches, setFilteredCoaches] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [showCropper, setShowCropper] = useState(false);
  const [usedCropOptions, setUsedCropOptions] = useState([]);

  useEffect(() => {
    const fetchImagePreviews = async () => {
      try {
        const imagePromises = formData.CoachingImages.map(async (image) => {
          if (!image.FileId || image.FileId.startsWith("blob:")) {
            return {
              ...image,
              imageUrl: null, // Mark as invalid but don't remove
              isBroken: true,
              errorMessage: "Invalid file reference",
            };
          }

          try {
            const imageUrl = await downloadFile(image.FileId);
            return { ...image, imageUrl, isBroken: false };
          } catch (error) {
            console.error(
              `Failed to fetch image for FileId ${image.FileId}:`,
              error
            );
            return {
              ...image,
              imageUrl: null, // Keep it in UI
              isBroken: true,
              errorMessage: "Unable to fetch image from server",
            };
          }
        });

        const imagePreviews = await Promise.all(imagePromises);
        setImagePreviews(imagePreviews);
      } catch (error) {
        console.error("Error fetching image previews:", error);
      }
    };

    fetchImagePreviews();
  }, [coachingDetails]);

  useEffect(() => {
    const fetchSportsAndSponsors = async () => {
      try {
        const fetchedSports = await fetchSports();
        setSports(fetchedSports);

        const fetchedSponsors = await fetchSponsors();
        setSponsors(fetchedSponsors);
      } catch (error) {
        console.error("Error fetching sports and sponsors:", error);
      }
    };

    fetchSportsAndSponsors();
  }, []);

  useEffect(() => {
    const fetchCoaches = async () => {
      try {
        const usersData = await getUsers();
        const coachList = usersData.users.filter(
          (user) => user.RoleType === "Coach"
        );
        setCoaches(coachList);
      } catch (error) {
        console.error("Error fetching coaches:", error);
      }
    };

    fetchCoaches();
  }, []);

  useEffect(() => {
    if (coachingDetails && coaches.length > 0) {
      // Check if the previously selected coach exists in the fetched list
      const existingCoach = coaches.find(
        (coach) => coach.UserId === coachingDetails.CoachId
      );

      setFormData((prevData) => ({
        ...prevData,
        SportId: coachingDetails.sport?.SportId || "",
        CoachId: existingCoach ? coachingDetails.CoachId : "",
      }));

      if (coachingDetails.sport?.SportId) {
        filterCoachesBySport(coachingDetails.sport?.SportId);
      }
    }
  }, [coachingDetails, coaches]);

  const filterCoachesBySport = (sportId) => {
    if (!sportId) {
      setFilteredCoaches([]);
      return;
    }

    const filtered = coaches.filter((coach) =>
      coach.PreferredSportsId?.includes(sportId)
    );

    setFilteredCoaches(filtered);

    if (!filtered.some((coach) => coach.UserId === formData.CoachId)) {
      setFormData((prevData) => ({
        ...prevData,
        CoachId: "",
      }));
    }
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "SportId") {
      filterCoachesBySport(value);
    }
  };

  const handleRemoveImage = (fileId) => {
    setImagePreviews((prev) => prev.filter((image) => image.FileId !== fileId));

    setFormData((prevData) => ({
      ...prevData,
      CoachingImages: prevData.CoachingImages.filter(
        (image) => image.FileId !== fileId
      ),
    }));

    setToast({ message: "Image removed successfully!", type: "success" });
  };

  const handleImageCrop = async (blob, tag) => {
    const formData = new FormData();
    formData.append("data", blob);
    formData.append("alt_text", tag);

    try {
      const response = await uploadEventImage(formData);
      if (response && response.FileId) {
        const imageUrl = await downloadFile(response.FileId);

        setImagePreviews((prev) => [
          ...prev,
          { FileId: response.FileId, Tags: [tag], imageUrl },
        ]);

        setFormData((prevData) => ({
          ...prevData,
          CoachingImages: [
            ...prevData.CoachingImages,
            { FileId: response.FileId, Tags: [tag] },
          ],
        }));

        setUsedCropOptions((prev) =>
          prev.includes(tag) ? prev : [...prev, tag]
        );

        setToast({ message: "Image uploaded successfully!", type: "success" });
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      setToast({ message: "Image upload failed!", type: "error" });
    } finally {
      setShowCropper(false);
    }
  };

  // Handle FAQ changes
  const handleFAQChange = (index, field, value) => {
    const updatedFAQs = [...formData.FAQ];
    updatedFAQs[index][field] = value;
    setFormData((prevData) => ({
      ...prevData,
      FAQ: updatedFAQs,
    }));
  };

  // Add new FAQ
  const addFAQ = () => {
    setFormData((prevData) => ({
      ...prevData,
      FAQ: [...prevData.FAQ, { question: "", answer: "" }],
    }));
  };

  // Remove FAQ
  const removeFAQ = (index) => {
    const updatedFAQs = formData.FAQ.filter((_, i) => i !== index);
    setFormData((prevData) => ({
      ...prevData,
      FAQ: updatedFAQs,
    }));
  };

  // Handle Sponsor Selection
  const handleSponsorChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      SponsorIds: checked
        ? [...prevData.SponsorIds, value]
        : prevData.SponsorIds.filter((id) => id !== value),
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedCoaching = await updateCoaching(
        coachingDetails.CoachOfferingId,
        formData
      );

      onClose();

      onUpdate(updatedCoaching.coaching_offering_id);

      setToast({ message: "Coaching updated successfully!", type: "success" });
    } catch (error) {
      console.error("Error updating coaching:", error);
      setToast({ message: "Failed to update coaching.", type: "error" });
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl max-h-[90vh] overflow-auto relative p-6">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-red-600 text-2xl"
        >
          <FaTimes />
        </button>

        <h2 className="text-3xl font-bold mb-6 text-center">Edit Coaching</h2>

        {toast && (
          <ToastNotification
            message={toast.message}
            type={toast.type}
            onClose={() => setToast(null)}
          />
        )}

        <form onSubmit={handleSubmit} className="max-h-[75vh] overflow-y-auto">
          {/* Coaching Title */}
          <div className="mb-6">
            <label className="block text-lg font-semibold mb-2">
              Coaching Title
            </label>
            <input
              type="text"
              name="CoachingTitle"
              value={formData.CoachingTitle}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              required
            />
          </div>

          {/* Sport Selection Dropdown */}
          <div className="mb-6">
            <label
              htmlFor="SportId"
              className="block text-lg font-semibold mb-2"
            >
              Select Sport *
            </label>
            <select
              name="SportId"
              id="SportId"
              value={formData.SportId}
              onChange={(e) => {
                handleInputChange(e);
                filterCoachesBySport(e.target.value); // ✅ Update coaches based on sport
              }}
              className="w-full p-3 border border-gray-300 rounded-lg"
              required
            >
              <option value="">Select a Sport</option>
              {sports.map((sport) => (
                <option key={sport.SportId} value={sport.SportId}>
                  {sport.SportType}
                </option>
              ))}
            </select>
          </div>

          {/* Coach Selection Dropdown */}
          <div className="mb-6">
            <label
              htmlFor="CoachId"
              className="block text-lg font-semibold mb-2"
            >
              Select Coach *
            </label>
            <select
              name="CoachId"
              id="CoachId"
              value={formData.CoachId} // ✅ Ensuring the correct value is selected
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              required
              disabled={!formData.SportId || filteredCoaches.length === 0} // ✅ Disable if no sport or no filtered coaches
            >
              <option value="">
                {formData.SportId
                  ? filteredCoaches.length === 0
                    ? "No coaches available for this sport"
                    : "Select a Coach"
                  : "Please select a sport first"}
              </option>
              {filteredCoaches.map((coach) => (
                <option key={coach.UserId} value={coach.UserId}>
                  {coach.FirstName} {coach.LastName} ({coach.EmailAddress})
                </option>
              ))}
            </select>
          </div>

          {/* Coaching Description */}
          <div className="mb-6">
            <label className="block text-lg font-semibold mb-2">
              Coaching Description
            </label>
            <textarea
              name="CoachingDescription"
              value={formData.CoachingDescription}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg resize-none"
              rows="4"
              placeholder="Enter coaching description"
              required
            />
          </div>

          {/* Coaching Images Preview */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-4">Uploaded Images</h3>
            {imagePreviews.length === 0 ? (
              <p className="text-gray-500 text-center">
                No images uploaded yet.
              </p>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                {imagePreviews.map((image, index) => (
                  <div
                    key={index}
                    className="relative bg-white shadow-lg rounded-xl overflow-hidden border border-gray-200 transition-all hover:shadow-2xl flex flex-col items-center"
                  >
                    {/* If Image is Broken, Show Placeholder */}
                    {image.isBroken ? (
                      <div className="w-full h-[250px] flex flex-col items-center justify-center bg-gray-100 text-gray-600">
                        <p className="text-center text-sm font-medium">
                          ⚠️ {image.errorMessage}
                        </p>
                        <p className="text-xs text-gray-500">
                          Remove or re-upload
                        </p>
                      </div>
                    ) : (
                      <div className="relative w-full h-[250px] flex items-center justify-center bg-gray-100">
                        <img
                          src={image.imageUrl}
                          alt={`Coaching ${image.Tags[0] || "Image"}`}
                          className="max-w-full max-h-full object-cover rounded-t-xl"
                          onError={(e) => {
                            e.target.onerror = null; // Prevent infinite error loops
                            e.target.src = ""; // Trigger the isBroken state
                            setImagePreviews((prevImages) =>
                              prevImages.map((img) =>
                                img.FileId === image.FileId
                                  ? { ...img, isBroken: true }
                                  : img
                              )
                            );
                          }}
                        />
                      </div>
                    )}

                    {/* Image Tag */}
                    <div className="absolute top-2 left-2 bg-blue-600 text-white text-xs font-semibold px-3 py-1 rounded-full shadow-md">
                      {image.Tags[0] || "No Tag"}
                    </div>

                    {/* Remove Button */}
                    <button
                      onClick={() => handleRemoveImage(image.FileId)}
                      className="absolute top-2 right-2 bg-red-600 text-white text-sm rounded-full px-2 py-1 shadow-md hover:bg-red-700 transition"
                    >
                      ✕
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>

          <button
            type="button"
            onClick={() => setShowCropper(true)}
            disabled={usedCropOptions.length >= 4} // Limit to 4 images
            className="bg-blue-500 text-white p-2 rounded-lg transition duration-200"
          >
            Add Image
          </button>

          {showCropper && (
            <CropperEventImage
              onCropComplete={handleImageCrop}
              onClose={() => setShowCropper(false)}
              usedCropOptions={usedCropOptions}
            />
          )}

          {/* Sponsors */}
          <div className="mb-6">
            <label className="block text-lg font-semibold mb-2">
              Select Sponsors
            </label>
            {sponsors.map((sponsor) => (
              <label
                key={sponsor.SponsorId}
                className="flex items-center space-x-2"
              >
                <input
                  type="checkbox"
                  value={sponsor.SponsorId}
                  checked={formData.SponsorIds.includes(sponsor.SponsorId)}
                  onChange={handleSponsorChange}
                />
                <span>{sponsor.SponsorName}</span>
              </label>
            ))}
          </div>

          {/* FAQs */}
          <div className="mb-6">
            <label className="block text-lg font-semibold mb-2">FAQs</label>
            {formData.FAQ.map((faq, index) => (
              <div key={index} className="flex space-x-4 mb-2">
                <input
                  type="text"
                  placeholder="Question"
                  value={faq.question}
                  onChange={(e) =>
                    handleFAQChange(index, "question", e.target.value)
                  }
                  className="w-1/2 p-2 border rounded"
                />
                <input
                  type="text"
                  placeholder="Answer"
                  value={faq.answer}
                  onChange={(e) =>
                    handleFAQChange(index, "answer", e.target.value)
                  }
                  className="w-1/2 p-2 border rounded"
                />
                <button type="button" onClick={() => removeFAQ(index)}>
                  <FaTrash />
                </button>
              </div>
            ))}
            <button type="button" onClick={addFAQ} className="text-blue-500">
              <FaPlus /> Add FAQ
            </button>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-3 rounded-lg"
          >
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditCoachingOverlay;
