import React, { useState, useEffect } from "react";
import {
  createReward,
  fetchRewardSponsors,
  fetchMilestones,
  uploadEventImage,
  downloadFile,
} from "../../api/api"; // Replace with actual API functions
import { useNavigate } from "react-router-dom";
import ToastNotification from "../../components/ToastNotification";
import CropperRewardImage from "../../components/CropperRewardImage";

const CreateRewards = () => {
  const [formData, setFormData] = useState({
    RewardType: "",
    RewardName: "",
    RewardDescription: "",
    RewardSponsorId: "",
    RewardImageFileId: "",
    MilestoneId: "",
    CouponCode: "",
    RewardExpiry: "",
    Terms: "",
  });

  const [showCropper, setShowCropper] = useState(false);
  const [sponsors, setSponsors] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [toast, setToast] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);
 const navigate = useNavigate()
  // Fetch sponsors and milestones on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const sponsorData = await fetchRewardSponsors();
        setSponsors(sponsorData);
      } catch (error) {
        handleError(error, "Failed to fetch sponsors.");
      }

      try {
        const milestoneData = await fetchMilestones();
        setMilestones(milestoneData);
      } catch (error) {
        handleError(error, "Failed to fetch milestones.");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchImage = async () => {
      if (formData.RewardImageFileId) {
        try {
          const imageUrl = await downloadFile(formData.RewardImageFileId);
          setImagePreviewUrl(imageUrl);
        } catch (error) {
          console.error("Error fetching image:", error);
        }
      }
    };

    fetchImage();
  }, [formData.RewardImageFileId]);

  const handleError = (error, defaultMessage) => {
    const status = error?.response?.status;
    if (status === 404) {
      setToast({ message: "Data not found.", type: "error" });
    } else {
      setToast({
        message: defaultMessage || "Something went wrong.",
        type: "error",
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCropComplete = async (croppedBlob) => {
    try {
      setLoading(true);
  
      const imageFormData = new FormData();
      imageFormData.append("data", croppedBlob);
      imageFormData.append("alt_text", "Reward Sponsor Image"); // Required by API
  
      const response = await uploadEventImage(imageFormData);
      if (response && response.FileId) {
        setFormData((prev) => ({
          ...prev,
          RewardImageFileId: response.FileId, // Store only FileId, not URL
        }));
  
        // Fetch and set preview URL separately
        const imageUrl = await downloadFile(response.FileId);
        setImagePreviewUrl(imageUrl);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      setToast({ message: "Image upload failed. Please try again.", type: "error" });
    } finally {
      setLoading(false);
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const adjustedFormData = {
      ...formData,
      RewardExpiry: new Date(formData.RewardExpiry).toISOString(), // Convert date to ISO format
    };

    try {
      await createReward(adjustedFormData); // Submit form data to API
      setToast({ message: "Reward created successfully!", type: "success" });
      setFormData({
        RewardType: "",
        RewardName: "",
        RewardDescription: "",
        RewardSponsorId: "",
        RewardImageFileId: "",
        MilestoneId: "",
        CouponCode: "",
        RewardExpiry: "",
        Terms: "",
      });

      setTimeout(() => navigate("/rewards"), 1500);
    } catch (error) {
      handleError(error, "Failed to create reward.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
        Create Reward
      </h2>

      {toast && (
        <ToastNotification
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Reward Type */}
        <div>
          <label
            htmlFor="RewardType"
            className="block text-lg font-semibold mb-2"
          >
            Reward Type
          </label>
          <input
            type="text"
            name="RewardType"
            id="RewardType"
            value={formData.RewardType}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            placeholder="Enter reward type (e.g., Discount, Cashback)"
            required
          />
        </div>

        {/* Reward Name */}
        <div>
          <label
            htmlFor="RewardName"
            className="block text-lg font-semibold mb-2"
          >
            Reward Name
          </label>
          <input
            type="text"
            name="RewardName"
            id="RewardName"
            value={formData.RewardName}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            placeholder="Enter reward name"
            required
          />
        </div>

        {/* Reward Description */}
        <div>
          <label
            htmlFor="RewardDescription"
            className="block text-lg font-semibold mb-2"
          >
            Reward Description
          </label>
          <textarea
            name="RewardDescription"
            id="RewardDescription"
            value={formData.RewardDescription}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            placeholder="Enter reward description"
            required
          ></textarea>
        </div>

        <div>
          <label className="block text-lg font-semibold mb-2">
            Reward Image
          </label>

          {/* Image Preview (if uploaded) */}
          {imagePreviewUrl ? (
            <div className="mb-4">
              <img
                src={imagePreviewUrl}
                alt="Uploaded Reward"
                className="w-40 h-20 object-cover rounded shadow-lg border"
              />
            </div>
          ) : (
            <p className="text-gray-500 mb-2">No image uploaded.</p>
          )}

          {/* Upload Button */}
          <button
            type="button"
            onClick={() => setShowCropper(true)}
            className="text-blue-500 font-medium px-4 py-2 rounded hover:bg-blue-100 border-[2px] border-blue-500"
          >
            {formData.RewardImageFileId ? "Change Image" : "Upload Image"}
          </button>

          {formData.RewardImageFileId && (
            <p className="text-green-500 mt-2">Image uploaded successfully!</p>
          )}
        </div>

        {/* Sponsor Dropdown */}
        <div>
          <label
            htmlFor="RewardSponsorId"
            className="block text-lg font-semibold mb-2"
          >
            Reward Sponsor
          </label>
          <select
            name="RewardSponsorId"
            id="RewardSponsorId"
            value={formData.RewardSponsorId}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            required
          >
            <option value="">Select a Sponsor</option>
            {sponsors.map((sponsor) => (
              <option
                key={sponsor.RewardSponsorId}
                value={sponsor.RewardSponsorId}
              >
                {sponsor.RewardSponsorName}
              </option>
            ))}
          </select>
        </div>

        {/* Milestone Dropdown */}
        <div>
          <label
            htmlFor="MilestoneId"
            className="block text-lg font-semibold mb-2"
          >
            Milestone
          </label>
          <select
            name="MilestoneId"
            id="MilestoneId"
            value={formData.MilestoneId}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            required
          >
            <option value="">Select a Milestone</option>
            {milestones.map((milestone) => (
              <option key={milestone.MilestoneId} value={milestone.MilestoneId}>
                {milestone.MilestoneName}
              </option>
            ))}
          </select>
        </div>

        {/* Coupon Code */}
        <div>
          <label
            htmlFor="CouponCode"
            className="block text-lg font-semibold mb-2"
          >
            Coupon Code
          </label>
          <input
            type="text"
            name="CouponCode"
            id="CouponCode"
            value={formData.CouponCode}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            placeholder="Enter coupon code"
            required
          />
        </div>

        <div>
          <label className="block text-lg font-semibold mb-2">
            Reward Expiry Date
          </label>
          <input
            type="date"
            name="RewardExpiry"
            value={formData.RewardExpiry}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          />
        </div>

        <div>
          <label htmlFor="Terms" className="block text-lg font-semibold mb-2">
            Terms & Conditions
          </label>
          <textarea
            name="Terms"
            id="Terms"
            value={formData.Terms}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            placeholder="Enter terms and conditions"
            required
          ></textarea>
        </div>

        {/* Submit Button */}
        <div>
          <button
            type="submit"
            className={`w-full px-4 py-3 text-white rounded ${
              loading ? "bg-gray-500" : "bg-blue-500 hover:bg-blue-600"
            }`}
            disabled={loading}
          >
            {loading ? "Create Reward" : "Create Reward"}
          </button>
        </div>
      </form>
      {/* Cropper Modal */}
      {showCropper && (
        <CropperRewardImage
          onCropComplete={handleCropComplete}
          onClose={() => setShowCropper(false)}
        />
      )}
    </div>
  );
};

export default CreateRewards;
