import React, { useState } from "react";
import {
  createRewardSponsor,
  uploadEventImage,
  downloadFile,
} from "../../api/api";
import CropperSponsorImage from "../../components/CropperRewardSponsorImage";

const CreateRewardSponsorForm = () => {
  const [formData, setFormData] = useState({
    RewardSponsorType: "",
    RewardSponsorName: "",
    RewardSponsorImageFileId: "",
    IsActive: true,
  });

  const [showCropper, setShowCropper] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleCropComplete = async (croppedBlob) => {
    try {
      setImageUploading(true);
      setError(null);

      const imageFormData = new FormData();
      imageFormData.append("data", croppedBlob); // Change "file" to "data"
      imageFormData.append("alt_text", "Reward Sponsor Image"); // Add required alt_text

      const response = await uploadEventImage(imageFormData); // Upload image
      if (response && response.FileId) {
        setFormData((prev) => ({
          ...prev,
          RewardSponsorImageFileId: response.FileId, // Store the uploaded file ID
        }));

        const imageUrl = await downloadFile(response.FileId);
        setImagePreviewUrl(imageUrl);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      setError("Failed to upload image. Please try again.");
    } finally {
      setImageUploading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      await createRewardSponsor(formData);
      console.log("Reward sponsor data", formData);
      setSuccessMessage("Reward sponsor created successfully!");
      setFormData({
        RewardSponsorType: "",
        RewardSponsorName: "",
        RewardSponsorImageFileId: "",
        IsActive: true,
      });
    } catch (error) {
      console.error("Error creating reward sponsor:", error);
      setError("Failed to create reward sponsor. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
        Create Reward Sponsor
      </h2>

      {error && <p className="text-red-500 text-center">{error}</p>}
      {successMessage && (
        <p className="text-green-500 text-center">{successMessage}</p>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Reward Sponsor Type */}
        <div>
          <label
            htmlFor="RewardSponsorType"
            className="block text-lg font-semibold mb-2"
          >
            Reward Sponsor Type
          </label>
          <input
            type="text"
            name="RewardSponsorType"
            id="RewardSponsorType"
            value={formData.RewardSponsorType}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            placeholder="Enter the type (e.g., Corporate, Individual)"
            required
          />
        </div>

        {/* Reward Sponsor Name */}
        <div>
          <label
            htmlFor="RewardSponsorName"
            className="block text-lg font-semibold mb-2"
          >
            Reward Sponsor Name
          </label>
          <input
            type="text"
            name="RewardSponsorName"
            id="RewardSponsorName"
            value={formData.RewardSponsorName}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            placeholder="Enter sponsor name"
            required
          />
        </div>

        {/* Sponsor Image */}
        <div>
          <label className="block text-lg font-semibold mb-2">
            Sponsor Image
          </label>

          {/* Image Preview (if uploaded) */}
          {imagePreviewUrl ? (
            <div className="mb-4">
              <img
                src={imagePreviewUrl}
                alt="Uploaded Sponsor"
                className="w-40 h-20 object-cover rounded shadow-lg border"
              />
            </div>
          ) : (
            <p className="text-gray-500 mb-2">No image uploaded.</p>
          )}

          {/* Upload Button */}
          <button
            type="button"
            onClick={() => setShowCropper(true)}
            className= {`text-blue-500 font-medium px-4 py-2 rounded ${
              imageUploading ? "bg-gray-100 text-gray-600 border-gray-600" : " hover:bg-blue-100"
            } hover:bg-blue-100 border-[2px] border-blue-500`}
          >
            {formData.RewardSponsorImageFileId
              ? "Change Image"
              : "Upload Image"}
          </button>

          {formData.RewardSponsorImageFileId && (
            <p className="text-green-500 mt-2">Image uploaded successfully!</p>
          )}
        </div>

        {/* Is Active Checkbox */}
        <div>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              name="IsActive"
              checked={formData.IsActive}
              onChange={handleInputChange}
              className="w-5 h-5 text-blue-500 border-gray-300 rounded focus:ring-blue-500"
            />
            <span className="text-lg font-semibold">Is Active</span>
          </label>
        </div>

        {/* Submit Button */}
        <div>
          <button
            type="submit"
            className={`w-full px-4 py-3 text-white rounded ${
              loading ? "bg-gray-500" : "bg-blue-500 hover:bg-blue-600"
            }`}
            disabled={loading}
          >
            {loading ? "Submitting..." : "Create Reward Sponsor"}
          </button>
        </div>
      </form>

      {/* Cropper Modal */}
      {showCropper && (
        <CropperSponsorImage
          onCropComplete={handleCropComplete}
          onClose={() => setShowCropper(false)}
        />
      )}
    </div>
  );
};

export default CreateRewardSponsorForm;
