import React, { useEffect, useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { fetchCoachingEventDetails } from "../api/api";
import moment from "moment";
const locales = {
  "en-US": require("date-fns/locale/en-US"),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const CalendarView = ({ coachingId }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSlot, setSelectedSlot] = useState(null); // To track selected slot for overlay

  useEffect(() => {
    const fetchAllocations = async () => {
      try {
        const data = await fetchCoachingEventDetails(coachingId);
        const allocations = formatAllocationsForCalendar(
          data.CoachAllocationDetails
        );
        setEvents(allocations);
      } catch (error) {
        console.error("Error fetching coaching details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllocations();
  }, [coachingId]);

  const formatAllocationsForCalendar = (allocations) => {
    return allocations.map((allocation) => {
      const localStart = moment.utc(allocation.StartTimeStamp).local().toDate();
      const localEnd = moment(localStart)
        .add(allocation.Duration, "minutes")
        .toDate();

      return {
        id: allocation.CoachingAllocationId,
        title: `Learners: ${allocation.Learners}`,
        start: localStart, // Converted to local time
        end: localEnd, // Converted to local time
        allDay: false,
        details: allocation,
      };
    });
  };

  // Handle slot click to show modal
  const handleSlotClick = (event) => {
    setSelectedSlot(event);
  };

  // Close the modal
  const closeModal = () => {
    setSelectedSlot(null);
  };

  if (loading) {
    return <p>Loading calendar...</p>;
  }

  return (
    <div className="p-4 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Coaching Allocations</h2>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 600 }}
        onSelectEvent={handleSlotClick} // Handle slot click
        eventPropGetter={(event, start, end, isSelected) => {
          return {
            style: {
              backgroundColor: "#512D9A",
              color: "#fff",
              borderRadius: "5px",
              padding: "5px",
              transition: "background-color 0.3s ease-in-out",
            },
            className: "custom-calendar-event",
          };
        }}
      />

      <style>
        {`
    .custom-calendar-event:hover {
      background-color: #3A1D6E 
      cursor: pointer;
    }
  `}
      </style>

      {/* Slot Details Modal */}
      {selectedSlot && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
            <h3 className="text-xl font-semibold text-gray-900 mb-4">
              Slot Details
            </h3>
            <p>
              <strong>Venue:</strong>{" "}
              {selectedSlot.details.VenueDetails.VenueName || "N/A"}
            </p>
            <p>
              <strong>Start Date:</strong>{" "}
              {moment
                .utc(selectedSlot.details.StartTimeStamp)
                .local()
                .format("YYYY-MM-DD")}
            </p>
            <p>
              <strong>Start Time:</strong>{" "}
              {moment
                .utc(selectedSlot.details.StartTimeStamp)
                .local()
                .format("hh:mm A")}
            </p>

            <p>
              <strong>Duration:</strong> {selectedSlot.details.Duration} mins
            </p>
            <p>
              <strong>Price:</strong> ₹{selectedSlot.details.Price || "N/A"}
            </p>
            <p>
              <strong>Price (Credits):</strong>{" "}
              {selectedSlot.details.PriceCredits || "N/A"}
            </p>
            <p>
              <strong>Number of Learners:</strong>{" "}
              {selectedSlot.details.Learners}
            </p>

            <button
              onClick={closeModal}
              className="mt-4 w-full bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarView;
