import React, { useState } from 'react'
import {useLocation} from 'react-router-dom'
import CreateEventGame from './CreateEventGame';
import { FaPlus, FaTrashAlt, FaFilter, FaTimes } from "react-icons/fa";
import Loader from "../../components/Loader";


const EventGames = () => {
  const location = useLocation();
  const {eventData} = location.state;
  const [isCreatingGame, setIsCreatingGame] = useState(false)
  console.log(eventData)


  const handleCreateGame = () => {
    setIsCreatingGame(true); // Enable editing when the Edit button is clicked
  };

  // Handling Cancel Edit
  const handleCancelCreateGame = () => {
    setIsCreatingGame(false); // Disable editing when cancel button is clicked
  };


  return (
    <div className='bg-gray-100 min-h-screen p-6'>
      <div className="flex justify-between items-center mb-6">
              {/* Heading */}
              <h1 className="text-3xl font-bold sm:text-4xl text-gray-800">
                All Games
              </h1>
      
              {/* Create Game Button */}
              <button className="bg-gradient-to-r from-purple-500 to-indigo-600 text-white p-3 rounded-lg shadow-lg hover:bg-indigo-700 flex items-center justify-center sm:w-auto w-12 h-12"
              onClick={handleCreateGame}
              
              >
              <FaPlus size={20} className="sm:hidden" />
                <span className="hidden sm:inline-block ml-2 text-base sm:text-lg">
                  Create Game
                </span>

              </button>                
            </div>

            {/* Edit Event Form Overlay */}
      {isCreatingGame && (
        <CreateEventGame
          eventData={eventData}
          onCancel={handleCancelCreateGame}
        />
      )}
    </div>
  )
}

export default EventGames
