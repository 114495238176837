import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  fetchCoachingMembers,
  fetchUserById,
  fetchCoachingWaitlist,
  fetchCoachingRazorpayDetails,
  fetchCoachAllocationDetails,
} from "../../api/api";

import * as XLSX from "xlsx";

const CoachingMembers = () => {
  const { coachOfferingId } = useParams();
  const [tab, setTab] = useState("members");
  const [coachingMembers, setCoachingMembers] = useState({});
  const [waitlist, setWaitlist] = useState([]);
  const [userProfiles, setUserProfiles] = useState({});
  const [razorpayData, setRazorpayData] = useState({});
  const [allocations, setAllocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loadingWaitlist, setLoadingWaitlist] = useState(false);
  const [waitlistError, setWaitlistError] = useState(null);

  const fetchedRazorpayIds = useRef(new Set()); 

  useEffect(() => {
    if (!coachOfferingId) return;

    const fetchAllData = async () => {
      setLoading(true);
      try {
        const allocationData = await fetchCoachAllocationDetails(
          coachOfferingId
        );
        setAllocations(allocationData);

        const allocationIds = allocationData.flatMap((venue) =>
          venue.CoachingAllocation.map(
            (allocation) => allocation.CoachingAllocationId
          )
        );

        const members = await fetchCoachingMembers(coachOfferingId);
        const groupedMembers = members.reduce((acc, member) => {
          acc[member.CoachingAllocationId] =
            acc[member.CoachingAllocationId] || [];
          acc[member.CoachingAllocationId].push(member);
          return acc;
        }, {});

        setCoachingMembers(groupedMembers);
        setError(null);

        fetchRazorpayData();
      } catch (err) {
        console.error("Failed to fetch coaching members:", err);
        setError("No coaching members found.");
        setCoachingMembers({});
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [coachOfferingId]);

  const fetchRazorpayData = async () => {
    try {
      const data = await fetchCoachingRazorpayDetails(coachOfferingId);

      const mappedData = {};
      data.forEach((allocation) => {
        allocation.UserList.forEach((user) => {
          mappedData[user.UserId] = {
            RazorpayOrderId: user.RazorpayOrderId,
            SubOrderId: user.SubOrderId,
            Amount: user.Amount,
          };
        });
      });

      setRazorpayData(mappedData);
    } catch (error) {
      console.error("Error fetching Razorpay details:", error);
    }
  };
  useEffect(() => {
    if (!coachingMembers || Object.keys(coachingMembers).length === 0) return;
  
    const fetchUserProfiles = async () => {
      const profiles = {};
      const usersToFetch = new Set(); 
  
      Object.values(coachingMembers)
        .flat()
        .forEach((member) => {
          if (!userProfiles[member.UserId] && !usersToFetch.has(member.UserId)) {
            usersToFetch.add(member.UserId); 
          }
        });
  
      if (usersToFetch.size === 0) return; 
  
      await Promise.all(
        [...usersToFetch].map(async (userId) => {
          try {
            const profile = await fetchUserById(userId);
            profiles[userId] = profile;
          } catch (err) {
            profiles[userId] = null; 
          }
        })
      );
  
      setUserProfiles((prev) => ({ ...prev, ...profiles })); 
    };
  
    fetchUserProfiles();
  }, [coachingMembers]); 
  

  useEffect(() => {
    if (!coachOfferingId) return;

    const fetchWaitlistData = async () => {
      setLoadingWaitlist(true);
      try {
        const response = await fetchCoachingWaitlist(coachOfferingId);
        setWaitlist(Array.isArray(response) ? response : []);
        setWaitlistError(null);
      } catch (err) {
        console.error("Failed to fetch waitlist:", err);
        setWaitlistError("No waitlist entries available.");
        setWaitlist([]);
      } finally {
        setLoadingWaitlist(false);
      }
    };

    fetchWaitlistData();
  }, [coachOfferingId]);

  const exportToExcel = (data, filename) => {
    if (!data || data.length === 0) {
      alert("No data available for export.");
      return;
    }

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, filename);
    XLSX.writeFile(wb, `${filename}.xlsx`);
  };

  return (
    <div className="p-4 bg-white shadow rounded">
      <h1 className="text-xl font-bold mb-4">Coaching Registration Details</h1>

      {/* Tabs for Members & Waitlist */}
      <div className="flex border-b border-gray-200">
        <button
          className={`py-2 px-4 ${
            tab === "members" ? "border-b-2 border-blue-500 text-blue-500" : ""
          }`}
          onClick={() => setTab("members")}
        >
          Coaching Members
        </button>
        <button
          className={`py-2 px-4 ${
            tab === "waitlist" ? "border-b-2 border-blue-500 text-blue-500" : ""
          }`}
          onClick={() => setTab("waitlist")}
        >
          Waitlist
        </button>
      </div>

      <div className="mt-4">
        {tab === "members" ? (
          loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : (
            <div>
              {/* Export Button for Coaching Members */}
              <button
                className="px-4 py-2 bg-green-500 text-white rounded mb-4"
                onClick={() => {
                  const membersData = Object.keys(coachingMembers).flatMap(
                    (allocationId) =>
                      coachingMembers[allocationId].map((member) => ({
                        "Slot ID": allocationId,
                        "User ID": member.UserId,
                        Name: userProfiles[member.UserId]?.User
                          ? `${
                              userProfiles[member.UserId]?.User?.FirstName ||
                              "N/A"
                            } ${
                              userProfiles[member.UserId]?.User?.LastName || ""
                            }`.trim()
                          : "N/A",
                        Email:
                          userProfiles[member.UserId]?.User?.EmailAddress ||
                          "N/A",
                        "Razorpay Order ID":
                          razorpayData[member.UserId]?.RazorpayOrderId || "N/A",
                        "Sub Order ID":
                          razorpayData[member.UserId]?.SubOrderId || "N/A",
                        Amount:
                          razorpayData[member.UserId]?.Amount !== undefined
                            ? `₹${razorpayData[member.UserId]?.Amount}`
                            : "N/A",
                      }))
                  );
                  exportToExcel(membersData, "CoachingMembers");
                }}
              >
                Export Coaching Members
              </button>

              <table className="w-full border border-gray-300">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="border px-4 py-2">Slot ID</th>
                    <th className="border px-4 py-2">User ID</th>
                    <th className="border px-4 py-2">Name</th>
                    <th className="border px-4 py-2">Email</th>
                    <th className="border px-4 py-2">Razorpay Order ID</th>
                    <th className="border px-4 py-2">Sub Order ID</th>
                    <th className="border px-4 py-2">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(coachingMembers).map((allocationId) => {
                    const allocation = allocations
                      .flatMap((venue) => venue.CoachingAllocation)
                      .find(
                        (alloc) => alloc.CoachingAllocationId === allocationId
                      );

                    return (
                      <React.Fragment key={allocationId}>
                        {/* Group Header */}
                        <tr className="bg-gray-200">
                          <td
                            className="border px-4 py-2 font-bold"
                            colSpan="8"
                          >
                            Coach:{" "}
                            {allocation?.CoachDetails?.CoachName || "Unknown"} |
                            Venue:{" "}
                            {allocations.find((v) =>
                              v.CoachingAllocation.some(
                                (a) => a.CoachingAllocationId === allocationId
                              )
                            )?.VenueName || "Unknown"}{" "}
                            | Slot:{" "}
                            {allocation?.Slot
                              ? new Date(allocation.Slot).toLocaleString(
                                  "en-IN",
                                  {
                                    weekday: "long",
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  }
                                )
                              : "Unknown"}
                          </td>
                        </tr>

                        {(coachingMembers[allocationId] || []).map((member) => (
                          <tr key={member.UserId}>
                            <td className="border px-4 py-2">{allocationId}</td>
                            <td className="border px-4 py-2">
                              {member.UserId}
                            </td>
                            <td className="border px-4 py-2">
                              {userProfiles[member.UserId]?.User
                                ? `${
                                    userProfiles[member.UserId]?.User
                                      ?.FirstName || "N/A"
                                  } ${
                                    userProfiles[member.UserId]?.User
                                      ?.LastName || ""
                                  }`.trim()
                                : "N/A"}
                            </td>
                            <td className="border px-4 py-2">
                              {userProfiles[member.UserId]?.User
                                ?.EmailAddress || "N/A"}
                            </td>
                            <td className="border px-4 py-2">
                              {razorpayData[member.UserId]?.RazorpayOrderId ||
                                "N/A"}
                            </td>
                            <td className="border px-4 py-2">
                              {razorpayData[member.UserId]?.SubOrderId || "N/A"}
                            </td>
                            <td className="border px-4 py-2">
                              {razorpayData[member.UserId]?.Amount !== undefined
                                ? `₹${razorpayData[member.UserId]?.Amount}`
                                : "N/A"}
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )
        ) : loadingWaitlist ? (
          <p>Loading waitlist...</p>
        ) : waitlistError ? (
          <p className="text-red-500">{waitlistError}</p>
        ) : (
          <div>
            {/* Export Button for Waitlist */}
            <button
              className="px-4 py-2 bg-green-500 text-white rounded mb-4"
              onClick={() => {
                const waitlistData = waitlist.map((member) => ({
                  "User ID": member.UserId,
                  Name: userProfiles[member.UserId]?.User
                    ? `${
                        userProfiles[member.UserId]?.User?.FirstName || "N/A"
                      } ${
                        userProfiles[member.UserId]?.User?.LastName || ""
                      }`.trim()
                    : "N/A",
                  Email:
                    userProfiles[member.UserId]?.User?.EmailAddress || "N/A",
                }));
                exportToExcel(waitlistData, "Waitlist");
              }}
            >
              Export Waitlist
            </button>

            <table className="w-full border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border px-4 py-2">User ID</th>
                  <th className="border px-4 py-2">Name</th>
                  <th className="border px-4 py-2">Email</th>
                </tr>
              </thead>
              <tbody>
                {(Array.isArray(waitlist) ? waitlist : []).map((member) => (
                  <tr key={member.UserId}>
                    <td className="border px-4 py-2">{member.UserId}</td>
                    <td className="border px-4 py-2">
                      {userProfiles[member.UserId]?.User
                        ? `${
                            userProfiles[member.UserId]?.User?.FirstName ||
                            "N/A"
                          } ${
                            userProfiles[member.UserId]?.User?.LastName || ""
                          }`.trim()
                        : "N/A"}
                    </td>
                    <td className="border px-4 py-2">
                      {userProfiles[member.UserId]?.User?.EmailAddress || "N/A"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default CoachingMembers;
