import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  fetchEventMembers,
  fetchRazorpayDetails,
  fetchWaitlist,
  fetchUserById,
  fetchOrderById,
} from "../../api/api";
import * as XLSX from "xlsx";

const RegistrationDetails = () => {
  const { eventId } = useParams();
  const [tab, setTab] = useState("members");
  const [eventMembers, setEventMembers] = useState([]);
  const [groupedMembers, setGroupedMembers] = useState({});
  const [waitlist, setWaitlist] = useState([]);
  const [userProfiles, setUserProfiles] = useState({});
  const [orderTimestamps, setOrderTimestamps] = useState({});
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [loadingWaitlist, setLoadingWaitlist] = useState(false);
  const [membersError, setMembersError] = useState("");
  const [waitlistError, setWaitlistError] = useState("");
  const [razorpayData, setRazorpayData] = useState({});

  // Fetch Razorpay Details
  useEffect(() => {
    const fetchRazorpayData = async () => {
      try {
        const data = await fetchRazorpayDetails(eventId);
        const mappedData = data.UserList.reduce((acc, user) => {
          acc[user.UserId] = {
            RazorpayOrderId: user.RazorpayOrderId,
            SubOrderId: user.SubOrderId,
          };
          return acc;
        }, {});
        setRazorpayData(mappedData);
      } catch (error) {
        console.error("Error fetching Razorpay details:", error);
      }
    };

    if (eventId) {
      fetchRazorpayData();
    }
  }, [eventId]);

  useEffect(() => {
    if (!eventId) {
      setMembersError("Event ID is missing.");
      return;
    }

    const fetchMembers = async () => {
      try {
        setLoadingMembers(true);

        // Fetch members for the specific eventId
        const members = await fetchEventMembers(eventId);

        // Group members by OrderId
        const grouped = members.reduce((acc, member) => {
          acc[member.OrderId] = acc[member.OrderId] || [];
          acc[member.OrderId].push(member);
          return acc;
        }, {});

        setGroupedMembers(grouped);
        setEventMembers(members);
        setMembersError(null); // Clear any previous errors
      } catch (error) {
        if (error.response?.status === 404) {
          setEventMembers([]); // Clear event members on 404
          setGroupedMembers({}); // Clear grouped members on 404
          setMembersError(null); // Do not show any error message for 404
        } else {
          setEventMembers([]); // Clear event members on other errors
          setGroupedMembers({}); // Clear grouped members on other errors
          setMembersError("Failed to fetch event members."); // Generic error message
        }
      } finally {
        setLoadingMembers(false);
      }
    };

    fetchMembers();
  }, [eventId]);

  useEffect(() => {
    const fetchOrderTimestamps = async () => {
      const timestamps = {};
      for (const member of eventMembers) {
        const orderId = member.OrderId;
        if (!timestamps[orderId]) {
          try {
            const orderData = await fetchOrderById(orderId);
            timestamps[orderId] = orderData?.CreatedAtTimeStamp || "N/A";
          } catch (error) {
            timestamps[orderId] = "Error";
          }
        }
      }
      setOrderTimestamps(timestamps);
    };

    if (eventMembers.length > 0) {
      fetchOrderTimestamps();
    }
  }, [eventMembers]);

  // Fetch User Profiles for Members
  useEffect(() => {
    const fetchProfiles = async () => {
      const profiles = {};
      for (const member of eventMembers) {
        if (!userProfiles[member.UserId]) {
          try {
            const profile = await fetchUserById(member.UserId);
            profiles[member.UserId] = profile;
          } catch (error) {
            if (error.response?.status === 404) {
              console.warn(`UserId not found: ${member.UserId}`);
              profiles[member.UserId] = null; // Mark as not found
            } else {
              console.error(
                `Failed to fetch profile for UserId: ${member.UserId}`,
                error
              );
            }
          }
        }
      }
      setUserProfiles((prev) => ({ ...prev, ...profiles }));
    };

    fetchProfiles();
  }, [eventMembers]);

  // Fetch Waitlist
  useEffect(() => {
    const fetchWaitlistData = async () => {
        try {
            setLoadingWaitlist(true);
            
            const waitlistResponse = await fetchWaitlist(eventId);
            
            const waitlistData = waitlistResponse.items || []; 

            if (waitlistData.length === 0) {
                setWaitlist([]);
                setWaitlistError("No waitlist data available.");
            } else {
                setWaitlist(waitlistData);
                setWaitlistError(null); // Clear previous errors
            }
        } catch (error) {
            console.error("Error fetching waitlist:", error); 
            setWaitlist([]); 
            setWaitlistError(
                error.response?.status === 404
                    ? "No waitlist found for this event."
                    : "Failed to fetch waitlist."
            );
        } finally {
            setLoadingWaitlist(false);
        }
    };

    if (eventId) {
        fetchWaitlistData();
    }
}, [eventId]);


const handleExportMembers = () => {
  const data = [];

  Object.keys(groupedMembers).forEach((orderId) => {
      const orderMembers = groupedMembers[orderId];

      if (orderMembers.length === 0) return;

      const firstMember = orderMembers[0]; // Get first member for timestamps & order info
      const createdAt = orderTimestamps[firstMember.OrderId]
          ? new Date(orderTimestamps[firstMember.OrderId]).toISOString().replace("T", " ").slice(0, 19)
          : "N/A";
      
      const razorpayInfo = razorpayData[firstMember.UserId] || {};

      // Start constructing a single row per OrderID
      let row = {
          OrderID: firstMember.OrderId,
          CreatedAt: createdAt,
      };

      // Add all members in the row dynamically
      orderMembers.forEach((member, index) => {
          const profile = userProfiles[member.UserId];

          if (profile) {
              row[`Player${index + 1}_Name`] = `${profile.User.FirstName} ${profile.User.LastName}`;
              row[`Player${index + 1}_Mobile`] = profile.User.MobileNumber || "N/A";
              row[`Player${index + 1}_Email`] = profile.User.EmailAddress || "N/A";
              row[`Player${index + 1}_Gender`] = profile.User.Gender || "N/A";
          }
      });

      // Append Razorpay Order details at the end
      row["RazorpayOrderID"] = razorpayInfo.RazorpayOrderId || "N/A";
      row["SubOrderID"] = razorpayInfo.SubOrderId || "N/A";

      data.push(row);
  });

  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, `Event Members`);
  XLSX.writeFile(workbook, `EventMembers.xlsx`);
};


  // Export Waitlist to Excel
  const handleExportWaitlist = () => {
    const data = [];

    waitlist.forEach((item) => {
        const team = item?.OrderData?.Team || [];
        const createdAt = new Date(item.CreatedAtTimeStamp).toISOString().replace("T", " ").slice(0, 19);

        // Start constructing a single row per OrderID
        let row = {
            WaitlistID: item.waitlistId,
            CreatedAt: createdAt,
            OrderAmount: item.OrderAmount,
            OrderStatus: item.OrderStatus
        };

        // Add all members in the row dynamically
        team.forEach((member, index) => {
            row[`Player${index + 1}_Name`] = `${member.FirstName} ${member.LastName}`;
            row[`Player${index + 1}_Mobile`] = member.Phone || "N/A";
            row[`Player${index + 1}_Email`] = member.Email || "N/A";
            row[`Player${index + 1}_Gender`] = member.Gender || "N/A";
        });

        data.push(row);
    });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `Waitlist`);
    XLSX.writeFile(workbook, `EventWaitlist.xlsx`);
};

  const renderTabContent = () => {
    if (tab === "members") {
      if (loadingMembers) {
        return <div>Loading event members...</div>;
      }

      if (membersError) {
        return <div className="text-red-500">{membersError}</div>;
      }

      if (!eventMembers || eventMembers.length === 0) {
        return <div>No event members available</div>;
      }

      return (
        <div>
          <button
            onClick={handleExportMembers}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 mb-4"
          >
            Export to Excel
          </button>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white rounded-lg shadow-lg overflow-hidden">
              <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  {/* <th className="py-3 px-6 text-left">Order ID</th> */}
                  <th className="py-3 px-6 text-left">Created At</th>
                  <th className="py-3 px-6 text-left">User ID</th>
                  <th className="py-3 px-6 text-left">Name</th>
                  <th className="py-3 px-6 text-left">Mobile</th>
                  <th className="py-3 px-6 text-left">Email</th>
                  <th className="py-3 px-6 text-left">Gender</th>
                  <th className="py-3 px-6 text-left">Razorpay Order ID</th>
                  <th className="py-3 px-6 text-left">Sub Order ID</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {Object.keys(groupedMembers).map((orderId) => {
                  const orderMembers = groupedMembers[orderId];

                  return (
                    <React.Fragment key={orderId}>
                      {/* Order Row */}
                      <tr className="bg-gray-100 font-semibold">
                        <td className="py-3 px-6 text-left" colSpan="7">
                          Order ID: {orderId}
                        </td>
                      </tr>

                      {/* Member Rows */}
                      {orderMembers.map((member) => {
                        const profile = userProfiles[member.UserId];
                        const razorpayInfo = razorpayData[member.UserId] || {};
                        return (
                          <tr
                            key={member.UserId}
                            className="border-b border-gray-200 hover:bg-gray-100"
                          >
                            <td className="py-3 px-6 text-left">
                              {orderTimestamps[member.OrderId]
                                ? new Date(orderTimestamps[member.OrderId])
                                    .toISOString()
                                    .replace("T", " ")
                                    .slice(0, 19)
                                : "Loading..."}
                            </td>
                            <td className="py-3 px-6 text-left whitespace-nowrap">
                              {member.UserId}
                            </td>
                            <td className="py-3 px-6 text-left font-medium">
                              {profile
                                ? `${profile.User.FirstName} ${profile.User.LastName}`
                                : "Loading..."}
                            </td>
                            <td className="py-3 px-6 text-left">
                              {profile
                                ? `${profile.User.MobileNumber}`
                                : "Loading..."}
                            </td>
                            <td className="py-3 px-6 text-left">
                              {profile
                                ? profile.User.EmailAddress
                                : "Loading..."}
                            </td>
                            <td className="py-3 px-6 text-left">
                              {profile ? profile.User.Gender : "Loading..."}
                            </td>
                            <td className="py-3 px-6 text-left">
                              {razorpayInfo.RazorpayOrderId || "N/A"}
                            </td>
                            <td className="py-3 px-6 text-left">
                              {razorpayInfo.SubOrderId || "N/A"}
                            </td>
                          </tr>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    if (tab === "waitlist") {
      if (loadingWaitlist) {
          return <div>Loading waitlist...</div>;
      }
  
      if (waitlistError) {
          return <div className="text-red-500">{waitlistError}</div>;
      }
  
      if (!waitlist || waitlist.length === 0) {
          return <div>No waitlist data available.</div>;
      }
  
      return (
          <div className="overflow-x-auto">
              <button
                  onClick={handleExportWaitlist}
                  className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 mb-4"
              >
                  Export to Excel
              </button>
              <table className="min-w-full bg-white rounded-lg shadow-lg overflow-hidden">
                  <thead>
                      <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                          <th className="py-3 px-6 text-left">Name</th>
                          <th className="py-3 px-6 text-left">Mobile</th>
                          <th className="py-3 px-6 text-left">Email</th>
                          <th className="py-3 px-6 text-left">Gender</th>
                          <th className="py-3 px-6 text-left">Order Amount</th>
                          <th className="py-3 px-6 text-left">Order Status</th>
                          <th className="py-3 px-6 text-left">Created At</th>
                      </tr>
                  </thead>
                  <tbody className="text-gray-600 text-sm font-light">
                      {waitlist.flatMap((item, index) => {
                          const team = item?.OrderData?.Team || [];
                          return team.map((member, memberIndex) => (
                              <tr
                                  key={`${index}-${memberIndex}`}
                                  className="border-b border-gray-200 hover:bg-gray-100"
                              >
                                  <td className="py-3 px-6 text-left font-medium">
                                      {`${member.FirstName} ${member.LastName}`}
                                  </td>
                                  <td className="py-3 px-6 text-left">{member.Phone}</td>
                                  <td className="py-3 px-6 text-left">{member.Email}</td>
                                  <td className="py-3 px-6 text-left">{member.Gender}</td>
                                  <td className="py-3 px-6 text-left">{item.OrderAmount}</td>
                                  <td className="py-3 px-6 text-left text-[#ff0000] font-normal">
                                      {item.OrderStatus}
                                  </td>
                                  <td className="py-3 px-6 text-left">
                                      {new Date(item.CreatedAtTimeStamp).toLocaleString()}
                                  </td>
                              </tr>
                          ));
                      })}
                  </tbody>
              </table>
          </div>
      );
  }
  };  

  return (
    <div className="p-4 bg-white shadow rounded">
      <h1 className="text-xl font-bold mb-4">Registration Details</h1>
      <div className="flex border-b border-gray-200">
        <button
          className={`py-2 px-4 ${
            tab === "members" ? "border-b-2 border-blue-500 text-blue-500" : ""
          }`}
          onClick={() => setTab("members")}
        >
          Event Members
        </button>
        <button
          className={`py-2 px-4 ${
            tab === "waitlist" ? "border-b-2 border-blue-500 text-blue-500" : ""
          }`}
          onClick={() => setTab("waitlist")}
        >
          Waitlist
        </button>
      </div>
      <div className="mt-4">{renderTabContent()}</div>
    </div>
  );
};

export default RegistrationDetails;
