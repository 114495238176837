import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUsers, updateUser } from "../../api/api";
import { FaEdit, FaInfoCircle } from "react-icons/fa";
import EditUserForm from "../../components/Users/EditUserForm";
import ToastNotification from "../../components/ToastNotification";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRole, setSelectedRole] = useState("All");
  const [editUser, setEditUser] = useState(null);
  const navigate = useNavigate();
  const [toast, setToast] = useState(null);
  const [showHints, setShowHints] = useState(false); // Toggle hints visibility

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      setError(null);

      try {
        const data = await getUsers(100, 0);
        const usersData = data?.users || [];
        setUsers(usersData);
        setFilteredUsers(usersData);
      } catch (err) {
        setError("Failed to fetch users.");
        console.error("Error fetching users:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    applyFilters(value, selectedRole);
  };

  const handleRoleFilter = (e) => {
    const role = e.target.value;
    setSelectedRole(role);
    applyFilters(searchTerm, role);
  };

  const applyFilters = (search, role) => {
    let filtered = users;

    if (role !== "All") {
      filtered = filtered.filter((user) => user.RoleType === role);
    }

    if (search) {
      filtered = filtered.filter(
        (user) =>
          `${user.FirstName} ${user.LastName}`.toLowerCase().includes(search) ||
          user.UserId.toLowerCase().includes(search) ||
          user.MobileNumber?.toLowerCase().includes(search)
      );
    }

    setFilteredUsers(filtered);
  };

  const handleRowClick = (userId) => {
    navigate(`/master/user-master/${userId}`);
  };

  const handleUpdateUser = async (updatedUser) => {
    try {
      const token = localStorage.getItem("auth")
        ? JSON.parse(localStorage.getItem("auth")).token
        : null;

      if (!token) {
        setToast({
          message: "Access token is missing. Please log in.",
          type: "error",
        });
        return;
      }

      await updateUser(updatedUser.UserId, updatedUser, token);
      setToast({ message: "User updated successfully!", type: "success" });
      setEditUser(null);
      setUsers((prev) =>
        prev.map((user) =>
          user.UserId === updatedUser.UserId ? updatedUser : user
        )
      );
      setFilteredUsers((prev) =>
        prev.map((user) =>
          user.UserId === updatedUser.UserId ? updatedUser : user
        )
      );
    } catch (error) {
      console.error("Error updating user:", error);
      setToast({
        message: "Failed to update user. Please try again.",
        type: "error",
      });
    }
  };

  if (loading) return <p className="text-gray-500">Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="container mx-auto p-6 bg-white rounded-lg shadow-md">
      {/* Page Title & Info Button */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">User List</h2>
        <button
          onClick={() => setShowHints(!showHints)}
          className="text-gray-600 hover:text-gray-800 flex items-center"
          title="Click to see hints"
        >
          <FaInfoCircle size={20} className="mr-1" />
          Hints
        </button>
      </div>
      {/* Hints Popover */}
      {showHints && (
        <div className="absolute top-12 right-6 bg-yellow-100 border border-yellow-400 text-yellow-800 p-4 rounded-lg shadow-md z-10 w-80">
          <h3 className="font-semibold text-lg mb-2">Hints & Tips</h3>
          <ul className="list-disc list-inside text-sm space-y-1">
            <li>Click on a row to view more details about a user.</li>
            <li>
              Use the search bar to filter by name, user ID, or mobile number.
            </li>
            <li>You can filter users by role using the dropdown.</li>
            <li>
              Click the edit icon (<FaEdit className="inline text-blue-500" />)
              to update user details.
            </li>
          </ul>
          <button
            className="mt-3 text-blue-500 hover:underline"
            onClick={() => setShowHints(false)}
          >
            Got it!
          </button>
        </div>
      )}

      <div className="mb-4 flex flex-col md:flex-row md:items-center md:justify-between gap-4">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search by Name, User ID, or Mobile Number"
          className="w-full md:w-2/3 p-3 border border-gray-300 rounded-lg"
        />
        <select
          value={selectedRole}
          onChange={handleRoleFilter}
          className="w-full md:w-1/3 p-3 border border-gray-300 rounded-lg"
        >
          <option value="All">All Users</option>
          <option value="General">General</option>
          <option value="Coach">Coach</option>
          <option value="Admin">Admin</option>
        </select>
      </div>

      <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 px-4 py-2">#</th>
              <th className="border border-gray-300 px-4 py-2">Name</th>
              <th className="border border-gray-300 px-4 py-2">
                Mobile Number
              </th>
              <th className="border border-gray-300 px-4 py-2">
                Email
              </th>
              <th className="border border-gray-300 px-4 py-2">Role</th>
              <th className="border border-gray-300 px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user, index) => (
              <tr
                key={user.UserId}
                className="hover:bg-gray-100 cursor-pointer"
                onClick={() => handleRowClick(user.UserId)}
              >
                <td className="border border-gray-300 px-4 py-2 text-center">
                  {index + 1}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {user.FirstName} {user.LastName}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {user.MobileNumber || "N/A"}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {user.EmailAddress || "N/A"}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {user.RoleType}
                </td>
                <td className="border border-gray-300 px-4 py-2 text-center">
                  <button
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent row click when clicking the edit button
                      setEditUser(user);
                    }}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    <FaEdit size={20} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {editUser && (
        <EditUserForm
          user={editUser}
          onClose={() => setEditUser(null)}
          onUpdate={handleUpdateUser}
        />
      )}

      {/* Display the toast notification */}
      {toast && (
        <ToastNotification
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      {filteredUsers.length === 0 && (
        <p className="text-gray-500 mt-4">No users found.</p>
      )}
    </div>
  );
};

export default UserList;
