import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createMilestone, fetchSports } from "../../api/api"; // API function
import ToastNotification from "../../components/ToastNotification";

const CreateMilestone = () => {
  const navigate = useNavigate();
  const [milestoneData, setMilestoneData] = useState({
    MilestoneType: "",
    MilestoneName: "",
    MilestoneDescription: "",
    Gender: null,
    RuleType: null,
    MilestoneCreateDynamics: [
      {
        IsConsecutive: false,
        MilestoneSport: "",
        MilestoneCategory: "",
        MilestoneSubCategory: null,
        SportFormat: null,
        OfferingIdEntered: false,
        MilestoneCondition: null,
        MilestoneAction: 1,
        MilestoneReps: 1,
        Frequency: "One-time",
      },
    ],
  });

  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(null);
  const [sports, setSports] = useState([]);

  useEffect(() => {
    // Fetch sports
    setLoading(true);
    const fetchSportsData = async () => {
      try {
        const fetchedSports = await fetchSports();
        setSports(fetchedSports);
      } catch (error) {
        console.error("Error fetching sports:", error);
      }
    };
    setLoading(false);
    fetchSportsData();
  }, []);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMilestoneData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle changes for nested "MilestoneCreateDynamics" array
  const handleDynamicChange = (e, index) => {
    const { name, value } = e.target;
    setMilestoneData((prev) => {
      const updatedDynamics = [...prev.MilestoneCreateDynamics];
      updatedDynamics[index] = {
        ...updatedDynamics[index],
        [name]: value || null, // Handle null values correctly
      };
      return { ...prev, MilestoneCreateDynamics: updatedDynamics };
    });
  };

  const preventInvalidKeysAndArrows = (e) => {
    if (["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleNumberInput = (e, index) => {
    const { name, value } = e.target;
    if (/^\d*$/.test(value)) {
      setMilestoneData((prev) => {
        const updatedDynamics = [...prev.MilestoneCreateDynamics];
        updatedDynamics[index] = {
          ...updatedDynamics[index],
          [name]: value === "" ? "" : Math.max(0, Number(value)), // Prevents negative values
        };
        return { ...prev, MilestoneCreateDynamics: updatedDynamics };
      });
    }
  };

  // Add a new dynamic milestone condition
  const addMilestoneCondition = () => {
    setMilestoneData((prev) => ({
      ...prev,
      MilestoneCreateDynamics: [
        ...prev.MilestoneCreateDynamics,
        {
          IsConsecutive: false,
          MilestoneSport: "",
          MilestoneCategory: "",
          MilestoneSubCategory: null,
          SportFormat: 1,
          OfferingIdEntered: false,
          MilestoneCondition: null,
          MilestoneAction: 1,
          MilestoneReps: 1,
          Frequency: "One-time",
        },
      ],
    }));
  };

  // Remove a dynamic milestone condition
  const removeMilestoneCondition = (index) => {
    setMilestoneData((prev) => ({
      ...prev,
      MilestoneCreateDynamics: prev.MilestoneCreateDynamics.filter(
        (_, i) => i !== index
      ),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let adjustedMilestoneData = {
      ...milestoneData,
    }

    if(milestoneData.Gender ==="All" || milestoneData.Gender ===""){
      adjustedMilestoneData={
        ...milestoneData,
        Gender:null,
      }
    }

    try {
      await createMilestone(adjustedMilestoneData);
      console.log("Milestone payload", adjustedMilestoneData)
      setToast({ message: "Milestone created successfully!", type: "success" });
      setTimeout(() => navigate("/milestones"), 1500);
    } catch (error) {
      setToast({ message: "Failed to create milestone", type: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg space-y-8">
      <h2 className="text-3xl font-bold text-center text-gray-800">
        Create Milestone
      </h2>

      {toast && (
        <ToastNotification
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Milestone Name */}
        <div className="mb-6">
          <label className="block text-lg font-semibold mb-2">
            Milestone Name
          </label>
          <input
            type="text"
            name="MilestoneName"
            value={milestoneData.MilestoneName}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            placeholder="Enter milestone name"
            required
          />
        </div>
        {/* Milestone Type */}
        <div className="mb-6">
          <label className="block text-lg font-semibold mb-2">
            Milestone Name
          </label>
          <select
            name="MilestoneType"
            value={milestoneData.MilestoneType}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          >
            <option value="">Choose a type</option>
            <option value="Targeted">Targeted Member</option>
            <option value="Milestone">Milestone</option>
            <option value="General">General</option>
            <option value="Streak">Streak</option>
          </select>
        </div>

        {/* Milestone Description */}
        <div className="mb-6">
          <label className="block text-lg font-semibold mb-2">
            Milestone Description
          </label>
          <textarea
            name="MilestoneDescription"
            value={milestoneData.MilestoneDescription}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            placeholder="Enter milestone description"
            required
          />
        </div>

        {/* Gender Selection */}
        <div className="mb-6">
          <label className="block text-lg font-semibold mb-2">Gender</label>
          <select
            name="Gender"
            value={milestoneData.Gender}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          >
            <option value="">Select an option</option>
            <option value="All">All</option>
            <option value="Man">Man</option>
            <option value="Woman">Woman</option>
            <option value="Non-Binary">Non-Binary</option>
          </select>
        </div>

        {/* Milestone Type */}
        <div className="mb-6">
          <label className="block text-lg font-semibold mb-2">
            Type of Rule
          </label>
          <select
            name="RuleType"
            value={milestoneData.RuleType}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          >
            <option value="">Choose a type</option>
            <option value="And">And</option>
            <option value="Or">Or</option>
          </select>
        </div>

        {/* Milestone Create Dynamics (Nested Fields) */}
        <h3 className="text-xl font-semibold text-gray-700">
          Milestone Conditions
        </h3>

        {milestoneData.MilestoneCreateDynamics.map((dynamic, index) => (
          <div key={index} className="mb-6 border p-4 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold text-gray-700 mb-4">
              Condition {index + 1}
            </h3>

            {/* Is Consecutive */}
            <div className="mb-4">
              <label className="block text-lg font-semibold mb-2">
                Is Consecutive?
              </label>
              <input
                type="checkbox"
                name="IsConsecutive"
                checked={dynamic.IsConsecutive}
                onChange={(e) =>
                  handleDynamicChange(
                    {
                      target: {
                        name: "IsConsecutive",
                        value: e.target.checked,
                      },
                    },
                    index
                  )
                }
                className="h-5 w-5"
              />
            </div>

            {/* Sport Dropdown */}
            <div className="mb-6">
              <label
                htmlFor="SportId"
                className="block text-lg font-semibold mb-2"
              >
                Sport *
              </label>
              <select
                name="MilestoneSport"
                id="MilestoneSport"
                value={dynamic.MilestoneSport}
                onChange={(e) => handleDynamicChange(e, index)}
                className="w-full p-3 border border-gray-300 rounded-lg"
                required
              >
                <option value="">Select a Sport</option>
                {sports.map((sport) => (
                  <option key={sport.SportId} value={sport.SportType}>
                    {sport.SportType}
                  </option>
                ))}
              </select>
            </div>

            {/* Category & Subcategory */}
            <div className="grid grid-cols-2 gap-6">
              {/* Category Dropdown */}
              <div className="mb-4">
                <label className="block text-lg font-semibold mb-2">
                  Category
                </label>
                <select
                  name="MilestoneCategory"
                  value={dynamic.MilestoneCategory || ""}
                  onChange={(e) => {
                    handleDynamicChange(e, index);
                    if (e.target.value === "PlayPass") {
                      // If "PlayPass" is selected, set Subcategory to null
                      handleDynamicChange(
                        {
                          target: { name: "MilestoneSubCategory", value: null },
                        },
                        index
                      );
                    }
                  }}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  required
                >
                  <option value="">Select Category</option>
                  <option value="Event">Event</option>
                  <option value="PlayPass">PlayPass</option>
                </select>
              </div>

              {/* Subcategory Dropdown (Visible Only if Category is "Event") */}
              {dynamic.MilestoneCategory === "Event" && (
                <div className="mb-4">
                  <label className="block text-lg font-semibold mb-2">
                    Subcategory
                  </label>
                  <select
                    name="MilestoneSubCategory"
                    value={dynamic.MilestoneSubCategory || ""}
                    onChange={(e) => handleDynamicChange(e, index)}
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    required
                  >
                    <option value="">Select Subcategory</option>
                    <option value="League">League</option>
                    <option value="Beginner">Beginner</option>
                    <option value="Intermediate">Intermediate</option>
                    <option value="Expert">Expert</option>
                  </select>
                </div>
              )}
            </div>

            {/* Sport Format */}
            <div className="grid grid-cols-2 gap-6">
              <div className="mb-4">
                <label className="block text-lg font-semibold mb-2">
                  Sport Format
                </label>
                <input
                  type="number"
                  name="SportFormat"
                  value={dynamic.SportFormat || ""}
                  onChange={(e) => handleNumberInput(e, index)}
                  onKeyDown={preventInvalidKeysAndArrows}
                  onWheel={(e) => e.target.blur()}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                />
              </div>

              {/* Offering ID Entered */}
              <div className="mb-4">
                <label className="block text-lg font-semibold mb-2">
                  Offering ID Entered
                </label>
                <select
                  name="OfferingIdEntered"
                  value={dynamic.OfferingIdEntered || false}
                  onChange={(e) => handleDynamicChange(e, index)}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                >
                <option value="">Select Condition</option>
                <option value="True">Yes</option>
                <option value="False">No</option>
                </select>
              </div>
            </div>

            {/* Milestone Condition */}
            <div className="mb-4">
              <label className="block text-lg font-semibold mb-2">
                Milestone Condition
              </label>
              <select
                name="MilestoneCondition"
                value={dynamic.MilestoneCondition || ""}
                onChange={(e) => handleDynamicChange(e, index)}
                className="w-full p-3 border border-gray-300 rounded-lg"
              >
                <option value="">Select Condition</option>
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
              </select>
            </div>

            {/* Milestone Action */}
            <div className="mb-4">
              <label className="block text-lg font-semibold mb-2">
                Milestone Action
              </label>
              <input
                type="number"
                name="MilestoneAction"
                value={dynamic.MilestoneAction || ""}
                onChange={(e) => handleNumberInput(e, index)}
                onKeyDown={preventInvalidKeysAndArrows}
                onWheel={(e) => e.target.blur()}
                className="w-full p-3 border border-gray-300 rounded-lg"
              />
            </div>

            {/* Milestone Reps */}
            <div className="mb-4">
              <label className="block text-lg font-semibold mb-2">
                Milestone Reps
              </label>
              <input
                type="number"
                name="MilestoneReps"
                value={dynamic.MilestoneReps || ""}
                onChange={(e) => handleNumberInput(e, index)}
                onKeyDown={preventInvalidKeysAndArrows}
                onWheel={(e) => e.target.blur()}
                className="w-full p-3 border border-gray-300 rounded-lg"
              />
            </div>

            {/* Frequency */}
            <div className="mb-4">
              <label className="block text-lg font-semibold mb-2">
                Frequency
              </label>
              <select
                name="Frequency"
                value={dynamic.Frequency || ""}
                onChange={(e) => handleDynamicChange(e, index)}
                className="w-full p-3 border border-gray-300 rounded-lg"
              >
                <option value="One-time">One-time</option>
                <option value="Recurring">Recurring</option>
              </select>
            </div>

            {/* Remove Condition */}
            <button
              type="button"
              onClick={() => removeMilestoneCondition(index)}
              className="text-red-500 font-medium underline"
            >
              Remove Condition
            </button>
          </div>
        ))}

        {/* Add Condition Button */}
        <button
          type="button"
          onClick={addMilestoneCondition}
          className=" bg-gray-300 text-gray-700 p-3 rounded-lg shadow-md hover:bg-gray-400"
        >
          Add Another Condition
        </button>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-3 rounded-lg shadow-md hover:bg-blue-600"
          disabled={loading}
        >
          {loading ? "Creating Milestone..." : "Create Milestone"}
        </button>
      </form>
    </div>
  );
};

export default CreateMilestone;
