import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchMilestones, deleteMilestone } from "../../api/api";
import Loader from "../../components/Loader";
import {FaTrashAlt } from "react-icons/fa";
const MilestoneList = () => {
  const navigate = useNavigate();
  const [milestones, setMilestones] = useState([]);
  const [filteredMilestones, setFilteredMilestones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [deletingMilestone, setDeletingMilestone] = useState(null);

  // Fetch milestones from API
  useEffect(() => {
    const getMilestones = async () => {
      try {
        const data = await fetchMilestones();
        setMilestones(data);
        setFilteredMilestones(data);
      } catch (error) {
        console.error("Failed to fetch milestones:", error);
      } finally {
        setLoading(false);
      }
    };
    getMilestones();
  }, []);

  // Handle search input
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    if (!term) {
      setFilteredMilestones(milestones);
      return;
    }

    const filtered = milestones.filter(
      (milestone) =>
        milestone.MilestoneName.toLowerCase().includes(term) ||
        milestone.MilestoneGetDynamics.some((dynamic) =>
          dynamic.MilestoneSport.toLowerCase().includes(term)
        )
    );

    setFilteredMilestones(filtered);
  };

  // Handle Reset Search
  const handleReset = () => {
    setSearchTerm("");
    setFilteredMilestones(milestones);
  };

  const handleDelete = async (milestoneId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this milestone?"
    );
    if (!confirmDelete) return;

    setDeletingMilestone(milestoneId); // Show loading state for this milestone

    try {
      await deleteMilestone(milestoneId);
      // Remove deleted milestone from the list
      setMilestones((prev) =>
        prev.filter((m) => m.MilestoneId !== milestoneId)
      );
      setFilteredMilestones((prev) =>
        prev.filter((m) => m.MilestoneId !== milestoneId)
      );
    } catch (error) {
      console.error("Failed to delete milestone:", error);
      alert("Error deleting milestone. Please try again.");
    } finally {
      setDeletingMilestone(null); // Reset loading state
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold text-gray-800">Milestones</h2>
        <button
          onClick={() => navigate("/milestones/create")}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 transition"
        >
          + Create Milestone
        </button>
      </div>

      {/* Search Bar */}
      <div className="flex items-center gap-4 mb-4">
        <input
          type="text"
          placeholder="Search by Name or Sport..."
          value={searchTerm}
          onChange={handleSearch}
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={handleReset}
          className="bg-gray-400 text-white px-4 py-2 rounded-lg hover:bg-gray-500 transition"
        >
          Reset
        </button>
      </div>

      {/* Milestones Table */}
      {filteredMilestones.length === 0 ? (
        <p className="text-gray-500 text-center">No milestones found.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="w-full border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="border px-4 py-2 text-left">Milestone Name</th>
                <th className="border px-4 py-2 text-left">Type</th>
                <th className="border px-4 py-2 text-left">Description</th>
                <th className="border px-4 py-2 text-left">Sport</th>
                <th className="border px-4 py-2 text-left">Category</th>
                <th className="border px-4 py-2 text-left">Subcategory</th>
                <th className="border px-4 py-2 text-left">Condition</th>
                <th className="border px-4 py-2 text-left">Reps</th>
                <th className="border px-4 py-2 text-left">Frequency</th>
              </tr>
            </thead>
            <tbody>
              {filteredMilestones.map((milestone) =>
                milestone.MilestoneGetDynamics.map((dynamic, index) => (
                  <tr
                    key={`${milestone.MilestoneId}-${index}`}
                    className="hover:bg-gray-100"
                  >
                    {/* Merge first row cells for milestone details */}
                    {index === 0 && (
                      <>
                        <td
                          className="border px-4 py-2"
                          rowSpan={milestone.MilestoneGetDynamics.length}
                        >
                          {milestone.MilestoneName}
                        </td>
                        <td
                          className="border px-4 py-2"
                          rowSpan={milestone.MilestoneGetDynamics.length}
                        >
                          {milestone.MilestoneType}
                        </td>
                        <td
                          className="border px-4 py-2"
                          rowSpan={milestone.MilestoneGetDynamics.length}
                        >
                          {milestone.MilestoneDescription}
                        </td>
                      </>
                    )}

                    {/* Display dynamic milestone conditions */}
                    <td className="border px-4 py-2">
                      {dynamic.MilestoneSport}
                    </td>
                    <td className="border px-4 py-2">
                      {dynamic.MilestoneCategory}
                    </td>
                    <td className="border px-4 py-2">
                      {dynamic.MilestoneSubCategory || "N/A"}
                    </td>
                    <td className="border px-4 py-2">
                      {dynamic.MilestoneCondition}
                    </td>
                    <td className="border px-4 py-2">
                      {dynamic.MilestoneReps}
                    </td>
                    <td className="border px-4 py-2">{dynamic.Frequency}</td>
                    {/* Delete Button */}
                    {index === 0 && (
                      <td
                        className="border px-4 py-2"
                        rowSpan={milestone.MilestoneGetDynamics.length}
                      >
                        <button
                          onClick={() => handleDelete(milestone.MilestoneId)}
                          className="bg-red-100 text-red-500 hover:bg-red-200"
                          disabled={deletingMilestone === milestone.MilestoneId}
                        >
                          <FaTrashAlt/>
                        </button>
                      </td>
                    )}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default MilestoneList;
