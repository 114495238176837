import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaCaretDown, FaCaretRight, FaBars, FaTimes } from "react-icons/fa";
import packageJSON from "../../package.json";

const Sidebar = () => {
  const [isEventsOpen, setIsEventsOpen] = useState(false);
  const [isCoachingOpen, setIsCoachingOpen] = useState(false);
  const [isRewardsOpen, setIsRewardsOpen] = useState(false);
  const [isSponsorsOpen, setIsSponsorsOpen] = useState(false);
  const [isVenuesOpen, setIsVenuesOpen] = useState(false);
  const [isMasterListOpen, setIsMasterListOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isPlayPassOpen, setIsPlayPassOpen] = useState(false);  



  const toggleDropdown = (dropdown) => {
    if (dropdown === "events") {
      setIsEventsOpen(!isEventsOpen);
    }else if(dropdown === "playPass"){
      setIsPlayPassOpen(!isPlayPassOpen);
    } else if (dropdown === "coaching") {
      setIsCoachingOpen(!isCoachingOpen);
    } else if (dropdown === "rewards") {
      setIsRewardsOpen(!isRewardsOpen);
    } else if (dropdown === "sponsors") {
      setIsSponsorsOpen(!isSponsorsOpen);
    } else if (dropdown === "venues") {
      setIsVenuesOpen(!isVenuesOpen);
    } else if (dropdown === "masterlist") {
      setIsMasterListOpen(!isMasterListOpen);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="z-40 fixed top-0 left-0">
      {/* Sidebar */}
      <div
        className={`w-64 bg-[#2D1066] text-white h-screen transition-all duration-300 transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          } md:translate-x-0 flex flex-col fixed`}
      >
        {/* Admin Dashboard Heading */}
        <div className="flex items-center justify-between p-4 text-xl font-bold border-b border-gray-600">
          <span>Admin Dashboard v{packageJSON.version}</span>
          <div
            className="text-white cursor-pointer md:hidden absolute top-4 right-4"
            onClick={toggleSidebar}
          >
            <FaTimes size={24} />
          </div>
        </div>

        {/* Scrollable Content */}
        <div className="flex-grow overflow-y-auto scrollbar-thin scrollbar-thumb-[#dddddd] scrollbar-track-[#2D1066]" style={{ paddingBottom: "80px" }}>
          <ul className="space-y-2 mt-4">
            <li>
              <Link
                to="/"
                className="font-medium block p-2 hover:bg-[#F2F2F2] hover:text-[#2D1066]"
              >
                Dashboard
              </Link>
            </li>

            {/* Events Dropdown */}
            <li>
              <button
                className="font-medium inline-flex w-full p-2 justify-between items-center hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                onClick={() => toggleDropdown("events")}
              >
                Events {isEventsOpen ? <FaCaretDown /> : <FaCaretRight />}
              </button>
              {isEventsOpen && (
                <ul className="pl-4 space-y-2">
                  <li>
                    <Link
                      to="/events"
                      className="block p-2 hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                    >
                      Event Details
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/events/create"
                      className="block p-2 hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                    >
                      Create Event
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            {/* PlayPass Dropdown */}

            <li>
              <button
                className="font-medium inline-flex w-full p-2 justify-between items-center hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                onClick={() => toggleDropdown("playPass")}
              >
                Playpass Game{isPlayPassOpen ? <FaCaretDown /> : <FaCaretRight />}
              </button>

              {isPlayPassOpen && (
                <ul className="pl-4 space-y-2">
                  <li>
                    <Link
                      to="/playpass"
                      className="block p-2 hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                    >
                      Playpass Game Details
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/playpass/create"
                      className="block p-2 hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                    >
                      Create Playpass Game
                    </Link>
                  </li>
                </ul>
              )}

            </li>


            {/* Coaching Dropdown */}
            <li>
              <button
                className="font-medium inline-flex w-full p-2 justify-between items-center hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                onClick={() => toggleDropdown("coaching")}
              >
                Coaching {isCoachingOpen ? <FaCaretDown /> : <FaCaretRight />}
              </button>
              {isCoachingOpen && (
                <ul className="pl-4 space-y-2">
                  <li>
                    <Link
                      to="/coaching"
                      className="block p-2 hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                    >
                      Coaching Details
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/coaching/create"
                      className="block p-2 hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                    >
                      Create Coaching
                    </Link>
                  </li>
                  
                </ul>
              )}
            </li>

            {/* Rewards Dropdown */}
            <li>
              <button
                className="font-medium inline-flex w-full p-2 justify-between items-center hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                onClick={() => toggleDropdown("rewards")}
              >
                Rewards {isRewardsOpen ? <FaCaretDown /> : <FaCaretRight />}
              </button>
              {isRewardsOpen && (
                <ul className="pl-4 space-y-2">
                  <li>
                    <Link
                      to="/rewards/create"
                      className="block p-2 hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                    >
                      Create Rewards
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/rewards-sponsor/create"
                      className="block p-2 hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                    >
                      Create Rewards Sponsor
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/rewards"
                      className="block p-2 hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                    >
                      Rewards Details
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/milestones/create"
                      className="block p-2 hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                    >
                      Create milestone
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/milestones"
                      className="block p-2 hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                    >
                      Milestone Details
                    </Link>
                  </li>
                  
                </ul>
              )}
            </li>

            {/* Sponsors Dropdown */}
            <li>
              <button
                className="font-medium inline-flex w-full p-2 justify-between items-center hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                onClick={() => toggleDropdown("sponsors")}
              >
                Sponsors {isSponsorsOpen ? <FaCaretDown /> : <FaCaretRight />}
              </button>
              {isSponsorsOpen && (
                <ul className="pl-4 space-y-2">
                  <li>
                    <Link
                      to="/sponsors"
                      className="block p-2 hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                    >
                      Sponsor List
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/sponsors/create"
                      className="block p-2 hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                    >
                      Create Sponsor
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            {/* Venues Dropdown */}
            <li>
              <button
                className="font-medium inline-flex w-full p-2 justify-between items-center hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                onClick={() => toggleDropdown("venues")}
              >
                Venues {isVenuesOpen ? <FaCaretDown /> : <FaCaretRight />}
              </button>
              {isVenuesOpen && (
                <ul className="pl-4 space-y-2">
                  <li>
                    <Link
                      to="/venues/"
                      className="block p-2 hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                    >
                      Venue Details
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/venues/create"
                      className="block p-2 hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                    >
                      Create Venue
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/venues/createSlot"
                      className="block p-2 hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                    >
                      Create Venue Slot
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/region/create"
                      className="block p-2 hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                    >
                      Create Region
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            {/* Master List Dropdown */}
            <li>
              <button
                className="font-medium inline-flex w-full p-2 justify-between items-center hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                onClick={() => toggleDropdown("masterlist")}
              >
                Master List {isMasterListOpen ? <FaCaretDown /> : <FaCaretRight />}
              </button>
              {isMasterListOpen && (
                <ul className="pl-4 space-y-2">
                  <li>
                    <Link
                      to="/master/user-master"
                      className="block p-2 hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                    >
                      User Master
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/user/create"
                      className="block p-2 hover:bg-[#F2F2F2] hover:text-[#2D1066]"
                    >
                      Create User
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        </div>

        {/* Profile Section */}
        <div className="bg-[#2D1066] text-white p-4 border-t border-gray-600 fixed bottom-0 w-64">
          <div className="flex items-center space-x-4">
            <img
              src="https://via.placeholder.com/40"
              alt="Profile"
              className="w-10 h-10 rounded-full"
            />
            <Link to="/profile" className="text-white font-medium">
              My Profile
            </Link>
          </div>
        </div>
      </div>

      {/* Hamburger Icon */}
      <div
        className="md:hidden absolute top-4 left-4 text-white cursor-pointer"
        onClick={toggleSidebar}
      >
        {isSidebarOpen ? <p /> : <FaBars className="text-[#2D1066]" size={24} />}
      </div>
    </div>
  );
};

export default Sidebar;
