import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getUserById,
  getTransactionsAdmin,
  getOrders,
  getWalletBalance,
  fetchSports
} from "../../api/api";
import ToastNotification from '../../components/ToastNotification';
import TxnList from "./TxnList";

const UserDetails = () => {
  const { userId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [sports, setSports] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [orders, setOrders] = useState([]);
  const [walletBalance, setWalletBalance] = useState(0);
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingTransactions, setLoadingTransactions] = useState(true);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [loadingWallet, setLoadingWallet] = useState(true);
  const [error, setError] = useState(null);
  const [toast, setToast] = useState(null);
  const navigate = useNavigate();
  // Fetch user details
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const user = await getUserById(userId);
        setUserDetails(user);
      } catch (err) {
        if (err.response?.status === 404) {
          setUserDetails(null); // Set userDetails to null if 404
        } else {
          setError("Failed to fetch user details.");
        }
        setToast({ message: 'Failed to fetch user Details!', type: 'error' });
      } finally {
        setLoadingUser(false);
      }
    };

    fetchUserDetails();
  }, [userId]);
  // Fetch user transactions
  useEffect(() => {
    const fetchUserTransactions = async () => {
      try {
        const transactionData = await getTransactionsAdmin(userId, 100, 0);
        setTransactions(transactionData?.Data || []);
      } catch (err) {
        if (err.response?.status === 404) {
          setTransactions([]); // Set transactions to an empty array if 404
        } else {
          console.error("Error fetching transactions:", err);
        }
      } finally {
        setLoadingTransactions(false);
      }
    };

    fetchUserTransactions();
  }, [userId]);
  useEffect(() => {
    // Fetch sports
    const fetchSportsData = async () => {
      try {
        const fetchedSports = await fetchSports();
        setSports(fetchedSports);
      } catch (error) {
        console.error("Error fetching sports:", error);
      }
    };
    fetchSportsData();
  }, []); // Only runs once on mount
  // Fetch user orders
  useEffect(() => {
    const fetchUserOrders = async () => {
      try {
        const ordersData = await getOrders(userId, 1, 20);
        setOrders(ordersData?.orders || []);
      } catch (err) {
        if (err.response?.status === 404) {
          setOrders([]); // Set orders to an empty array if 404
        } else {
          console.error("Error fetching orders:", err);
        }
      } finally {
        setLoadingOrders(false);
      }
    };

    fetchUserOrders();
  }, [userId]);

  // Fetch wallet balance
  useEffect(() => {
    const fetchWalletBalance = async () => {
      try {
        const balanceData = await getWalletBalance(userId);
        setWalletBalance(balanceData?.Balance ?? "Not Available");
      } catch (err) {
        if (err.response?.status === 404) {
          setWalletBalance("0"); // Set wallet balance to "Not Available" if 404
        } else {
          console.error("Error fetching wallet balance:", err);
        }
      } finally {
        setLoadingWallet(false);
      }
    };

    fetchWalletBalance();
  }, [userId]);

  const getPreferredSports = () => {
    const ids = userDetails?.SportId || [];

    return ids
      .map((id) => {
        const sport = sports.find((s) => s.SportId === id);
        return sport ? sport.SportType : null;
      })
      .filter(Boolean); // removes nulls
  };

  // Function to get the appropriate color for the order status
  const getOrderStatusColor = (status) => {
    switch (status) {
      case "Success":
        return "text-green-500 font-medium";
      case "Pending":
        return "text-yellow-500 font-medium";
      case "Failed":
        return "text-red-500 font-medium";
      default:
        return "text-gray-500";
    }
  };

  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="container mx-auto p-6 bg-white rounded-lg shadow-md space-y-6">
      {/* User Details and Wallet Balance */}
      {toast && <ToastNotification {...toast} onClose={() => setToast(null)} />}
      {userDetails && (
        <div className="bg-blue-50 p-6 rounded-lg shadow-md grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <p className="text-lg ">
              <strong>Name:</strong> {userDetails?.User?.FirstName}{" "}
              {userDetails?.User?.LastName}
            </p>
            <p className="text-lg">
              <strong>Mobile:</strong> {userDetails?.User?.MobileNumber}
            </p>
            <p className="text-lg">
              <strong>Email:</strong> {userDetails?.User?.EmailAddress}
            </p>
            <p className="text-lg">
              <strong>User ID:</strong> {userDetails?.User?.UserId}
            </p>
            <p className="text-lg">
              <strong>Membership Tier:</strong>{" "}
              {userDetails?.User?.MembershipTier || "N/A"}
            </p>
            <p className="text-lg">
              <strong>User Role:</strong>{" "}
              {userDetails?.User?.RoleType || "N/A"}
            </p>
            <p className="text-lg">
              <strong>Sport:</strong>{" "}
              {getPreferredSports().length > 0
                ? getPreferredSports().join(", ")
                : "N/A"}
            </p>

          </div>
          <div>
            <div className="bg-white p-4 rounded-lg shadow-md">
              <h3 className="text-xl font-bold mb-2">Credit Wallet Balance</h3>
              <p className="text-3xl font-semibold text-green-600">
                {walletBalance} credits
              </p>
            </div>
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={() => navigate(`/master/user-master/${userId}/rewards`)}
              >
                View User Rewards
              </button>
            </div>
          </div>
        </div>
      )}

      {transactions && transactions.length > 0 ? <TxnList
        txns={transactions}
      /> : null}


      {/* Transactions and Orders */}
      {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-gray-50 p-4 rounded-lg shadow-md">
          <h3 className="text-xl font-bold mb-4">Transactions</h3>
          {transactions.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="table-auto w-full border-collapse border border-gray-300">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="border border-gray-300 px-4 py-2">
                      Transaction ID
                    </th>
                    <th className="border border-gray-300 px-4 py-2">
                      Date & Time
                    </th>
                    <th className="border border-gray-300 px-4 py-2">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((txn) => (
                    <tr key={txn.transaction_id}>
                      <td className="border border-gray-300 px-4 py-2">
                        {txn.Id}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {new Date(txn.OrderDate).toLocaleString()}
                      </td>
                      <td
                        className={`border border-gray-300 px-4 py-2 ${txn.Status === "Success"
                          ? "text-green-500 font-medium"
                          : "text-yellow-500 font-medium"
                          }`}
                      >
                        {txn.Status}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-gray-500">No transactions found.</p>
          )}
        </div> */}

      {/* Orders Table */}
      {/* <div className="bg-gray-50 p-4 rounded-lg shadow-md">
          <h3 className="text-xl font-bold mb-4">Orders</h3>
          {orders.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="table-auto w-full border-collapse border border-gray-300">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="border border-gray-300 px-4 py-2">
                      Order ID
                    </th>
                    <th className="border border-gray-300 px-4 py-2">
                      Offer Type
                    </th>
                    <th className="border border-gray-300 px-4 py-2">
                      Amount (Fiat / Credits)
                    </th>
                    <th className="border border-gray-300 px-4 py-2">Status</th>
                    <th className="border border-gray-300 px-4 py-2">
                      Created At
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr key={order.OrderId}>
                      <td className="border border-gray-300 px-4 py-2">
                        {order.OrderId}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {order.OfferType}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        ₹{order.OrderFiatAmount} / {order.OrderCreditAmount}{" "}
                        credits
                      </td>
                      <td
                        className={`border border-gray-300 px-4 py-2 ${getOrderStatusColor(
                          order.OrderStatus
                        )}`}
                      >
                        {order.OrderStatus}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {new Date(order.CreatedAtTimeStamp).toLocaleString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-gray-500">No orders found.</p>
          )}
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default UserDetails;
