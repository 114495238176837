import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchSports, addPlayAreaToVenue } from "../../api/api"; // API functions
import ToastNotification from "../../components/ToastNotification";

const AddPlayArea = () => {
  const { venueId } = useParams();
  const navigate = useNavigate();

  // State for form inputs
  const [playAreaInput, setPlayAreaInput] = useState({
    PlayAreaName: "",
    SportId: "",
    SupportedFormats: [],
    Price: "",
    PriceCredits: "",
  });

  const [sports, setSports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(null);
  const [error, setError] = useState(null);

  // Predefined formats based on sport type
  const sportFormats = {
    Football: ["5v5", "6v6", "7v7", "8v8"],
    Pickleball: ["1v1", "2v2"],
    Padel: ["2v2"],
  };

  useEffect(() => {
    const fetchAndSetSports = async () => {
      try {
        const sportsData = await fetchSports();
        setSports(sportsData);
      } catch (error) {
        setError("Failed to fetch sports");
      }
    };

    fetchAndSetSports();
  }, []);

  const handleSportChange = (e) => {
    const selectedSportId = e.target.value;
    const selectedSportName = sports.find(
      (sport) => sport.SportId === selectedSportId
    )?.SportType;

    setPlayAreaInput((prev) => ({
      ...prev,
      SportId: selectedSportId,
      SupportedFormats:
        selectedSportName === "Padel"
          ? ["2v2"]
          : selectedSportName === "Pickleball"
          ? ["1v1", "2v2"]
          : [],
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (["Price", "PriceCredits"].includes(name) && value !== "" && !/^\d+$/.test(value)) {
      return;
    }

    setPlayAreaInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFormatChange = (format) => {
    setPlayAreaInput((prev) => ({
      ...prev,
      SupportedFormats: [format],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!playAreaInput.PlayAreaName || !playAreaInput.SportId || !playAreaInput.Price || !playAreaInput.PriceCredits) {
      setToast({ message: "Please fill all required fields", type: "error" });
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await addPlayAreaToVenue(venueId, [playAreaInput]);
      setToast({ message: "Play Area added successfully!", type: "success" });
      setTimeout(() => navigate(`/venues/${venueId}/playareas`), 1500);
    } catch (error) {
      setError("Failed to add play area.");
      setToast({ message: "Failed to add play area", type: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg space-y-8">
      <h2 className="text-3xl font-bold text-center text-gray-800">Add Play Area</h2>

      {toast && <ToastNotification message={toast.message} type={toast.type} onClose={() => setToast(null)} />}

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Play Area Name */}
        <div className="mb-6">
          <label className="block text-lg font-semibold mb-2">Play Area Name</label>
          <input
            type="text"
            name="PlayAreaName"
            value={playAreaInput.PlayAreaName}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            placeholder="Enter play area name"
            required
          />
        </div>

        {/* Sport Selection */}
        <div className="mb-6">
          <label className="block text-lg font-semibold mb-2">Sport</label>
          <select
            name="SportId"
            value={playAreaInput.SportId}
            onChange={handleSportChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          >
            <option value="">Select a Sport</option>
            {sports.map((sport) => (
              <option key={sport.SportId} value={sport.SportId}>
                {sport.SportType}
              </option>
            ))}
          </select>
        </div>

        {/* Supported Formats */}
        {playAreaInput.SportId && (
          <div className="mb-6">
            <label className="block text-lg font-semibold mb-2">Supported Formats</label>
            {playAreaInput.SportId &&
              sports.find((s) => s.SportId === playAreaInput.SportId)?.SportType === "Football" && (
                <div className="grid grid-cols-2 gap-4">
                  {sportFormats.Football.map((format) => (
                    <label key={format} className="flex items-center space-x-2 cursor-pointer">
                      <input
                        type="radio"
                        name="SupportedFormats"
                        value={format}
                        checked={playAreaInput.SupportedFormats.includes(format)}
                        onChange={() => handleFormatChange(format)}
                        className="hidden"
                      />
                      <span
                        className={`px-4 py-2 border rounded-lg ${
                          playAreaInput.SupportedFormats.includes(format) ? "bg-blue-500 text-white" : "border-gray-300"
                        }`}
                      >
                        {format}
                      </span>
                    </label>
                  ))}
                </div>
              )}
            {playAreaInput.SportId &&
              sports.find((s) => s.SportId === playAreaInput.SportId)?.SportType === "Pickleball" && (
                <p className="text-gray-600">Formats: Singles, Doubles</p>
              )}
            {playAreaInput.SportId &&
              sports.find((s) => s.SportId === playAreaInput.SportId)?.SportType === "Padel" && (
                <p className="text-gray-600">Format: Doubles</p>
              )}
          </div>
        )}

        {/* Price and Price Credits */}
        <div className="grid grid-cols-2 gap-6">
          <div className="mb-6">
            <label className="block text-lg font-semibold mb-2">Price (INR)</label>
            <input
              type="text"
              name="Price"
              value={playAreaInput.Price}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              placeholder="Enter price in INR"
              required
            />
          </div>

          <div className="mb-6">
            <label className="block text-lg font-semibold mb-2">Price Credits</label>
            <input
              type="text"
              name="PriceCredits"
              value={playAreaInput.PriceCredits}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              placeholder="Enter price in credits"
              required
            />
          </div>
        </div>

        {/* Submit Button */}
        <button type="submit" className="w-full bg-blue-500 text-white p-3 rounded-lg shadow-md hover:bg-blue-600" disabled={loading}>
          {loading ? "Adding Play Area..." : "Add Play Area"}
        </button>
      </form>
    </div>
  );
};

export default AddPlayArea;
